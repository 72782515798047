import { AcAvatar, AcButton, AcDialog, AcTypography } from '@components'
import { DYNAMIC_LABELS, LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import { ISingleEmployee } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { AcSelect } from 'src/components/core/ac-select/ac-select'
import styles from './template-claim.module.scss'
import dayjs from 'dayjs'

export const TemplateClaimModal = observer(() => {
  const { ui, template, employees, auth } = useStore()
  const [employee, setEmployee] = useState(auth.current_employee)

  const handleClaimTemplate = useCallback(async () => {
    await template.handleClaimTemplate(employee?.id).then(() => {
      ui.closeModalGracefully()
    })
  }, [employee])

  const handleViewTemplate = () => {
    if (ui.modal && ui.modal.reject) {
      ui.modal.reject()
    }
  }

  const selectOption = ({ full_name, avatar }: Partial<ISingleEmployee>) => (
    <div className={styles['ac-template-claim-select-option']}>
      <AcAvatar
        src={avatar}
        text={full_name?.slice(0, 2)}
        className={styles['ac-template-claim-select-option-image']}
      />
      <AcTypography
        className={styles['ac-template-claim-select-option-label']}
        size="sm"
        weight="light"
        element="span">
        {full_name}
      </AcTypography>
    </div>
  )

  useEffect(() => {
    employees.getList()
  }, [])

  return (
    <AcDialog
      title={DYNAMIC_LABELS.CLAIM_TEMPLATE(template.current_info.name)}
      className={styles['ac-template-claim']}>
      <div className={styles['ac-template-claim-content']}>
        <AcTypography
          className={styles['ac-template-claim-content-label']}
          size="xs"
          color="neutral-700"
          element="span"
          weight="light">
          {LABELS.TEMPLATE_CLAIM_TEXT}
        </AcTypography>
        <div className={styles['ac-template-claim-content-inner']}>
          <AcSelect
            className={styles['ac-template-claim-select']}
            defaultValue={employee}
            disabled={employees.loading}
            options={employees.all}
            valueKey="id"
            labelKey="name"
            onChange={data => setEmployee(data)}
            name="selectEmployee"
            id="selectEmployee"
            renderOption={selectOption}
          />
          <div>
            {template.has_claim_history && (
              <div className={styles['ac-template-claim-history']}>
                <AcTypography
                  className={styles['ac-template-claim-history-title']}
                  size="sm"
                  color="neutral-500"
                  element="span">
                  {LABELS.CLAIMED_BEFORE_BY}
                </AcTypography>
                <span className={styles['ac-template-claim-history-claimer']}>
                  <AcTypography
                    className={styles['ac-template-claim-history-claimer-name']}
                    color="neutral-500"
                    size="xs"
                    element="span">
                    {template.info?.claim?.history[0]?.employee?.full_name}
                  </AcTypography>
                  <AcTypography
                    className={styles['ac-template-claim-history-claimer-time']}
                    color="neutral-500"
                    size="xs"
                    weight="light"
                    element="span">
                    {dayjs(template.info?.claim?.history[0]?.updated_at).format(
                      'DD-MM-YYYY HH:MM'
                    )}
                  </AcTypography>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles['ac-template-claim-actions']}>
        <AcButton
          color="secondary"
          label={LABELS.VIEW_TEMPLATE}
          onClick={handleViewTemplate}
        />
        <AcButton
          color="primary"
          label={LABELS.CLAIM}
          onClick={handleClaimTemplate}
        />
      </div>
    </AcDialog>
  )
})
