import clsx from 'clsx'
import { ForwardedRef, forwardRef, useMemo } from 'react'

import styles from './ac-card.module.scss'

interface IAcCard {
  className?: string
  children?: React.ReactNode
  element?: 'section' | 'div' | 'article'
  padding?: 'none' | 8 | 14 | 24 | 32
  elevation?: 'none' | 'xs' | 'sm' | 'lg'
  theme?: 'light' | 'error' | 'info'
}

const AcCard = forwardRef(
  (
    {
      className,
      element: Element = 'div',
      children,
      padding = 24,
      elevation = 'xs',
      theme = 'light',
    }: IAcCard,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const getCardClassNames = useMemo(
      () =>
        clsx([
          className,
          styles['ac-card'],
          styles[`ac-card--padding-${padding}`],
          styles[`ac-card--elevation-${elevation}`],
          styles[`ac-card--theme-${theme}`],
        ]),
      [className, padding, elevation, theme]
    )
    return (
      <Element
        className={getCardClassNames}
        ref={ref}>
        {children}
      </Element>
    )
  }
)

AcCard.displayName = 'AcCard'

export { AcCard }
