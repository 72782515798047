export const WEBSOCKET_CHANNELS = {
  PRIVATE: {
    BUURTBASE: 'private-buurtbase',
  },
}

export const WEBSOCKET_EVENTS = {
  TEMPLATE: {
    CLAIM_CREATED: 'App\\Domains\\Order\\Events\\ClaimCreated',
    CLAIM_DELETED: 'App\\Domains\\Order\\Events\\ClaimDeleted',
  },
}
