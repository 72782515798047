import clsx from 'clsx'
import { useMemo } from 'react'

import { AcCard, AcIcon } from '../index.core.component'

import styles from './ac-alert.module.scss'
import { ICONS } from '@constants'

interface IAcAlert {
  className?: string
  severity?: 'error' | 'info'
  title?: string
  visible?: boolean
  /** @default 'md' */
  size?: 'sm' | 'md'
}

export const AcAlert = ({
  className,
  title,
  severity = 'error',
  visible,
  size = 'md',
}: IAcAlert) => {
  const getAlertClassNames = useMemo(
    () =>
      clsx([
        className,
        styles['ac-alert'],
        styles[`ac-alert--size-${size}`],
        visible && styles['ac-alert--visible'],
      ]),
    [className, severity, visible]
  )

  const renderIcon = useMemo(() => {
    switch (severity) {
      case 'error':
        return <AcIcon icon={ICONS.ERROR} />
      case 'info':
        return <AcIcon icon={ICONS.INFO} />
    }
  }, [severity])

  return (
    <AcCard
      className={getAlertClassNames}
      theme={severity}
      elevation="none"
      padding={visible ? 14 : 'none'}>
      {renderIcon}
      {title}
    </AcCard>
  )
}
