export const SITES = [
  {
    id: 2,
    label: 'Rotterdam',
  },
  {
    id: 1,
    label: 'Amsterdam',
  },
]
