export const AcIntlFloat = (value: number, digits?: number) =>
  new Intl.NumberFormat('nl-NL', {
    maximumFractionDigits: digits,
  }).format(value)

export const AcIntlPrice = (value: number, showCurrency = true) =>
  new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: showCurrency ? void 0 : 'code',
  })
    .format(value)
    .replace(/[\sA-Za-z]/g, '')

export const AcIntlInputFloat = (
  value?: string | number | readonly string[]
) =>
  typeof value === 'number'
    ? parseFloat(value.toString()).toFixed(2)
    : (value as string)

export const AcIntlInputPercentage = (
  value?: string | number | readonly string[]
) => (value ? `${Math.round(value as number)}%` : '')
