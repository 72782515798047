import clsx from 'clsx'
import { useMemo } from 'react'
import { useContext } from 'react'

import { AcTabsContext } from './ac-tabs'

import styles from './ac-tabs.module.scss'

export interface IAcTabPanel {
  children: React.ReactNode
  id: string
  label: string
}

export const AcTabPanel = ({ children, id }: IAcTabPanel) => {
  const { activeTab } = useContext(AcTabsContext)

  const isActive = useMemo(() => id === activeTab, [id, activeTab])

  const getTabPanelClassNames = useMemo(
    () =>
      clsx([
        styles['ac-tab-panel'],
        isActive && styles['ac-tab-panel--active'],
      ]),
    [isActive]
  )

  return (
    <section
      id={'ac-tab-panel--' + id}
      role="tabpanel"
      className={getTabPanelClassNames}>
      {children}
    </section>
  )
}
