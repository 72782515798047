import { LABELS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { AcGlobalSearchCategory } from '@components'
import { observer } from 'mobx-react-lite'

export const GlobalSearchCustomersTab = observer(() => {
  const { global_search } = useStore()
  return (
    <AcGlobalSearchCategory
      categoryName={LABELS.CUSTOMERS}
      path={ROUTE_PATHS.CUSTOMER_DETAIL}
      items={global_search.results?.customer}
      icon="USER_ALT"
    />
  )
})
