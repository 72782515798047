import { AcLoader, AcProfileTabTagsRow, AcTypography } from '@components'
import { LABELS, TAG_PREFERENCES } from '@constants'
import { useStore } from '@hooks/use-store'
import { ISingleTag } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'

export const AcProfileTabTags = observer(
  ({ data, loading }: { data: ISingleTag[] | null; loading?: boolean }) => {
    const { customer } = useStore()

    const isSelected = useCallback(
      (itemId: number) => customer.profile_tags.some(({ id }) => id === itemId),
      [customer.profile_tags]
    )

    const renderData = useMemo(() => {
      if (!data) return null

      const willSplit = data.length > 4
      const split = willSplit ? Math.ceil(data.length / 2) : -1
      const columns =
        split > -1 ? [data.slice(0, split), data.slice(split + 1)] : [data]

      return (
        <div
          className="outer"
          style={{
            gridTemplateColumns: willSplit ? '1fr 1fr' : '.5fr',
            display: 'grid',
            gap: '2rem',
            padding: '1rem 0',
          }}>
          {columns.map((singleColumn, i) => (
            <div key={i}>
              {singleColumn.map(singleItem => (
                <AcProfileTabTagsRow
                  key={singleItem.id}
                  item={singleItem}
                  selected={isSelected(singleItem.id)}
                  onChange={item => customer.handleProfileTag(item)}
                  value={
                    customer.profile_tags.find(({ id }) => id === singleItem.id)
                      ?.preference ?? TAG_PREFERENCES[2].value
                  }
                />
              ))}
            </div>
          ))}
        </div>
      )
    }, [data])

    return (
      <div>
        {loading && <AcLoader />}
        {!loading && (
          <>
            {!data?.length && (
              <AcTypography size="sm">
                {LABELS.NO_TAGS_WITH_THIS_CATEGORY}
              </AcTypography>
            )}
            {renderData}
          </>
        )}
      </div>
    )
  }
)
