import {
  AcButton,
  AcDialog,
  AcIcon,
  AcTextInput,
  AcTypography,
} from '@components'
import { ICONS, LABELS, TAG_CATEGORIES } from '@constants'
import { useStore } from '@hooks/use-store'
import { ISingleTag } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { AcSelect } from 'src/components/core/ac-select/ac-select'
import styles from './tags-edit.module.scss'
import { toast } from 'react-toastify'

export const TagsEditModal = observer(() => {
  const { tags, ui } = useStore()
  const [tagCategory, setTagCategory] = useState<
    ISingleTag['category'] | undefined
  >()
  const [tagName, setTagName] = useState<string>()

  const isDisabled = useMemo(
    () => !(!tags.loading && tagName !== '' && tagCategory !== undefined),
    [tagCategory, tagName, tags.loading]
  )

  const handleClose = () => {
    ui.closeModalGracefully()
  }

  const handleEdit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (tagCategory && tagName)
      await tags
        .updateTag(tagName, tagCategory)
        .then(() =>
          toast(LABELS.TAG_EDITED, { icon: <AcIcon icon={ICONS.CHECK} /> })
        )
        .catch(() => void 0)
  }

  useEffect(() => {
    if (tags.single) {
      setTagName(tags.single.name)
      setTagCategory(tags.single.category)
    }
  }, [tags.single])

  return (
    <AcDialog title={LABELS.TAG_EDIT}>
      <form onSubmit={e => handleEdit(e)}>
        <div className={styles['ac-tags-create-modal-form--intro']}>
          <AcTypography
            weight="light"
            element="span">
            Bepaal hieronder de naam en categorie van de tag. Houd de naam van
            de tag zo kort mogelijk.
          </AcTypography>
        </div>
        <div className={styles['ac-tags-create-modal-form--inputs']}>
          <AcTextInput
            type="text"
            loading={tags.loading}
            name="tag-name"
            id="tag-name"
            placeholder={LABELS.TAG_NAME}
            value={tagName}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTagName(e.target.value)
            }
          />
          <AcSelect
            inputFormat="value"
            loading={tags.loading}
            defaultValue={tagCategory}
            onChange={({ value }) => setTagCategory(value)}
            options={TAG_CATEGORIES}
            placeholder={LABELS.SELECT_CATEGORY}
            labelKey="label"
            valueKey="value"
            name="tag-category"
            id="tag-category"
          />
        </div>
        <div className={styles['ac-tags-create-modal-form--actions']}>
          <AcButton
            color="secondary"
            onClick={handleClose}
            label={LABELS.CANCEL}
          />
          <AcButton
            label={LABELS.TAG_EDIT}
            type="submit"
            disabled={isDisabled}
          />
        </div>
      </form>
    </AcDialog>
  )
})
