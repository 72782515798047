import { AcMoreButton } from '@components'
import { ICONS, LABELS, WEEKDAYS } from '@constants'
import { useStore } from '@hooks/use-store'
import { ITemplateDaysName } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { IAcLayeredMenuItem } from '../ac-layered-menu/ac-layered-menu-item/ac-layered-menu-item'

export const AcTemplateDayMoreMenu = observer(
  ({
    day,
    is_deletable,
  }: {
    day: ITemplateDaysName
    is_deletable: boolean
  }) => {
    const { template } = useStore()
    const isFirstDay = useMemo(
      () => template.current_days_by_name.indexOf(day) === 0,
      [template.current_days]
    )
    const isLastDay = useMemo(
      () =>
        template.current_days_by_name.indexOf(day) ===
        template.current_days.length - 1,
      [template.current_days]
    )

    const generateCopyDayChildOptions = useMemo(() => {
      const data = []
      const options: ITemplateDaysName[] = template.current_days_by_name.filter(
        singleDay => singleDay !== day
      )

      for (const option of options) {
        data.push({
          label: WEEKDAYS[option],
          onClick: () => template.handleCopyDay(day, option),
        })
      }

      return data
    }, [template.current_days_by_name])

    const generateMenuItems: IAcLayeredMenuItem[] = useMemo(() => {
      const menuItems: IAcLayeredMenuItem[] = []

      if (!isFirstDay) {
        menuItems.push({
          label: LABELS.MOVE_TO_PREVIOUS_DAY,
          icon: ICONS.ANGLE_LEFT,
          onClick: () =>
            template.handleSwitchDay(
              day,
              template.current_days_by_name[
                template.current_days_by_name.indexOf(day) - 1
              ]
            ),
        })
      }

      if (!isLastDay) {
        menuItems.push({
          label: LABELS.MOVE_TO_NEXT_DAY,
          icon: ICONS.ANGLE_RIGHT,
          onClick: () =>
            template.handleSwitchDay(
              day,
              template.current_days_by_name[
                template.current_days_by_name.indexOf(day) + 1
              ]
            ),
        })
      }

      menuItems.push(
        {
          label: LABELS.CLEAR_DAY,
          icon: ICONS.DELETE,
          onClick: () => template.handleClearDay(day),
        },
        {
          label: LABELS.COPY_TO,
          icon: ICONS.COPY,
          onClick: () => null,
          children: generateCopyDayChildOptions,
        }
      )

      if (is_deletable) {
        menuItems.push({
          label: LABELS.REMOVE_DAY,
          icon: ICONS.DELETE,
          onClick: () => template.handleRemoveDay(day),
        })
      }

      return menuItems
    }, [
      isFirstDay,
      isLastDay,
      day,
      is_deletable,
      template.current_days_by_name,
    ])

    return <AcMoreButton menuOptions={generateMenuItems} />
  }
)
