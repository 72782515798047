import { makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { DataGridStore } from './datagrid.stores'

export class InvoicesStore {
  _store: Store

  loading: boolean
  // invoice: unknown
  // invoices: unknown
  data_grid: DataGridStore

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.loading = true
    this.data_grid = new DataGridStore(this._store, STORE_KEYS.INVOICES)

    // this.invoice = {} as unknown
    // this.invoices = []
  }

  // getList = action(async () => {
  //   this._store.set(STORE_KEYS.INVOICES, KEYS.LOADING, true)

  //   try {
  //     const res = await this._store.api.products.get_list()
  //     this._store.set(STORE_KEYS.INVOICES, KEYS.INVOICES, res)
  //   } catch (e) {
  //     console.error(e)
  //   } finally {
  //     this._store.set(STORE_KEYS.INVOICES, KEYS.LOADING, false)
  //   }
  // })

  // getById = action(async (id: number) => {
  //   this._store.set(STORE_KEYS.INVOICES, KEYS.LOADING, true)

  //   try {
  //     const res = await this._store.api.products.get_single(id)
  //     this._store.set(STORE_KEYS.INVOICES, KEYS.INVOICES, res)
  //     return res
  //   } catch (e) {
  //     console.error(e)
  //   } finally {
  //     this._store.set(STORE_KEYS.INVOICES, KEYS.LOADING, false)
  //   }
  // })
}
