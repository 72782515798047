import clsx from 'clsx'
import { useMemo } from 'react'

import { ICON_ELEMENTS } from '@constants'
import { AcIcon, AcTypography } from '../index.core.component'

import styles from './ac-chip.module.scss'

interface IChipProps {
  variant?: 'default' | 'success' | 'error'
  label: string
  icon?: keyof typeof ICON_ELEMENTS
  pill?: boolean
  outlined?: boolean
  size?: 'xs' | 'sm'
  badge?: string
}

export const AcChip = ({
  variant = 'default',
  label,
  icon,
  pill,
  outlined,
  size = 'sm',
  badge,
}: IChipProps) => {
  const getChipClassName = useMemo(
    () =>
      clsx(
        styles['ac-chip'],
        styles[`ac-chip--${variant}`],
        badge && styles['ac-chip--badge'],
        icon && styles['ac-chip--has-icon'],
        pill && styles['ac-chip--pill'],
        outlined && styles['ac-chip--outlined']
      ),
    [variant, icon, pill, outlined, badge]
  )

  return (
    <span
      className={getChipClassName}
      style={
        {
          '--badge-color': badge,
        } as React.CSSProperties
      }>
      {icon && (
        <AcIcon
          icon={icon}
          className={styles['ac-chip-icon']}
        />
      )}
      <AcTypography
        element="span"
        size={size}>
        {label}
      </AcTypography>
    </span>
  )
}
