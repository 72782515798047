type DataWithArrays<T> = {
  [K in keyof T]: T[K] | T[K][]
}

export class AcFormDataManipulator<T extends Record<string, any>> {
  _data: DataWithArrays<T>
  data: T

  constructor(data: T) {
    const sharedData = { ...data }
    this._data = sharedData
    this.data = sharedData

    return this
  }

  /**
   * Function that iterates over objects and returns an array of values specified by valueKey
   */

  mapObjectsToValue(
    fieldNames: keyof T | (keyof T)[],
    valueKey = 'id'
  ): AcFormDataManipulator<T> {
    const fields = Array.isArray(fieldNames) ? fieldNames : [fieldNames]

    for (const field of fields) {
      const value = this._data[field]
      if (Array.isArray(value)) {
        this._data[field] = value.map((obj: any) => obj[valueKey])
      }
    }

    return this
  }

  /**
   * Function that filters objects from an array when keys in object does not exist
   */

  filterIncompleteObjects(
    fieldNames: keyof T | (keyof T)[],
    requiredKeys: string[]
  ): AcFormDataManipulator<T> {
    const fields = Array.isArray(fieldNames) ? fieldNames : [fieldNames]

    for (const field of fields) {
      const value = this._data[field]
      if (Array.isArray(value)) {
        const filtered = value.filter((obj: any) => {
          return requiredKeys.every(key =>
            Object.prototype.hasOwnProperty.call(obj, key)
          )
        })
        this._data[field] = filtered
      }
    }
    return this
  }

  /**
   * Function that removes object key from an array of objects when value of object[key] is falsy
   */

  deleteFalsyKeyFromArrayObject(
    fieldNames: keyof T | (keyof T)[],
    falsyKeysToCheck: string[]
  ): AcFormDataManipulator<T> {
    const fields = Array.isArray(fieldNames) ? fieldNames : [fieldNames]

    for (const field of fields) {
      const value = this._data[field]
      if (Array.isArray(value)) {
        const mapped = value.map((obj: any) => {
          for (const key of falsyKeysToCheck) {
            obj[key] = obj[key] ? obj[key] : undefined
          }
          return obj
        })
        this._data[field] = mapped
      }
    }
    return this
  }

  /**
   * Function that removes the entry from formdata when it does not match the expected function-type
   */
  deleteInvalidEntry(
    fieldNames: keyof T | (keyof T)[],
    // eslint-disable-next-line @typescript-eslint/ban-types
    expectedType: Function
  ): AcFormDataManipulator<T> {
    const fields = Array.isArray(fieldNames) ? fieldNames : [fieldNames]

    for (const field of fields) {
      const value = this._data[field]

      if (expectedType === Number) {
        if (typeof value !== 'number') delete this._data[field]
      } else if (!(value instanceof expectedType)) delete this._data[field]
    }

    return this
  }
}
