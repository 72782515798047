import { AcLoader } from '@components'
import { ROUTE_PATHS } from '@constants'
import { useEffect } from 'react'

export const AcTemplateList = () => {
  useEffect(() => {
    window.location.href = ROUTE_PATHS.TEMPLATE_LIST_EXT
  }, [])
  return <AcLoader />
}
