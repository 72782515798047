import { useState, useRef } from 'react'
import PropTypes from 'prop-types'

// Crop components
import ReactCrop from 'react-image-crop'
import type { PixelCrop, PercentCrop } from 'react-image-crop'
import {
  useDebounceEffect,
  canvasPreview,
  centerAspectCrop,
} from '@utilities/helpers/ac-image-crop-utils'

// Other components
import { AcButton } from '@components'

// Contants
import { FORM_CONSTANTS, LABELS } from '@constants'

// Styles
import styles from './ac-image-input.module.scss'
import 'react-image-crop/dist/ReactCrop.css'

interface Props {
  src: string
  selectCroppedImage: (image: HTMLCanvasElement) => void
  closeModal: () => void
}

const AcImageCrop: React.FC<Props> = ({
  src,
  selectCroppedImage,
  closeModal,
}) => {
  // State
  const previewCanvasRef = useRef<null | HTMLCanvasElement>(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState<PercentCrop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const scale = 1
  const rotate = 0
  const aspect = 1 / 1

  // Methods
  const onImageLoad = (e: React.ChangeEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      if (width && height) {
        setCrop(centerAspectCrop(width, height, aspect))
      }
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        )
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  const emitCropppedImage = () => {
    const croppedImage = previewCanvasRef.current as HTMLCanvasElement
    selectCroppedImage(croppedImage)
  }

  return (
    <div>
      {src && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={c => setCompletedCrop(c)}
          aspect={aspect}
          className={styles['ac-image-crop']}>
          <img
            ref={imgRef}
            alt={FORM_CONSTANTS.CROP_IMAGE}
            src={src}
            style={{
              transform: `scale(${scale}) rotate(${rotate}deg)`,
            }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      <div className={styles['ac-image-crop-footer']}>
        <AcButton
          label={LABELS.CANCEL}
          color="secondary"
          onClick={closeModal}
        />
        <AcButton
          label={LABELS.CONFIRM}
          onClick={emitCropppedImage}
        />
      </div>
      {completedCrop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            objectFit: 'contain',
            width: completedCrop.width,
            height: completedCrop.height,
          }}
        />
      )}
    </div>
  )
}

AcImageCrop.propTypes = {
  src: PropTypes.any,
  selectCroppedImage: PropTypes.any,
  closeModal: PropTypes.any,
}

export default AcImageCrop
