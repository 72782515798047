import { AcButton, AcIcon } from '@components'
import { DATE_FORMATS, DYNAMIC_LABELS, ICONS } from '@constants'
import { useOnClickOutside } from '@hooks/use-click-outside'
import { useStore } from '@hooks/use-store'
import dayjs, { Dayjs } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { observer } from 'mobx-react-lite'
import { createRef, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { AcLayeredMenu } from '../index.features.components'

// First we have to extend dayJS to use ISOweeks/
dayjs.extend(isoWeek)
// Then we set the start of the week on monday
dayjs.Ls.en.weekStart = 1

export const AcTemplateWeekSelection = observer(() => {
  const { template } = useStore()

  if (!template.info.week) return null
  const wrapRef = createRef<HTMLElement>()
  const [parentComponent, setParentComponent] =
    useState<HTMLButtonElement | null>(null)
  const [menuComponent, setMenuComponent] = useState<HTMLDivElement | null>(
    null
  )
  const { styles, attributes } = usePopper(parentComponent, menuComponent, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })
  const [menuOpen, setMenuOpen] = useState(false)

  const generateMenuLabel = (date: Dayjs) => {
    if (!date) return null

    return DYNAMIC_LABELS.WEEK_N_FROM_TILL(
      date.isoWeek(),
      date.format(DATE_FORMATS.DAY_MONTH_YEAR),
      date.add(4, 'days').format(DATE_FORMATS.DAY_MONTH_YEAR)
    )
  }

  /* We define a list of 4 objects. 
  They are loosely typed so we can alter them in the reduce function below */

  const generateMenu = useMemo(() => {
    const menuItems: { [key: string]: any }[] = [
      {
        id: 0,
      },
      {
        id: 1,
      },
      {
        id: 2,
      },
      {
        id: 3,
      },
    ]

    // We use reduce to alter the objects defined in menuItems
    return menuItems.reduce((bulk, curr, i) => {
      // If index = 0 (first item) we set the start date to next week monday.
      if (i === 0) {
        // Get current isoWeek's first day (monday), add 1 week
        const nextMonday = dayjs().isoWeekday(1).add(1, 'week')
        // We transform the curr object to the following format.
        curr = {
          date: nextMonday,
          label: generateMenuLabel(nextMonday),
          onClick: () =>
            template.handleChangeWeek(nextMonday.isoWeek(), nextMonday.year()),
        }
        bulk = [curr]
      } else {
        const startDate = bulk[i - 1].date.add(1, 'week')
        bulk[i] = {
          date: startDate,
          label: generateMenuLabel(startDate),
          onClick: () =>
            template.handleChangeWeek(startDate.isoWeek(), startDate.year()),
        }
      }
      return bulk
    }, menuItems)
  }, [])

  useOnClickOutside(wrapRef, () => setMenuOpen(false))
  return (
    <span ref={wrapRef}>
      <AcButton
        ref={setParentComponent}
        onClick={() => setMenuOpen(!menuOpen)}
        icon={ICONS.DATE_ALT}
        color="secondary"
        label={DYNAMIC_LABELS.WEEK_N(template?.info?.week?.week)}>
        <AcIcon icon={menuOpen ? ICONS.ANGLE_UP : ICONS.ANGLE_DOWN} />
      </AcButton>
      {menuOpen && (
        <div
          style={{ ...styles.popper, zIndex: 99 }}
          ref={setMenuComponent}
          {...attributes.popper}>
          <AcLayeredMenu
            menuOptions={generateMenu as any}
            handleClose={() => setMenuOpen(false)}
          />
        </div>
      )}
    </span>
  )
})
