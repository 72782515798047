import clsx from 'clsx'
import { useContext, useId, useMemo } from 'react'

import { ICONS, ICON_ELEMENTS } from '@constants'
import { AcIcon } from '../index.core.component'
import { AcAccordionContext } from './ac-accordion'

import styles from './ac-accordion.module.scss'

interface IAcAccordionItemBase {
  title: string
  children: React.ReactNode
  icon?: keyof typeof ICON_ELEMENTS
  showIcon?: boolean
  iconElement?: React.ReactNode
}

export const AcAccordionItem = ({
  title,
  children,
  icon,
  showIcon,
  iconElement,
}: IAcAccordionItemBase) => {
  const id = useId()
  const { activeItemId, setActiveItemId } = useContext(AcAccordionContext)

  const handleHeaderClick = () => {
    if (activeItemId === id) setActiveItemId('')
    else setActiveItemId(id)
  }

  const isExpanded = useMemo(() => activeItemId === id, [id, activeItemId])

  return (
    <div
      className={styles['ac-accordion-item']}
      data-id={id}
      aria-expanded={isExpanded}>
      <button
        type="button"
        onClick={handleHeaderClick}
        className={styles['ac-accordion-item-header']}>
        <div>
          {showIcon && iconElement}
          {showIcon && icon && (
            <AcIcon
              icon={icon}
              size="lg"
            />
          )}
        </div>
        <span className={styles['ac-accordion-item-title']}>{title}</span>
        <div>
          <AcIcon
            className={clsx(
              styles['ac-accordion-item-arrow'],
              isExpanded && styles['ac-accordion-item-arrow--expanded']
            )}
            icon={ICONS.ANGLE_DOWN}
            size="lg"
          />
        </div>
      </button>
      <section className={styles['ac-accordion-item-content-wrapper']}>
        <div className={styles['ac-accordion-item-content']}>{children}</div>
      </section>
    </div>
  )
}
