import { ENDPOINTS, WEBSOCKET_CHANNELS } from '@constants'
import { getFromStorage } from '@helpers/browser-storage.helpers'
import Pusher from 'pusher-js'

export class ClientWebSocket {
  client: Pusher | null
  key: string
  token: string | undefined

  constructor() {
    this.client = null
    this.key = process.env.REACT_APP_SOCKET_KEY as string
    this.token = getFromStorage('access_token')
  }

  getOptions = () => ({
    broadcaster: 'pusher',
    key: this.key,
    cluster: 'eu',
    forceTLS: true,
    authEndpoint: ENDPOINTS.AUTH.SOCKET(),
    auth: {
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
      },
    },
  })

  connect = (callBack: (event: string, data: any) => void) => {
    if (this.client !== null) return
    this.client = new Pusher(this.key, this.getOptions())
    const channel = this.client.subscribe(WEBSOCKET_CHANNELS.PRIVATE.BUURTBASE)
    return channel.bind_global(callBack)
  }

  disconnect = () => {
    if (this.client)
      this.client?.unsubscribe(WEBSOCKET_CHANNELS.PRIVATE.BUURTBASE)
  }
}
