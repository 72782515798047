import { useNavigate } from 'react-router-dom'

// Components
import { AcButton, AcChip, AcTitle } from '@components'

// Constants
import { ICONS, LABELS } from '@constants'

// Styling
import styles from './app-header.module.scss'

interface IAppHeaderProps {
  title: string
  showBackButton?: boolean
  navigateBackTo?: string
  active?: boolean
  archived?: boolean
  action?: React.ReactNode
}

export const AcAppHeader = ({
  title,
  showBackButton = false,
  navigateBackTo,
  active = false,
  archived = false,
  action,
}: IAppHeaderProps) => {
  const navigate = useNavigate()

  const goToPath = () => {
    if (navigateBackTo) {
      navigate(navigateBackTo)
    } else {
      navigate(-1)
    }
  }

  return (
    <div className={styles['app-header']}>
      <div className={styles['app-header__left']}>
        {showBackButton && (
          <AcButton
            variant="icon"
            label={LABELS.BACK}
            icon={ICONS.PREVIOUS}
            onClick={goToPath}
          />
        )}
        <AcTitle level={1}>{title}</AcTitle>
        {active && (
          <AcChip
            icon={ICONS.CHECK}
            label={LABELS.ACTIVE}
            variant="success"
          />
        )}
        {archived && <AcChip label={LABELS.ARCHIVED} />}
      </div>
      <div className={styles['app-header__right']}>{action}</div>
    </div>
  )
}
