import {
  AcProductDetail,
  HomeView,
  AcAuth,
  AcLogin,
  AcProductList,
  AcSupplierList,
  AcSupplierDetail,
  AcTemplateDetail,
  AcTemplateList,
  AcProductCreate,
  AcSupplierCreate,
  AcProductTagsList,
  AcCustomerProfile,
  AcUserList,
  AcUserProfile,
  AcUserProfileCreate,
} from '@views'
import { AcDefaultLayout, AcGuestLayout } from '@layouts'

import { toBuurtbase } from '@helpers/redirect-buurtbase-url'
import { ISingleRoute } from '@typings'
import { ROUTE_KEYS } from './keys.contants'
import { PERMISSIONS } from './permissions.constants'

export const ROUTE_PATHS = {
  AUTH: '/auth',
  COUPON_LIST: toBuurtbase('/discount/coupons'),
  CUSTOMER_LIST: toBuurtbase('/customers'),
  CUSTOMER_DETAIL: toBuurtbase('/customers/:id'),
  CUSTOMER_PROFILE: '/customers/:id/profile',
  DASHBOARD: toBuurtbase('/'),
  DELIVERY_LIST: toBuurtbase('/weeks'),
  DELIVERY_MOMENT_LIST: toBuurtbase('/week/delivery-moments'),
  USER_LIST: '/users',
  USER_PROFILE: '/users/:id',
  USER_PROFILE_CREATE: '/users/create',
  FORMULA_DETAIL: toBuurtbase('/templates/:id/formulas'),
  HOME: '/',
  INVOICE_LIST: toBuurtbase('/invoices'),
  LOGIN: '/login',
  LOGOUT: toBuurtbase('/auth/logout'),
  MANUAL_CATEGORY_LIST: toBuurtbase('/manuals/categories'),
  NOTIFICATION_LIST: toBuurtbase('/holidays'),
  ORDER_LIST: toBuurtbase('/suppliers/orders'),
  PRODUCT_DETAIL: '/products/:id',
  PRODUCT_LIST: '/products',
  PRODUCT_CREATE: '/products/create',
  PRODUCT_TAGS: '/products/tags',
  PRODUCT_TAGS_DETAIL: '/products/tags/:id',
  SUPPLIER_DETAIL: '/suppliers/:id',
  SUPPLIER_CREATE: '/suppliers/create',
  SUPPLIER_LIST: '/suppliers',
  SEPA_TOOLS: toBuurtbase('/sepa'),
  SYSTEM_SETTINGS: toBuurtbase('/system'),
  TEMPLATE_LIST: '/templates',
  TEMPLATE_LIST_EXT: toBuurtbase('/templates'),
  TEMPLATE_DETAIL: '/templates/:id',
  TEMPLATE_DETAIL_EXT: toBuurtbase('/templates/:id'),
  WEBSHOP_ORDER_LIST: toBuurtbase('/webshoporders'),
  WEEK_ORDER_DETAIL: toBuurtbase('/order/:id'),
} as const

export const ROUTES: ISingleRoute[] = [
  {
    id: ROUTE_KEYS.HOME,
    path: ROUTE_PATHS.HOME,
    element: <HomeView />,
    layout: <AcDefaultLayout />,
    allowed: [],
  },
  {
    id: ROUTE_KEYS.LOGIN,
    path: ROUTE_PATHS.LOGIN,
    element: <AcLogin />,
    layout: <AcGuestLayout />,
    isPublic: true,
  },
  {
    id: ROUTE_KEYS.AUTH,
    path: ROUTE_PATHS.AUTH,
    element: <AcAuth />,
    layout: <AcGuestLayout />,
    isPublic: true,
  },
  {
    id: ROUTE_KEYS.CUSTOMER_PROFILE,
    path: ROUTE_PATHS.CUSTOMER_PROFILE,
    element: <AcCustomerProfile />,
    layout: <AcDefaultLayout />,
  },
  {
    id: ROUTE_KEYS.USER_LIST,
    path: ROUTE_PATHS.USER_LIST,
    element: <AcUserList />,
    layout: <AcDefaultLayout />,
    allowed: [PERMISSIONS.USER.READ],
  },
  {
    id: ROUTE_KEYS.USER_PROFILE,
    path: ROUTE_PATHS.USER_PROFILE,
    element: <AcUserProfile />,
    layout: <AcDefaultLayout />,
    allowed: [PERMISSIONS.USER.READ],
  },
  {
    id: ROUTE_KEYS.USER_PROFILE_CREATE,
    path: ROUTE_PATHS.USER_PROFILE_CREATE,
    element: <AcUserProfileCreate />,
    layout: <AcDefaultLayout />,
    allowed: [PERMISSIONS.USER.STORE],
  },
  {
    id: ROUTE_KEYS.PRODUCT_LIST,
    path: ROUTE_PATHS.PRODUCT_LIST,
    element: <AcProductList />,
    layout: <AcDefaultLayout />,
  },
  {
    id: ROUTE_KEYS.PRODUCT_TAGS,
    path: ROUTE_PATHS.PRODUCT_TAGS,
    element: <AcProductTagsList />,
    layout: <AcDefaultLayout />,
  },
  {
    id: ROUTE_KEYS.PRODUCT_TAGS_DETAILS,
    path: ROUTE_PATHS.PRODUCT_TAGS_DETAIL,
    element: <AcProductTagsList />,
    layout: <AcDefaultLayout />,
  },
  {
    id: ROUTE_KEYS.PRODUCT_CREATE,
    path: ROUTE_PATHS.PRODUCT_CREATE,
    element: <AcProductCreate />,
    layout: <AcDefaultLayout />,
  },
  {
    id: ROUTE_KEYS.PRODUCT_DETAIL,
    path: ROUTE_PATHS.PRODUCT_DETAIL,
    element: <AcProductDetail />,
    layout: <AcDefaultLayout />,
  },
  {
    id: ROUTE_KEYS.SUPPLIER_LIST,
    path: ROUTE_PATHS.SUPPLIER_LIST,
    element: <AcSupplierList />,
    layout: <AcDefaultLayout />,
  },
  {
    id: ROUTE_KEYS.SUPPLIER_DETAIL,
    path: ROUTE_PATHS.SUPPLIER_DETAIL,
    element: <AcSupplierDetail />,
    layout: <AcDefaultLayout />,
  },
  {
    path: ROUTE_PATHS.TEMPLATE_LIST,
    element: <AcTemplateList />,
    layout: <AcDefaultLayout />,
  },
  {
    path: ROUTE_PATHS.TEMPLATE_DETAIL,
    element: <AcTemplateDetail />,
    layout: <AcDefaultLayout />,
  },
  {
    id: ROUTE_KEYS.SUPPLIER_CREATE,
    path: ROUTE_PATHS.SUPPLIER_CREATE,
    element: <AcSupplierCreate />,
    layout: <AcDefaultLayout />,
  },
]
