import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { ICustomerProfile } from '@typings'
import { AxiosResponse } from 'axios'

export class CustomerApi extends AxiosBaseApi {
  getSingle = (id: number) =>
    this.api
      .get<AxiosResponse<{ data: any }>>(ENDPOINTS.CUSTOMER.SINGLE(id))
      .then(res => res.data.data)

  getProfile = (id: ICustomerProfile['customer_id']) =>
    this.api
      .get<AxiosResponse<{ data: ICustomerProfile }>>(
        ENDPOINTS.CUSTOMER.PROFILE_SINGLE(id)
      )
      .then(res => res.data.data)

  updateProfile = (id: ICustomerProfile['id'], data: ICustomerProfile) =>
    this.api
      .post<AxiosResponse<{ data: ICustomerProfile }>>(
        ENDPOINTS.CUSTOMER.SINGLE(id),
        data
      )
      .then(res => res.data.data)
}
