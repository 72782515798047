export const ROLES = {
    'super-admin': 'Super admin',
    'office-manager': 'Office manager',
    luncher: 'Luncher',
    planner: 'Planner'
}

export const ROLE_GROUPS = {
    employee: 'employee',
    contact: 'contact'
}