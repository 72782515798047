import {
  AcAlert,
  AcAppHeader,
  AcButton,
  AcFormProvider,
  AcIcon,
  AcTab,
  AcTabs,
} from '@components'
import {
  GLOBAL_ERRORS,
  ICONS,
  KEYS,
  LABELS,
  ROUTE_PATHS,
  SUPPLIER_DETAIL_TABS,
} from '@constants'
import { useStore } from '@hooks/use-store'
import { ISupplierInformationTab } from '@partials/tabs/supplier-detail/supplier-information'
import { ISupplier } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { useLocation, useParams } from 'react-router'
import { toast } from 'react-toastify'

export const AcSupplierDetail = observer((): JSX.Element => {
  const { suppliers } = useStore()
  const locationParams = useParams()
  const { pathname } = useLocation()

  const isCreate = useMemo(() => {
    return pathname === ROUTE_PATHS.SUPPLIER_CREATE
  }, [pathname])

  const [tabErrors, setTabErrors] = useState(new Set<string>())
  const [editMode, setEditMode] = useState(false)

  const handleSuccess = (newSupplier: ISupplier) => {
    setTabErrors(new Set())
    setEditMode(false)
    form.reset(newSupplier)
    toast(LABELS.SAVED_SUPPLIER, {
      toastId: KEYS.SAVED_SUPPLIER,
      icon: <AcIcon icon={ICONS.CHECK} />,
    })
  }

  const handleErrors = (errors: Record<string, string[]>) => {
    const errorKeys = Object.keys(errors)

    errorKeys.map(singleKey =>
      form.setError(singleKey as string, {
        type: 'custom',
        message: errors[singleKey][0],
      })
    )
  }

  const onSubmit = async (data: FieldValues) => {
    setTabErrors(new Set())
    await suppliers
      .updateSupplier(data as ISupplier)
      .then(updatedSupplier => handleSuccess(updatedSupplier as ISupplier))
      .catch(e => {
        handleErrors(e)
      })
  }

  const hasErrors = useMemo(() => !!tabErrors.size, [tabErrors])

  const setTabError = (name: string) => {
    setTabErrors(value => new Set(...value).add(name))
  }

  const form = useForm({
    defaultValues: async () => {
      const id = parseInt(locationParams?.id || '')
      if (!isCreate) return await suppliers.getById(id)
      return
    },
  })

  const onFormSubmit = () => {
    form.handleSubmit(onSubmit, console.error)()
  }

  useEffect(() => {
    // suppliers.getJoins()
    return () => suppliers.clearSupplier()
  }, [])

  return (
    <>
      <AcAppHeader
        showBackButton
        navigateBackTo={ROUTE_PATHS.SUPPLIER_LIST}
        active={suppliers.supplier.active ?? false}
        title={suppliers.supplier.name || ''}
        action={
          <>
            {!isCreate && (
              <AcButton
                icon={suppliers.current_active ? ICONS.X_CIRCLE : ICONS.CHECK}
                color="secondary"
                onClick={() => suppliers.handleArchiveSupplier()}
                label={
                  suppliers.current_archived
                    ? LABELS.ACTIVE_ACTION
                    : LABELS.ARCHIVE_ACTION
                }
              />
            )}
            <AcButton
              onClick={editMode ? onFormSubmit : () => setEditMode(true)}
              label={editMode ? LABELS.SAVE : LABELS.MODIFY}
            />
          </>
        }
      />
      <AcAlert
        title={GLOBAL_ERRORS.FORM_STATE_INVALID}
        visible={hasErrors}
      />
      <AcFormProvider
        form={form}
        onSubmit={onFormSubmit}
        readOnly={!editMode}
        loading={suppliers.loading}>
        <AcTabs
          tabs={SUPPLIER_DETAIL_TABS}
          errors={tabErrors}>
          <AcTab<ISupplierInformationTab>
            name={KEYS.SUPPLIER_INFORMATION}
            onError={() => setTabError(KEYS.SUPPLIER_INFORMATION)}
          />
          <AcTab
            name={KEYS.SUPPLIER_CONTACTS}
            onClick={() => alert(123)}
          />
        </AcTabs>
      </AcFormProvider>
    </>
  )
})
