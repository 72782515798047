import { useEffect } from 'react'

export function useOnClickOutside(
  ref: React.RefObject<HTMLElement>,
  handler: (event: TouchEvent | MouseEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref?.current?.contains(event.target as Element)) {
        return
      }
      handler(event)
    }

    document.addEventListener('mousedown', listener, { passive: true })
    document.addEventListener('touchstart', listener, { passive: true })
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
