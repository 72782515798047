import { observer } from 'mobx-react-lite'

import { AcAppMenu, AcContainer } from '@components'
import { Outlet } from 'react-router-dom'

import { useStore } from '@hooks/use-store'

import styles from './ac-default-layout.module.scss'

export const AcDefaultLayout = observer((): JSX.Element => {
  const { ui } = useStore()

  return (
    <div id="app">
      <AcAppMenu />
      <AcContainer
        className={ui.menu.dense && styles['ac-default-layout--menu-dense']}
        element="main">
        <Outlet />
      </AcContainer>
    </div>
  )
})
