import { AcIcon, AcTitle, AcTypography } from '@components'
import { ICONS, LABELS, WEEKDAYS } from '@constants'
import { useStore } from '@hooks/use-store'
import { ITemplateTodoExtrasDataDay } from '@typings'
import { AcTemplateTodoExtrasDayProduct } from '@components'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { AcIntlDDMMYYYYHHMM } from '@helpers/ac-intl-datetime.helper'
import styles from './ac-template-todo-extras.module.scss'

export const AcTemplateTodoExtras = observer(() => {
    const { template } = useStore()

    const renderOrderDateTime = useMemo(() => {
        if (!template?.current_todo_extras?.data?.created_at) return null
        const prettyDate = AcIntlDDMMYYYYHHMM(new Date(template?.current_todo_extras?.data?.created_at)) || '-'
        return <AcTypography element='span' color='neutral-500' size='xs'>{LABELS.SUBMITTED_ORDER_AT}{prettyDate}</AcTypography>

    }, [template.current_todo_extras?.data?.created_at])

    const renderExtrasPerDay = useMemo(
        () => {
            if (!template.current_todo_extras?.data?.days?.length) return null
            return template.current_todo_extras?.data?.days?.map(
                (day: ITemplateTodoExtrasDataDay) => (
                    <div key={day.day} className={styles['ac-template-todo-extras-day']}>
                        <AcTypography element='span' size='sm' weight='semibold'>{WEEKDAYS[day.day]}</AcTypography>
                        {!day.products?.length
                            ? <AcTypography element='span' size='xs'>-</AcTypography>
                            : day.products.map((prod, i) => <AcTemplateTodoExtrasDayProduct key={i} day={day.day} product={prod} />)}
                    </div>
                )
            )
        },
        [template.current_todo_extras?.data]
    )

    const renderEmpty = useMemo(() => (
        <div className={styles['ac-template-todo-extras-empty']}>
            <AcIcon
                className={styles['ac-template-todo-extras-empty-icon']}
                icon={ICONS.CHECKLIST}
                size="xl"
            />
            <AcTitle
                level={3}
                className={styles['ac-template-todo-extras-empty-title']}>
                {LABELS.NO_EXTRAS_AVAILABLE}
            </AcTitle>
        </div>
    ), [])

    return (
        <div className={styles['ac-template-todo-extras']}>
            {renderOrderDateTime}
            {template.current_todo_extras?.data ? renderExtrasPerDay : renderEmpty}
        </div>
    )
})
