import { KEYS, STORE_KEYS, WEEK_MENUS } from '@constants'
import { Store } from '@stores'
import { ICustomerProfile, ICustomerProfileTag } from '@typings'
import { action, makeAutoObservable } from 'mobx'

const handleWeekMenu = (values: ICustomerProfile) => {
  const { week_menu, ...rest } = values
  const _values = rest
  if (Array.isArray(week_menu)) {
    Object.assign(_values, {
      salad_active: week_menu.some(({value}) => value === KEYS.SALAD),
      soup_active: week_menu.some(({ value }) =>  value === KEYS.SOUP)
    })
  }
  return _values as ICustomerProfile
}
const setWeekMenu = (values: ICustomerProfile) => {
  Object.assign(values, {
    week_menu: [
      values.soup_active ? WEEK_MENUS.find(menu => menu.value === KEYS.SOUP) : '',
      values.salad_active ? WEEK_MENUS.find(menu => menu.value === KEYS.SALAD) : ''
    ].filter(Boolean)
  })
  return values
}
export class CustomerStore {
  _store: Store
  loading: boolean
  all: Nullable<any[]>
  single: Nullable<any>
  profile: ICustomerProfile
  profile_tags: ICustomerProfileTag[]

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.loading = true
    this.all = null
    this.single = null
    this.profile = {} as ICustomerProfile
    this.profile_tags = []
  }

  @action
  handleProfileTag = (tag: ICustomerProfileTag) => {
    const itemExists = this.profile_tags.find(({ id }) => id === tag.id)
    const isSame =
      itemExists &&
      itemExists?.id === tag.id &&
      itemExists.preference === tag.preference
    const changedPref = itemExists && !isSame

    let newValues = [...this.profile_tags]
    if (isSame) {
      newValues = this.profile_tags.filter(({ id }) => id !== tag.id)
    } else if (!isSame && changedPref) {
      const filteredTags = this.profile_tags.filter(({ id }) => id !== tag.id)
      newValues = [...filteredTags, tag]
    } else {
      newValues = [...this.profile_tags, tag]
    }

    this._store.set(STORE_KEYS.CUSTOMER, KEYS.PROFILE_TAGS, newValues)
  }

  @action
  processData = (data: any) => {
    const _data = data
    _data.tags = this.profile_tags

    return _data
  }

  @action
  updateProfile = async (data: ICustomerProfile) => {
    data = handleWeekMenu(data)
    this._store.set(STORE_KEYS.CUSTOMER, KEYS.LOADING, true)
    try {
      const _data = this.processData(data)
      const res = await this._store.api.customer.updateProfile(
        this.profile?.customer_id,
        _data
      )

      const formattedTags = res.tags.map(({ id, preference }) => ({
        id,
        preference
      }))
      this._store.set(STORE_KEYS.CUSTOMER, KEYS.PROFILE, setWeekMenu(res))
      this._store.set(STORE_KEYS.CUSTOMER, KEYS.PROFILE_TAGS, formattedTags)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      this._store.set(STORE_KEYS.CUSTOMER, KEYS.LOADING, false)
    }
  }

  @action
  getProfile = async (id: ICustomerProfile['customer_id']) => {
    this._store.set(STORE_KEYS.CUSTOMER, KEYS.LOADING, true)
    try {
      const res = await this._store.api.customer.getProfile(id)
      this._store.set(STORE_KEYS.CUSTOMER, KEYS.PROFILE, setWeekMenu(res))
      this._store.set(STORE_KEYS.CUSTOMER, KEYS.PROFILE_TAGS, res.tags)
      return res
    } catch (e) {
      console.log('e: ', e)
    } finally {
      this._store.set(STORE_KEYS.CUSTOMER, KEYS.LOADING, false)
    }
  }
}
