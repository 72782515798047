import { AcCard } from '@components'
import {
  AcLayeredMenuItem,
  IAcLayeredMenuItem,
} from './ac-layered-menu-item/ac-layered-menu-item'
import { useMemo } from 'react'

interface IAcLayeredMenuProps {
  menuOptions: IAcLayeredMenuItem[]
  handleClose?: () => void
}

export const AcLayeredMenu = ({
  menuOptions,
  handleClose,
}: IAcLayeredMenuProps) => {
  const renderMenuOptions = useMemo(() => {
    return menuOptions.map(singleMenuOption => (
      <AcLayeredMenuItem
        handleClose={handleClose}
        {...singleMenuOption}
        key={singleMenuOption.label}
      />
    ))
  }, [menuOptions])
  return (
    <AcCard
      elevation="sm"
      padding="none">
      {renderMenuOptions}
    </AcCard>
  )
}
