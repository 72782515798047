import { ASSERT_ERRORS } from '@constants'

type IErrorKey = keyof typeof ASSERT_ERRORS
type IAcAssertionObject = {
  [ErrorKey in IErrorKey]?: unknown
}

export class AcAssert {
  error: boolean
  component: string

  constructor(component: string) {
    this.component = component
    this.error = false
  }

  isIterable(properties: IAcAssertionObject) {
    const errorKeys = Object.keys(properties) as IErrorKey[]
    for (const errorKey of errorKeys) {
      const iterator = properties[errorKey] as Iterable<unknown>
      if (!iterator) this.emitError(errorKey)
      const isIterable = typeof iterator[Symbol.iterator] === 'function'
      isIterable || this.emitError(errorKey)
    }
  }

  isNullish(properties: IAcAssertionObject) {
    const errorKeys = Object.keys(properties) as IErrorKey[]
    for (const errorKey of errorKeys) {
      properties[errorKey] ?? this.emitError(errorKey)
    }
  }

  emitError(errorKey: IErrorKey) {
    this.error = true
    console.error(
      `[${this.component}] ` + ASSERT_ERRORS[errorKey],
      `(${errorKey})`
    )
  }
}
