import { ITemplateValidateRemark } from '@typings'
import { toJS } from 'mobx'

import styles from './ac-template-match-rules.module.scss'
import clsx from 'clsx'
import { AcIcon } from '@components'
import { DYNAMIC_LABELS, ICONS, LABELS } from '@constants'

type IAcTemplateMatchRules = {
  remarks: ITemplateValidateRemark[]
}

const getRemarkIcon = (type: ITemplateValidateRemark['type']) => {
  switch (type) {
    case 'WARNING':
      return ICONS.WARNING_CIRCLE_FILLED
    case 'ERROR':
      return ICONS.X_CIRCLE_FILLED
  }
}

export const AcTemplateMatchRules = ({ remarks }: IAcTemplateMatchRules) => {
  return (
    <ul className={styles['ac-template-match-rules']}>
      {remarks.map(({ content, type, penalty }) => (
        <li
          key={content}
          title={DYNAMIC_LABELS.TEMPLATE_VALIDATE_PENALTY(penalty)}
          className={clsx(styles['ac-template-match-rules__remark'])}>
          <AcIcon icon={getRemarkIcon(type)} />
          {content}
        </li>
      ))}
    </ul>
  )
}
