import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import { AcButton, AcTemplateTodoFinished, AcTypography } from '@components'
import { DYNAMIC_LABELS, LABELS } from '@constants'
import { AcIntlDDMMYYYY, AcIntlWeekday } from '@helpers/ac-intl-datetime.helper'
import { useStore } from '@hooks/use-store'
import { TemplateTodoMenuEdit } from '@partials/modals/index.modals'

import styles from './ac-template-todo-menu.module.scss'

export const AcTemplateTodoMenu = observer(() => {
  const { ui, template } = useStore()

  const renderContent = useMemo(() => {
    if (!template.current_todo_menu?.data?.updated_at)
      return DYNAMIC_LABELS.TEMPLATE_TODO_MENU_EMPTY(template.current_week)

    const updatedDate = new Date(template.current_todo_menu.data.updated_at)
    const updatedDateString = `
      ${AcIntlWeekday(updatedDate)} 
      ${AcIntlDDMMYYYY(updatedDate)}`

    return DYNAMIC_LABELS.TEMPLATE_TODO_MENU_FILLED(updatedDateString)
  }, [template.current_todo_menu, template.current_week])

  const renderMenuButtonText = useMemo(
    () =>
      template.current_todo_menu?.state === 'DONE'
        ? LABELS.CHANGE_MENU
        : LABELS.MAKE_MENU,
    [template.current_todo_menu]
  )

  const handleMenuButtonClick = async () =>
    await ui
      .showModal(TemplateTodoMenuEdit, { width: 'lg' })
      .catch(() => void 0)

  const handleFinishButtonClick = () => template.completeTodo('MENU')

  return (
    <div>
      <AcTypography
        size="xs"
        color="neutral-700">
        {renderContent}
      </AcTypography>
      {template.user_can_edit && (
        <div className={styles['ac-template-todo-menu-button-wrapper']}>
          <AcButton
            label={renderMenuButtonText}
            variant="text"
            color="neutral-700"
            onClick={handleMenuButtonClick}
            withToolTip={false}
            padding="none"
          />
          {template.current_todo_menu?.state === 'DONE' ? (
            <AcTemplateTodoFinished />
          ) : (
            <AcButton
              label={LABELS.FINISH_TASK}
              variant="text"
              color="primary"
              onClick={handleFinishButtonClick}
              withToolTip={false}
              padding="none"
            />
          )}
        </div>
      )}
    </div>
  )
})
