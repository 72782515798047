import clsx from 'clsx'

import { AcIcon, AcTypography } from '@components'
import { ICONS } from '@constants'

import styles from './ac-template-profile-list-link.module.scss'

type IAcTemplateProfileListLink = {
  label: string
  href?: string
  disabled?: boolean
}

export const AcTemplateProfileListLink = ({
  label,
  href,
  disabled,
}: IAcTemplateProfileListLink) => {
  return (
    <a
      className={clsx(
        styles['ac-template-profile-list-link'],
        disabled && styles['ac-template-profile-list-link--disabled']
      )}
      href={href}
      target="_blank"
      rel="noreferrer">
      <AcTypography
        weight="semibold"
        size="sm">
        {label}
      </AcTypography>
      <AcIcon
        size="lg"
        icon={ICONS.EXTERNAL}
      />
    </a>
  )
}
