import { ICON_ELEMENTS } from '@constants'

import styles from './ac-icon.module.scss'

interface IAcIconProps {
  icon: keyof typeof ICON_ELEMENTS
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  className?: string
}

export const AcIcon = ({ icon, size = 'md', className }: IAcIconProps) => {
  const Icon = ICON_ELEMENTS[icon]

  if (!Icon) return null
  return (
    <Icon
      className={`${styles['ac-icon']} ${styles[`ac-icon--${size}`]} ${
        className ? className : ''
      }`}
    />
  )
}
