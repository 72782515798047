import axios, { AxiosInstance } from 'axios'
import ApiConfig from '@config/api.config'
import { KEYS } from '@constants'

declare module 'axios' {
  export interface AxiosInstance {
    request<T = unknown>(config: AxiosRequestConfig): Promise<T>
    get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>
    delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>
    head<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>
    post<T = unknown>(
      url: string,
      data?: unknown,
      config?: AxiosRequestConfig
    ): Promise<T>
    put<T = unknown>(
      url: string,
      data?: unknown,
      config?: AxiosRequestConfig
    ): Promise<T>
    patch<T = unknown>(
      url: string,
      data?: unknown,
      config?: AxiosRequestConfig
    ): Promise<T>
  }
}

export class AxiosBaseApi {
  api: AxiosInstance
  download: AxiosInstance
  upload: AxiosInstance

  addInterceptor = (instance: AxiosInstance): void => {
    instance.interceptors.response.use(
      response => {
        const { authorization } = response.headers

        if (authorization) {
          const newToken = new CustomEvent(KEYS.NEW_TOKEN, {
            detail: { authorization },
          })
          window.dispatchEvent(newToken)
        }
        return response
      },
      error => {
        const originalRequest = error.config
        const { status } = error.response
        console.error('response error: ', originalRequest, status)

        if (status === 401) {
          const unauthEvent = new CustomEvent(KEYS.UNAUTH)
          window.dispatchEvent(unauthEvent)
        }

        // Example for handling internal error
        if (status === 500) {
          const internalError = new Event('internalError')
          window.dispatchEvent(internalError)
        }

        // Handle default error
        return Promise.reject(error)
      }
    )
  }

  constructor() {
    const _api = axios.create(ApiConfig.api)
    this.addInterceptor(_api)
    this.api = _api
    this.download = axios.create(ApiConfig.download)
    this.upload = axios.create(ApiConfig.upload)
  }
}
