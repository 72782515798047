import { AcCard, AcIcon, AcTypography } from '@components'
import {
  DYNAMIC_LABELS,
  ICONS,
  LABELS,
  TEMPLATE_TAG_PREFERENCE_COLORS,
} from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import styles from './ac-template-bibi-info.module.scss'

export const AcTemplateBibiInfo = observer(() => {
  const { template } = useStore()

  const [negativeProducts, limitedProducts, unavailableProducts] =
    useMemo(() => {
      const negative = []
      const limited = []
      const unavailable = []

      for (const product of template.current_products) {
        if (product.state === 'NEGATIVE') negative.push(product)
        if (product.state === 'LIMITED') limited.push(product)
        if (product.state === 'UNAVAILABLE') unavailable.push(product)
      }

      return [negative, limited, unavailable]
    }, [template.current_products])

  if (
    !negativeProducts.length &&
    !limitedProducts.length &&
    !unavailableProducts.length
  )
    return null

  return (
    <AcCard
      padding={14}
      className={styles['ac-template-bibi-info']}
      elevation="none"
      theme="info">
      <div className={styles['ac-template-bibi-info-title-wrapper']}>
        <AcIcon
          icon={ICONS.BIBI_AVATAR}
          size="xl"
        />
        <AcTypography
          color="neutral-900"
          element="span"
          weight="semibold"
          size="sm">
          {LABELS.BIBI_ATTENTION}
        </AcTypography>
      </div>
      {!!negativeProducts.length && (
        <div className={styles['ac-template-bibi-info-line']}>
          <span
            className={styles['ac-template-bibi-info-line-dot']}
            style={{
              backgroundColor: TEMPLATE_TAG_PREFERENCE_COLORS[0],
            }}></span>
          <AcTypography
            size="xs"
            color="neutral-900"
            className={styles['ac-template-bibi-info-line-text']}>
            {DYNAMIC_LABELS.BIBI_NEGATIVE_PRODUCTS(negativeProducts.length)}
          </AcTypography>
        </div>
      )}
      {!!limitedProducts.length && (
        <div className={styles['ac-template-bibi-info-line']}>
          <span
            className={styles['ac-template-bibi-info-line-dot']}
            style={{
              backgroundColor: TEMPLATE_TAG_PREFERENCE_COLORS[1],
            }}></span>
          <AcTypography
            size="xs"
            color="neutral-900"
            className={styles['ac-template-bibi-info-line-text']}>
            {DYNAMIC_LABELS.BIBI_LIMITED_PRODUCTS(limitedProducts.length)}
          </AcTypography>
        </div>
      )}
      {!!unavailableProducts.length && (
        <div className={styles['ac-template-bibi-info-line']}>
          <span
            className={styles['ac-template-bibi-info-line-dot']}
            style={{
              backgroundColor: TEMPLATE_TAG_PREFERENCE_COLORS[3],
            }}></span>
          <AcTypography
            size="xs"
            color="neutral-900"
            className={styles['ac-template-bibi-info-line-text']}>
            {DYNAMIC_LABELS.BIBI_UNAVAILABLE_PRODUCTS(
              unavailableProducts.length
            )}
          </AcTypography>
        </div>
      )}
    </AcCard>
  )
})
