import { action, computed, makeAutoObservable } from 'mobx'

import {
  DYNAMIC_LABELS,
  ICON_ELEMENTS,
  KEYS,
  LABELS,
  STORE_KEYS,
} from '@constants'
import { Store } from '@stores'
import { ISingleTag } from '@typings'
import { toast } from 'react-toastify'
import { DataGridStore } from './datagrid.stores'

export class TagsStore {
  _store: Store
  all: Nullable<ISingleTag[]>
  loading: boolean
  single: Nullable<ISingleTag>
  data_grid: DataGridStore

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.all = null
    this.single = null
    this.loading = false
    this.data_grid = new DataGridStore(this._store, STORE_KEYS.TAGS)
  }

  @computed
  get current_diet_tags(): ISingleTag[] | null {
    return (
      this.all &&
      this.all.filter(({ category }) => category === 'Dieetvoorkeuren')
    )
  }

  @computed
  get current_allergy_tags(): ISingleTag[] | null {
    return (
      this.all && this.all.filter(({ category }) => category === 'Allergieën')
    )
  }
  @computed
  get current_facility_tags(): ISingleTag[] | null {
    return (
      this.all && this.all.filter(({ category }) => category === 'Faciliteiten')
    )
  }

  getList = action(async () => {
    this._store.set(STORE_KEYS.TAGS, KEYS.LOADING, true)
    try {
      const res = await this._store.api.tags.get_list()
      this._store.set(STORE_KEYS.TAGS, KEYS.ALL, res)
    } catch (e) {
      console.error(e)
    } finally {
      this._store.set(STORE_KEYS.TAGS, KEYS.LOADING, false)
    }
  })

  getById = action(async (id: number) => {
    this._store.set(STORE_KEYS.TAGS, KEYS.LOADING, true)

    try {
      const res = await this._store.api.tags.get_single(id)
      this._store.set(STORE_KEYS.TAGS, KEYS.SINGLE, res)
    } catch (e) {
      console.error(e)
    } finally {
      this._store.set(STORE_KEYS.TAGS, KEYS.LOADING, false)
    }
  })

  @action
  clearSingle = () => {
    this.single = null
  }

  @action
  updateTag = async (
    name: ISingleTag['name'],
    category: ISingleTag['category']
  ) => {
    this._store.set(STORE_KEYS.TAGS, KEYS.LOADING, true)
    try {
      const id = this.single?.id
      if (!id) return
      await this._store.api.tags.update(id, { name, category })
      await this.data_grid.getData()
      this._store.ui.closeModalGracefully()
      return Promise.resolve()
    } catch (e) {
      console.log('error: ', e)
      return Promise.reject(e)
    } finally {
      this._store.set(STORE_KEYS.TAGS, KEYS.LOADING, false)
    }
  }

  @action
  createTag = async (
    name: ISingleTag['name'],
    category: ISingleTag['category']
  ) => {
    this._store.set(STORE_KEYS.TAGS, KEYS.LOADING, true)
    try {
      await this._store.api.tags.create({ name, category })
      await this.data_grid.getData()
      this._store.ui.closeModalGracefully()
      return Promise.resolve(name)
    } catch (e) {
      console.log('error: ', e)
      return Promise.reject(e)
    } finally {
      this._store.set(STORE_KEYS.TAGS, KEYS.LOADING, false)
    }
  }
}
