import {
  AcButton,
  AcDialog,
  AcLoader,
  AcProgressTrapezoid,
  AcTemplateMatchRules,
  AcTitle,
  AcTypography,
} from '@components'
import { DYNAMIC_LABELS, LABELS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'

import styles from './template-confirm-match.module.scss'

export const TemplateConfirmMatch = observer(() => {
  const { template, ui } = useStore()

  const handleConfirmClick = async () => {
    ui.modal.reject?.()
    const order = await template.handleTemplateFinish()

    if (order?.id) {
      window.location.href = `${ROUTE_PATHS.WEEK_ORDER_DETAIL.replace(
        ':id',
        order.id.toString()
      )}`
    }
  }

  const handleCancelClick = () => {
    ui.modal.reject?.()
  }

  if (!Object.keys(template.current_validate).length)
    return (
      <AcDialog>
        <AcLoader />
      </AcDialog>
    )

  return (
    <AcDialog>
      <div className={styles['template-confirm-match__wrapper']}>
        <AcProgressTrapezoid value={template.current_validate.score} />
        <div>
          <AcTitle level={1}>{template.current_validate.label}</AcTitle>
          <AcTypography
            weight="light"
            size="sm"
            element="span">
            {template.current_validate.description}
          </AcTypography>
        </div>
        <AcTitle level={2}>
          {DYNAMIC_LABELS.TEMPLATE_FINISH_WEEK_N_CUSTOMER(
            template.current_info?.week?.week,
            template.current_info?.customer?.name
          )}
        </AcTitle>
        <AcTypography
          size="xs"
          weight="light"
          element="span">
          {LABELS.TEMPLATE_VALIDATE_ALMOST_THERE}
        </AcTypography>
        <AcTemplateMatchRules remarks={template.current_validate.remarks} />
      </div>
      <div className={styles['template-confirm-match__actions']}>
        <AcButton
          fullWidth
          color="secondary"
          label={LABELS.TEMPLATE_VALIDATE_GO_BACK}
          onClick={handleCancelClick}
          withToolTip={false}
        />
        <AcButton
          fullWidth
          color="primary"
          label={LABELS.TEMPLATE_VALIDATE_CONFIRM}
          onClick={handleConfirmClick}
          withToolTip={false}
        />
      </div>
    </AcDialog>
  )
})
