import {
  ITemplateTodo,
  ITemplateTodoDataType,
  ITemplateTodoState,
  ITemplateTodoType,
} from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'

import {
  AcAccordion,
  AcAccordionItem,
  AcAccordionItemIcon,
  AcAlert,
  AcTemplateTodoComment,
  AcTemplateTodoMenu,
  AcTemplateTodoOccupancy,
  AcTemplateTodoTasks,
  AcTemplateTodoExtras
} from '@components'
import { LABELS, TEMPLATE_TODO_TYPES } from '@constants'
import { useStore } from '@hooks/use-store'
import { AcProgressCircular } from 'src/components/core/ac-progress-circular/ac-progress-circular'

import styles from './template-todo.module.scss'

export const TemplateSidebarTodoTab = observer(() => {
  const { template } = useStore()

  const todoComponents = {
    [TEMPLATE_TODO_TYPES.OCCUPANCY]: <AcTemplateTodoOccupancy />,
    [TEMPLATE_TODO_TYPES.MENU]: <AcTemplateTodoMenu />,
    [TEMPLATE_TODO_TYPES.COMMENT]: <AcTemplateTodoComment />,
    [TEMPLATE_TODO_TYPES.TASKS]: <AcTemplateTodoTasks />,
    [TEMPLATE_TODO_TYPES.EXTRAS]: <AcTemplateTodoExtras />
  }

  const getTasksIcon = useCallback(
    (
      type: ITemplateTodoType,
      state: ITemplateTodoState,
      progress: ITemplateTodo<ITemplateTodoDataType>['progress']
    ) => {
      if (type === TEMPLATE_TODO_TYPES.TASKS) {
        return (
          <div className={styles['template-sidebar-todo-tab-icon-wrapper']}>
            <span
              style={{ opacity: progress > 0 && progress < 100 ? 1 : 0 }}
              className={styles['template-sidebar-todo-tab-icon']}>
              <AcProgressCircular
                value={progress}
                size="24px"
              />
            </span>
            <span
              style={{ opacity: progress > 0 && progress < 100 ? 0 : 1 }}
              className={styles['template-sidebar-todo-tab-icon']}>
              <AcAccordionItemIcon state={state} />
            </span>
          </div>
        )
      } else {
        return <AcAccordionItemIcon state={state} />
      }
    },
    [template.current_todos]
  )

  return (
    <div className={styles['template-sidebar-todo-tab']}>
      {template.show_profile_updated && (
        <AcAlert
          title={LABELS.ADJUSTMENTS_CUSTOMER_PROFILE}
          severity="info"
          visible
          size="sm"
          className={styles['template-sidebar-todo-tab-alert']}
        />
      )}
      <AcAccordion>
        {template.current_todos.map(singleTodo => {
          return (
            <AcAccordionItem
              key={singleTodo.type}
              title={singleTodo.title}
              iconElement={getTasksIcon(
                singleTodo.type,
                singleTodo.state,
                singleTodo.progress
              )}
              showIcon>
              {todoComponents[singleTodo.type]}
            </AcAccordionItem>
          )
        })}
      </AcAccordion>
    </div>
  )
})
