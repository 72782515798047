import { ISupplier, ISupplierCompact, ISupplierDeliveryDay } from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, LABELS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { DataGridStore } from './datagrid.stores'
import { AcFormDataManipulator } from '@helpers/ac-form-data-manipulator.helper'

export class SuppliersStore {
  _store: Store

  loading: boolean
  supplier: ISupplier
  suppliers: ISupplierCompact[]
  data_grid: DataGridStore

  form?: UseFormReturn<ISupplier>

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.loading = true
    this.data_grid = new DataGridStore(this._store, STORE_KEYS.SUPPLIERS)
    this.loading = false
    this.supplier = {} as ISupplier
    this.suppliers = []
  }

  @computed
  get current_archived(): boolean {
    return !this.supplier.active
  }

  @computed
  get current_active(): boolean {
    return !!this.supplier.active
  }

  getList = action(async () => {
    this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, true)

    try {
      const res = await this._store.api.suppliers.get_list()
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.SUPPLIERS, res)
    } catch (e) {
      console.error(e)
    } finally {
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, false)
    }
  })

  getById = action(async (id: number) => {
    this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, true)

    try {
      const res = await this._store.api.suppliers.get_single(id)
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.SUPPLIER, res)
      return res
    } catch (e) {
      console.error(e)
    } finally {
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, false)
    }
  })

  @action
  private manipulateSupplier = (values: ISupplier) => {
    const { data } = new AcFormDataManipulator(values)
      .mapObjectsToValue('markets')
      .deleteFalsyKeyFromArrayObject('contacts', ['id'])
    return data
  }

  @action
  updateSupplier = async (values: ISupplier) => {
    this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, true)

    values = this.manipulateSupplier(values)

    try {
      const res = await this._store.api.suppliers.update_supplier(
        this.supplier.id,
        values
      )
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.SUPPLIER, res.data)
      return Promise.resolve(res.data)
    } catch (e: any) {
      return Promise.reject(e.response.data.errors)
    } finally {
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, false)
    }
  }

  @action
  createSupplier = async (values: ISupplier) => {
    this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, true)

    values = this.manipulateSupplier(values)

    try {
      const res = await this._store.api.suppliers.create_supplier(values)
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.SUPPLIER, res.data)
      return Promise.resolve(res.data)
    } catch (e: any) {
      return Promise.reject(e.response.data.errors)
    } finally {
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, false)
    }
  }

  @action
  handleArchiveSupplier = async () => {
    const modal = {
      title: this.current_archived
        ? LABELS.CONFIRM_SUPPLIER_ACTIVATE_TITLE
        : LABELS.CONFIRM_SUPPLIER_ARCHIVE_TITLE,
      content: this.current_archived
        ? LABELS.CONFIRM_SUPPLIER_ACTIVATE_TEXT
        : LABELS.CONFIRM_SUPPLIER_ARCHIVE_TEXT,
      confirmLabel: this.current_archived
        ? LABELS.ACTIVE_ACTION
        : LABELS.ARCHIVE_ACTION,
    }
    try {
      await this._store.ui.confirm({ ...modal })
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, true)
      await this._store.api.suppliers.archive_supplier(this.supplier.id)
      this.getById(this.supplier.id)
      this._store.set(STORE_KEYS.SUPPLIERS, KEYS.LOADING, false)
    } catch (e) {
      console.error(e)
    }
  }

  @action
  clearSupplier = () => {
    this._store.set(STORE_KEYS.SUPPLIERS, KEYS.SUPPLIER, {} as ISupplier)
  }

  @action
  setForm = (form: FieldValues) => {
    this._store.set(
      STORE_KEYS.SUPPLIERS,
      KEYS.FORM,
      form as unknown as UseFormReturn<ISupplier>
    )
  }

  /**
   * Will remove existing `delivery_days` that have `market_id`.
   * Array is extended with the passed new `delivery_days`
   * Finally update the form field
   *
   */
  @action
  updateDeliveryDays = (marketId: ID, deliveryDays: ISupplierDeliveryDay[]) => {
    const currentDeliveryDays = this.form?.getValues('delivery_days') || []
    const currentMarketDeliveryDaysRemoved = currentDeliveryDays?.filter(
      ({ market_id }) => market_id !== marketId
    )
    this.form?.setValue('delivery_days', [
      ...currentMarketDeliveryDaysRemoved,
      ...deliveryDays,
    ])
  }

  @action
  updateMarkets = (selectedMarkets: any[]) => {
    const markets = selectedMarkets
      .filter(({ checked }) => !!checked)
      .map(({ value, label }) => ({ id: value, name: label, abbreviation: '' }))
    this.form?.setValue('markets', markets)
  }
}
