import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import {
  IProduct,
  IProductGroup,
  IProductCategory,
  IProductsListResponse,
  IProductSitesResponse,
} from '@typings'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export class ProductsApi extends AxiosBaseApi {
  /* DEFAULT CRUD */

  get_list = () =>
    this.api
      .get<AxiosResponse<IProductsListResponse>>(ENDPOINTS.PRODUCTS.LIST())
      .then(response => response.data.data)

  get_single = (id: number) =>
    this.api
      .get<AxiosResponse<{ data: IProduct }>>(ENDPOINTS.PRODUCTS.SINGLE(id))
      .then(response => response.data.data)

  /* ACTIONS */

  archive_product = (id: number) =>
    this.api.put<AxiosResponse<void>>(ENDPOINTS.PRODUCTS.ARCHIVE(id))

  create_product = (values: IProduct) => {
    return this.upload
      .post<AxiosResponse<{ data: IProduct }>>(ENDPOINTS.PRODUCTS.LIST(), {
        ...values,
      })
      .then(response => response.data)
  }

  update_product = (id: number, values: IProduct) => {
    // BE need an added _method key to know it is update
    values['_method'] = 'PUT'
    return this.upload
      .post<AxiosResponse<{ data: IProduct }>>(ENDPOINTS.PRODUCTS.SINGLE(id), {
        ...values,
      })
      .then(response => response.data)
  }

  /* JOINS */

  get_group_list = (params?: AxiosRequestConfig['params']) =>
    this.api
      .get<AxiosResponse<{ data: IProductGroup[] }>>(
        ENDPOINTS.PRODUCTS.GROUP_LIST(),
        { params }
      )
      .then(response => response.data.data)
  get_category_list = (params?: AxiosRequestConfig['params']) =>
    this.api
      .get<AxiosResponse<{ data: IProductCategory[] }>>(
        ENDPOINTS.PRODUCTS.CATEGORY_LIST(),
        { params }
      )
      .then(response => response.data.data)
  get_selectable_category_list = (params?: AxiosRequestConfig['params']) =>
    this.api
      .get<AxiosResponse<{ data: IProductCategory[] }>>(
        ENDPOINTS.PRODUCTS.SELECTABLE_CATEGORY_LIST(),
        { params }
      )
      .then(response => response.data.data)
  get_site_list = (params?: AxiosRequestConfig['params']) =>
    this.api
      .get<AxiosResponse<IProductSitesResponse>>(
        ENDPOINTS.PRODUCTS.SITE_LIST(),
        { params }
      )
      .then(response => response.data)
}
