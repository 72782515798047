import clsx from 'clsx'
import { useMemo } from 'react'

import { AcTypography } from '@components'
import { ITemplateProduct } from '@typings'

import styles from './ac-template-product-title.module.scss'

export const AcTemplateProductTitle = ({
  state,
  name,
}: Partial<ITemplateProduct>) => {
  const getTitleClasses = useMemo(
    () =>
      clsx(
        styles['ac-template-product-title'],
        state && styles[`ac-template-product-title--${state}`]
      ),
    [state]
  )

  return (
    <AcTypography
      size="xs"
      className={getTitleClasses}>
      {name}
    </AcTypography>
  )
}
