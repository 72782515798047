import { createContext, useState } from 'react'

import styles from './ac-accordion.module.scss'

export const AcAccordionContext = createContext({
  activeItemId: '',
  setActiveItemId: (() => void 0) as React.Dispatch<
    React.SetStateAction<string>
  >,
})

interface IAcAccordion {
  children: React.ReactNode
}

export const AcAccordion = ({ children }: IAcAccordion) => {
  const [activeItemId, setActiveItemId] = useState<string>('')

  return (
    <div className={styles['ac-accordion']}>
      <AcAccordionContext.Provider value={{ activeItemId, setActiveItemId }}>
        {children}
      </AcAccordionContext.Provider>
    </div>
  )
}
