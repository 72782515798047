import { AcCheckbox, AcFormContext, AcTypography } from '@components'
import { TAG_PREFERENCES } from '@constants'
import { ICustomerProfileTag, ISingleTag } from '@typings'
import { useContext, useEffect, useMemo, useState } from 'react'
import { AcSelect } from '@components'
import styles from './ac-profile-tab-tags-row.module.scss'
import clsx from 'clsx'

export interface IAcProfileTabTagsRow {
  item: ISingleTag
  onChange: (item: ICustomerProfileTag) => void
  selected: boolean
  value: number | null
}

export const AcProfileTabTagsRow = ({
  item,
  onChange,
  selected,
  value,
}: IAcProfileTabTagsRow) => {
  const [checked, setChecked] = useState(selected)
  const { readOnly } = useContext(AcFormContext)

  const getWrapperClasses = useMemo(
    () =>
      clsx(
        styles['ac-profile-tab-tags-row'],
        readOnly && styles['ac-profile-tab-tags-row--read-only']
      ),
    [readOnly]
  )

  const [selectedPreference, setSelectedPreference] = useState(value)

  const handleChecked = () => {
    setChecked(!checked)
    onChange(handleFormatData(item.id, selectedPreference as number))
  }

  const handleFormatData = (id: number, preference: number) => {
    return {
      id,
      preference,
    }
  }

  const handlePreference = (newValue: number) => {
    setSelectedPreference(newValue)
    onChange(handleFormatData(item.id, newValue))
  }

  useEffect(() => {
    setChecked(selected)
  }, [selected])

  if (readOnly && !checked) return null

  return (
    <span className={getWrapperClasses}>
      {!readOnly && (
        <>
          <AcCheckbox
            withLabel
            checked={checked}
            label={item.name}
            onClick={handleChecked}
          />
          {checked && (
            <AcSelect
              defaultValue={selectedPreference}
              onChange={handlePreference}
              id={item.id.toString()}
              name={item.name}
              options={TAG_PREFERENCES}
              labelKey="label"
              valueKey="value"
              inputFormat="value"
              outputFormat="value"
            />
          )}
        </>
      )}
      {readOnly && checked && (
        <div className={styles['ac-profile-tab-tags-row-checked-value']}>
          <AcTypography
            size="sm"
            element="span"
            color="neutral-700">
            {item.name}
          </AcTypography>
          <AcTypography
            size="sm"
            element="span">
            {TAG_PREFERENCES.find(item => item.value == value)?.label}
          </AcTypography>
        </div>
      )}
    </span>
  )
}
