import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ISupplier, ISupplierCompact } from '@typings'

export class SuppliersApi extends AxiosBaseApi {
  /* DEFAULT CRUD */

  get_list = (params?: AxiosRequestConfig['params']) =>
    this.api
      .get<AxiosResponse<{ data: ISupplierCompact[] }>>(
        ENDPOINTS.SUPPLIERS.LIST(),
        {
          params,
        }
      )
      .then(response => response.data.data)

  get_single = (id: number) =>
    this.api
      .get<AxiosResponse<{ data: ISupplier }>>(ENDPOINTS.SUPPLIERS.SINGLE(id))
      .then(response => response.data.data)

  /* ACTIONS */

  update_supplier = (id: number, values: ISupplier) => {
    // BE need an added _method key to know it is update
    values['_method'] = 'PUT'
    return this.api
      .post<AxiosResponse<{ data: ISupplier }>>(
        ENDPOINTS.SUPPLIERS.SINGLE(id),
        {
          ...values,
        }
      )
      .then(response => response.data)
  }

  create_supplier = (values: ISupplier) =>
    this.api
      .post<AxiosResponse<{ data: ISupplier }>>(ENDPOINTS.SUPPLIERS.LIST(), {
        ...values,
      })
      .then(response => response.data)

  archive_supplier = (id: number) =>
    this.api.put<AxiosResponse<void>>(ENDPOINTS.SUPPLIERS.ARCHIVE(id))

  /* JOINS */
}
