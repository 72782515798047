import clsx from 'clsx'
import { useMemo } from 'react'
import styles from './ac-typography.module.scss'

interface IAcTypography {
  size?: 'md' | 'sm' | 'xs'
  weight?: 'semibold' | 'regular' | 'light'
  className?: string
  element?: 'p' | 'span' | 'legend'
  color?: 'neutral-500' | 'neutral-700' | 'neutral-900'
}
interface IAcTypographyHTMLFromChildren extends IAcTypography {
  html?: never
  children: React.ReactNode
}
interface IAcTypographyHTMLFromString extends IAcTypography {
  children?: never
  html: string
}

export const AcTypography = ({
  children,
  html,
  size = 'md',
  weight = 'regular',
  className,
  element: Element = 'p',
  color,
}: IAcTypographyHTMLFromString | IAcTypographyHTMLFromChildren) => {
  const getTypographyClassNames = useMemo(
    () =>
      clsx(
        styles['ac-typography'],
        styles[`ac-typography--${Element}`],
        styles[`ac-typography--${size}`],
        styles[`ac-typography--${weight}`],
        color && styles[`ac-typography--color-${color}`],
        className && className
      ),
    [Element, size, weight, color, className]
  )

  return (
    <Element
      className={getTypographyClassNames}
      dangerouslySetInnerHTML={html ? { __html: html } : void 0}>
      {children}
    </Element>
  )
}
