import { AcLoader } from '@components'
import { KEYS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'

export const AcAuth = observer(() => {
  const [params] = useSearchParams()
  const token = params.get(KEYS.TOKEN)
  const intended = params.get(KEYS.INTENDED)
  const { auth } = useStore()

  const handleToken = async (token: Nullable<string>) => {
    if (token) {
      await auth.handleTempToken(token)
    }
  }

  const isRelativeUrl = (url: string) => {
    return !!url && !url?.startsWith('http')
  }

  useEffect(() => {
    handleToken(token)
  }, [])

  if (auth.loading) return <AcLoader />

  return (
    <>
      {intended ? (
        isRelativeUrl(intended) ? (
          <Navigate to={intended} />
        ) : (
          (window.location.href = intended)
        )
      ) : (
        <Navigate to={ROUTE_PATHS.HOME} />
      )}
    </>
  )
})
