import clsx from 'clsx'
import { RefObject } from 'preact'
import {
  Children,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { AcAssert } from '@helpers/ac-assert.helpers'
import { AcTabLabel } from './ac-tab-label'
import { AcTabPanel } from './ac-tab-panel'

import styles from './ac-tabs.module.scss'

interface IAcSingleTab {
  id: string
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props?: any) => Nullable<JSX.Element>
  disabled: boolean
  badge?: boolean
}

interface IAcTabs {
  tabs: IAcSingleTab[]
  children?: React.ReactNode
  errors?: string[] | Set<string>
  className?: string
  borderless?: boolean
  onTabChange?: (activeTab: string) => void
}

interface IAcTabsContext {
  activeTab: string
  errorTabs: string[]
}

type ITabNavLabelRefs = Map<string, RefObject<HTMLButtonElement>>
type IAcTab = <TabProps>(props: TabProps & { name: string }) => null

export const AcTabsContext = createContext<IAcTabsContext>({
  activeTab: '',
  errorTabs: [],
})

export const AcTab: IAcTab = () => null

export const AcTabs = ({
  tabs,
  children,
  errors,
  className,
  borderless,
  onTabChange,
}: IAcTabs) => {
  const [activeTab, setActiveTab] = useState(tabs?.[0]?.id || '')

  const tabNavLabelRefs = useRef<ITabNavLabelRefs>(new Map())
  const tabNavSliderRef = useRef<HTMLHRElement>(null)
  const resizeObserverRef = useRef<ResizeObserver>()

  if (errors) {
    const PropertyAssert = new AcAssert('AcTabs')
    PropertyAssert.isIterable({ TABS_TABS_NOT_ITERABLE: errors })
    if (PropertyAssert.error) return null
  }

  const errorTabs = useMemo(() => [...(errors || [])], [errors])

  const handleTabChange = (id: IAcSingleTab['id']) => {
    setActiveTab(id)
    onTabChange && onTabChange(id)
  }

  const renderTabLabels = useMemo(() => {
    const tabLabels = []

    for (const { label, id, badge } of tabs) {
      const ref = useRef<HTMLButtonElement>(null)
      tabNavLabelRefs.current.set(id, ref)
      tabLabels.push(
        <AcTabLabel
          ref={ref}
          id={id}
          badge={badge}
          onClick={() => handleTabChange(id)}>
          {label}
        </AcTabLabel>
      )
    }

    return tabLabels
  }, [errors, activeTab, tabs])

  const renderTabs = useMemo(() => {
    const tabCollection = []
    const childrenProps = new Map()

    // Combine children props (<AcTab />) with Tab element
    Children.forEach(children as React.ReactElement, child =>
      childrenProps.set(child.props.name, child.props)
    )

    for (const { id, label, component: Tab } of tabs) {
      const childProps = childrenProps.get(id) || {}

      tabCollection.push(
        <AcTabPanel
          id={id}
          label={label}>
          <Tab {...childProps} />
        </AcTabPanel>
      )
    }

    return tabCollection
  }, [activeTab, tabs])

  const getTabNavSliderClassNames = useMemo(
    () =>
      clsx([
        styles['ac-tab-nav__slider'],
        errorTabs.includes(activeTab) && styles['ac-tab-nav__slider--error'],
      ]),
    [errorTabs, activeTab]
  )

  // Create ResizeObserver that moves and resizes tabNavSlider
  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver(
      (elements: ResizeObserverEntry[]) => {
        for (const element of elements) {
          if (tabNavSliderRef.current) {
            const tabNavPositionX = (
              tabNavSliderRef.current.parentElement as HTMLElement
            ).getBoundingClientRect().left
            const targetRect = (
              element.target as HTMLElement
            ).getBoundingClientRect()

            tabNavSliderRef.current.style.width = targetRect.width + 'px'
            tabNavSliderRef.current.style.transform = `translateX(${
              targetRect.left - tabNavPositionX
            }px)`
          }
        }
      }
    )
    onTabChange && onTabChange(activeTab)
  }, [])

  // Assign activeTab el to ResizeObserver on activeTab change
  useEffect(() => {
    const activeTabElement = tabNavLabelRefs.current.get(activeTab)?.current
    if (resizeObserverRef.current && activeTabElement) {
      resizeObserverRef.current.observe(activeTabElement)
      return () => resizeObserverRef?.current?.unobserve(activeTabElement)
    }
  }, [activeTab])

  return (
    <div className={clsx(styles['ac-tabs'], className)}>
      <AcTabsContext.Provider value={{ activeTab, errorTabs }}>
        <div
          className={clsx(
            styles['ac-tab-nav'],
            borderless && styles['ac-tab-nav--borderless']
          )}
          role="tablist">
          <hr
            ref={tabNavSliderRef}
            className={getTabNavSliderClassNames}
          />
          {renderTabLabels}
        </div>
        {renderTabs}
      </AcTabsContext.Provider>
    </div>
  )
}
