import { useEffect } from 'react'
import { centerCrop, makeAspectCrop } from 'react-image-crop'
import type { PixelCrop } from 'react-image-crop'

const TO_RADIANS = Math.PI / 180
let PREVIEW_URL = ''

const canvasPreview = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) => {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const rotateRads = rotate * TO_RADIANS
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // 2) Scale the image
  ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  )

  ctx.restore()
}

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) =>
  centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )

const toBlob = (canvas: HTMLCanvasElement) => {
  return new Promise(resolve => {
    canvas.toBlob(resolve)
  })
}

const imgPreview = async (
  image: HTMLImageElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) => {
  const canvas = document.createElement('canvas')
  canvasPreview(image, canvas, crop, scale, rotate)

  //eslint-disable-next-line
  const blob: any = await toBlob(canvas)
  if (PREVIEW_URL) {
    URL.revokeObjectURL(PREVIEW_URL)
  }

  PREVIEW_URL = URL.createObjectURL(blob)
  return PREVIEW_URL
}

//eslint-disable-next-line
const useDebounceEffect = (fn: any, waitTime: number, deps: any) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn(...deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps)
}

export {
  canvasPreview,
  useDebounceEffect,
  centerAspectCrop,
  imgPreview,
  toBlob,
}
