import { AcIcon } from '@components'
import { ICONS, TEMPLATE_TODO_STATES } from '@constants'
import { ITemplateTodoState } from '@typings'
import styles from './ac-accordion-item-icon.module.scss'
import clsx from 'clsx'
import { useMemo } from 'react'

export const AcAccordionItemIcon = ({
  state,
}: {
  state: ITemplateTodoState
}) => {
  const getIconClasses = useMemo(
    () =>
      clsx(
        styles['ac-accordion-item-icon'],
        state && styles[`ac-accordion-item-icon--${state}`]
      ),
    [state]
  )
  return (
    <div className={getIconClasses}>
      <AcIcon
        icon={
          state === TEMPLATE_TODO_STATES.WARNING ? ICONS.WARNING : ICONS.CHECK
        }
        size="md"
        className={styles['ac-accordion-item-icon-element']}
      />
    </div>
  )
}
