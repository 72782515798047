import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'
import styles from './ac-datagrid-row.module.scss'

interface Props {
  children?: ReactNode
  className?: string
  onClick?: (e: React.MouseEvent) => void
}

export const AcDataGridRow = ({ children, onClick, className }: Props) => {
  const getRowClassNames = useMemo(
    () =>
      clsx(
        styles['ac-datagrid-row'],
        onClick && styles['ac-datagrid-row--clickable'],
        className && className
      ),
    [className]
  )

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClick && onClick(e)
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    if(e.button !== 1) return

    e.stopPropagation()
    onClick && onClick(e)
  }

  return (
    <tr
      className={getRowClassNames}
      onMouseDown={(e: React.MouseEvent) => handleMouseDown(e)}
      onClick={(e: React.MouseEvent) => handleClick(e)}>
      {children}
    </tr>
  )
}
