import { useState } from 'react'
import type { DragEvent } from 'react'
import PropTypes from 'prop-types'

// Components
import { AcChip } from '@components'

// Constants
import { IMAGE_UPLOAD_ERRORS, FORM_CONSTANTS, LABELS } from '@constants'

// Styles
import styles from './ac-image-input.module.scss'

import ImagePlaceholder from '@assets/icons/ImagePlaceholder.svg'

interface Props {
  onChange: (image: File) => void
}

const AcImageUpload: React.FC<Props> = ({ onChange }) => {
  const [dragging, setDragging] = useState(false)
  const [error, setError] = useState('')

  const renderError = (error: string) => {
    setError(error)
    setTimeout(() => {
      setError('')
    }, 3000)
  }

  const onFileUpload = (file: File) => {
    if (
      file.type !== 'image/jpg' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png'
    ) {
      return renderError(IMAGE_UPLOAD_ERRORS.WRONG_MIME_TYPE)
    }
    onChange(file)
  }

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragging(true)
    } else if (e.type === 'dragleave') {
      setDragging(false)
    }
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    if (e.dataTransfer && e.dataTransfer.files.length > 1) {
      return renderError(IMAGE_UPLOAD_ERRORS.MULTIPLE_FILES_ERROR)
    }
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileUpload(e.dataTransfer.files[0])
    }
  }

  const clickFileUpload = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const files = target.files as FileList
    if (files?.length > 1) {
      return renderError(IMAGE_UPLOAD_ERRORS.MULTIPLE_FILES_ERROR)
    }

    if (files[0]) {
      onFileUpload(files[0])
    }
  }

  return (
    <>
      <input
        type="file"
        hidden
        onChange={clickFileUpload}
        multiple={false}
        formNoValidate
        id="file-upload"
        accept="image/jpg,image/jpeg,image/png"
      />
      <label
        id="label-file-upload"
        htmlFor="file-upload">
        <div
          className={
            !dragging
              ? styles['ac-image-upload']
              : `${styles['ac-image-upload']} ${styles['ac-image-upload__dragging']}`
          }
          onDragEnter={handleDrag}>
          <div className={styles['ac-image-upload__box']}>
            {error ? (
              <AcChip
                label={error}
                icon="INFO"
              />
            ) : (
              <>
                <img src={ImagePlaceholder} alt={LABELS.PLACEHOLDER} width={52} height={52} />
                <span className={styles['ac-image-upload__text']}>
                  {FORM_CONSTANTS.DRAG_IMAGE}{' '}
                  <span className={styles['ac-image-upload__text__highlight']}>
                    {FORM_CONSTANTS.UPLOAD}
                  </span>
                </span>
                <span className={styles['ac-image-upload__helperText']}>
                  {FORM_CONSTANTS.ACCEPTED_FILES}, {FORM_CONSTANTS.ACCEPTED_FILESIZE}
                </span>
              </>
            )}
          </div>
          {dragging && (
            <div
              className={styles['drag_element']}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </div>
      </label>
    </>
  )
}

AcImageUpload.propTypes = {
  onChange: PropTypes.any,
}

export default AcImageUpload
