import { AcIcon, AcTypography } from '@components'
import { ICONS, LABELS } from '@constants'

import styles from './ac-template-todo-finished.module.scss'

interface IAcTemplateTodoFinished {
  label?: string
}

export const AcTemplateTodoFinished = ({ label }: IAcTemplateTodoFinished) => {
  return (
    <div className={styles['ac-template-todo-finished']}>
      <AcIcon
        icon={ICONS.CHECK}
        className={styles['ac-template-todo-finished-icon']}
        size="lg"
      />
      <AcTypography
        className={styles['ac-template-todo-finished-label']}
        element="span"
        size="sm"
        weight="semibold">
        {label ?? LABELS.TASK_FINISHED}
      </AcTypography>
    </div>
  )
}
