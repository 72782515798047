import { AcMoreButton } from '@components'
import { ICONS, WEEK, WEEKDAYS } from '@constants'
import { useStore } from '@hooks/use-store'
import { TemplateEditLunchBudgetModal } from '@partials/modals/index.modals'
import { ITemplateDaysName } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { IAcLayeredMenuItem } from '../ac-layered-menu/ac-layered-menu-item/ac-layered-menu-item'

export const AcTemplateHeaderMoreMenu = observer(() => {
  const { ui, template } = useStore()
  const generateAddDayChildOptions = useMemo(() => {
    const data = []
    const options: ITemplateDaysName[] = WEEK.WORKING_WEEK.filter(
      singleDay => !template.current_days_by_name.includes(singleDay)
    )

    for (const option of options) {
      data.push({
        label: WEEKDAYS[option],
        onClick: () => template.handleAddDay(option),
      })
    }

    return data
  }, [template.current_days_by_name])

  const menuItems: Set<IAcLayeredMenuItem> = new Set([
    {
      label: 'Bibi shuffle de week',
      icon: ICONS.BIBI,
      onClick: () => {
        template.handleBibiShuffle({})
      },
    },
    {
      label: 'Lunchbudget aanpassen',
      icon: ICONS.PIGGY,
      onClick: () =>
        ui.showModal(TemplateEditLunchBudgetModal).catch(() => void 0),
    },
  ])

  if (generateAddDayChildOptions.length > 0) {
    menuItems.add({
      label: 'Dag toevoegen',
      icon: ICONS.PLUS,
      onClick: () => void 0,
      children: generateAddDayChildOptions,
    })
  }
  return (
    <AcMoreButton
      menuOptions={[...menuItems]}
      buttonType="header"
    />
  )
})
