import { useCallback } from 'react'

import clsx from 'clsx'
import styles from './ac-radio-input.module.scss'

interface IAcRadioInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  option: ID | string
  onChange?: (value: any) => void
  label: string
}
export const AcRadioInput = ({
  option,
  value,
  onChange,
  label,
  ...inputProps
}: IAcRadioInputProps) => {
  const onInputChange: React.MouseEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (inputProps.readOnly) return e.preventDefault()

      onChange?.(option)
    },
    [inputProps.readOnly]
  )
  return (
    <input
      className={clsx(
        styles['ac-radio-input'],
        inputProps.readOnly && styles['ac-radio-input--read-only']
      )}
      aria-label={label}
      type="radio"
      value={option}
      checked={option === value}
      onClick={onInputChange}
      {...inputProps}
    />
  )
}
