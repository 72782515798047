import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { IAuthEmployee } from '@typings'

export class AuthApi extends AxiosBaseApi {
  postTempToken = (token: string) =>
    this.api
      .post<{ data: { data: IAuthEmployee } }>(
        ENDPOINTS.AUTH.TEMP_TOKEN(),
        {
          token,
        }
      )
      .then(response => response.data.data)
}
