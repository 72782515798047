import { forwardRef, useMemo } from 'react'

import { AcInputBase } from '../ac-input-base/ac-input-base'
import { AcTypography } from '../index.core.component'

import styles from './ac-text-input.module.scss'

export interface IAcTextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string
  id: string
  className?: string
  type?: 'text' | 'email' | 'password' | 'url' | 'number' | 'tel' | 'search'
  /** @default 14 */
  padding?: 8 | 10 | 14 | 16
  loading?: boolean
  hint?: string
  error?: boolean | string
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
}

export interface IAcTextareaInputProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string
  id: string
  className?: string
  type?: 'textarea'
  /** @default 16 */
  padding?: 8 | 10 | 14 | 16
  loading?: boolean
  hint?: string
  error?: boolean | string
}
const AcTextInput = forwardRef<
  HTMLElement,
  IAcTextInputProps | IAcTextareaInputProps
>(({ type = 'text', name, className, error, hint, loading, ...props }, ref) => {
  const renderInput = (props: IAcTextInputProps) => {
    return (
      <input
        className={styles['ac-text-input']}
        ref={ref as React.ForwardedRef<HTMLInputElement>}
        aria-describedby={props.id + '_hint'}
        {...props}
        type={type}
        name={name}
        disabled={props.disabled || loading}
      />
    )
  }

  const renderTextarea = (props: IAcTextareaInputProps) => {
    return (
      <textarea
        className={styles['ac-text-input']}
        ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
        {...props}
        name={name}
        disabled={props.disabled || loading}
      />
    )
  }

  const padding = useMemo(() => {
    if (!props.padding) return type === 'textarea' ? 16 : 14

    return props.padding
  }, [props.padding, type])

  const { startAdornment, endAdornment, ...inputProps } =
    props as IAcTextInputProps

  const value = useMemo(() => {
    // For floats, replace '.' by ','
    if (!Number.isNaN(parseInt(inputProps.value as string)))
      return ((inputProps.value as string) || '').toString().replace?.('.', ',')

    return inputProps.value
  }, [inputProps.value])

  return (
    <AcInputBase
      className={className}
      error={error}
      hint={hint}
      disabled={inputProps.disabled}
      padding={padding}
      loading={loading}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      withoutBorders={inputProps.readOnly}>
      {inputProps.readOnly ? (
        <AcTypography
          weight="semibold"
          size="sm"
          element="span">
          {value}
        </AcTypography>
      ) : type === 'textarea' ? (
        renderTextarea(props as IAcTextareaInputProps)
      ) : (
        renderInput(props as IAcTextInputProps)
      )}
    </AcInputBase>
  )
})
AcTextInput.displayName = 'AcTextInput'

export { AcTextInput }
