import { AcAppHeader, AcButton, AcDataGrid } from '@components'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { TagsCreateModal, TagsEditModal } from '@partials/modals/index.modals'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const AcProductTagsList = observer(() => {
  const { tags, ui } = useStore()
  const navigate = useNavigate()
  const { id } = useParams()

  const openCreateTagModal = async () => {
    await ui.showModal(TagsCreateModal, { closeable: true }).catch(() => void 0)
  }

  useEffect(() => {
    if (id !== undefined) {
      const _id = parseInt(id)
      tags.getById(_id)
      ui.showModal(TagsEditModal, {
        closeable: true,
        onClose: () => {
          tags.clearSingle()
          navigate(ROUTE_PATHS.PRODUCT_TAGS, { replace: true })
        },
      }).catch(() => void 0)
    }
  }, [id])

  return (
    <>
      <AcAppHeader
        showBackButton
        navigateBackTo={ROUTE_PATHS.PRODUCT_LIST}
        title={LABELS.TAGS}
        action={
          <AcButton
            onClick={openCreateTagModal}
            label={LABELS.NEW}
            icon={ICONS.PLUS}
          />
        }
      />
      <AcDataGrid
        store={tags.data_grid}
        detailPath={ROUTE_PATHS.PRODUCT_TAGS_DETAIL}
        navigateOptions={{ replace: true }}
      />
    </>
  )
})
