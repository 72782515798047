import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useParams, generatePath, useNavigate } from 'react-router-dom'
import { AcAppHeader, AcButton, AcAlert, AcFormProvider, AcIcon } from '@components'
import { ROUTE_PATHS, LABELS, GLOBAL_ERRORS, KEYS, ICONS } from '@constants'
import { UserProfileForm } from '@partials/forms/user-profile-form/user-profile-form'
import { useForm } from 'react-hook-form'
import { defaultProfileValues } from 'src/stores/users.stores'
import { ISingleUserCreate } from '@typings'
import { toast } from 'react-toastify'

export const AcUserProfileCreate = observer(() => {
  const navigate = useNavigate()
  const { users } = useStore()
  const { id } = useParams()
  const form = useForm({
    defaultValues: defaultProfileValues,
  })

  const [hasErrors, setHasErrors] = useState(false)

  const handleErrors = (errors: Record<string, string[]>) => {
    setHasErrors(true)
    const errorKeys = Object.keys(errors)

    errorKeys.map(singleKey => {
      // @ts-expect-error: formTypings
      form.setError(singleKey, {
        type: 'custom',
        message: errors[singleKey][0],
      })
    })
  }

  const onSubmit = (data: ISingleUserCreate) => {
    setHasErrors(false)
    users.createProfile(data)
      .then(newUser => {
        form.reset(newUser)
        toast(LABELS.SAVED_PROFILE, {
          toastId: KEYS.SAVED_PROFILE,
          icon: <AcIcon icon={ICONS.CHECK} />,
        })
        setTimeout(() => {
          const path = generatePath(ROUTE_PATHS.USER_PROFILE, { id: newUser.id.toString() })
          navigate(path, { replace: true })
        }, 250)
      })
      .catch(err => {
        setHasErrors(true)
        if (err.roles) {
          err.roles_contact = err.roles
          err.roles_employee = err.roles
        }
        handleErrors(err)
      })
  }

  const onError = () => setHasErrors(true)

  const onFormSubmit = () => {
    form.handleSubmit(onSubmit)()
  }

  return (
    <>
      <AcAppHeader
        showBackButton
        navigateBackTo={ROUTE_PATHS.USER_LIST}
        title={LABELS.CREATE_NEW_USER}
        action={
          <AcButton
            onClick={onFormSubmit}
            label={LABELS.SAVE}
          />
        }
      />
      <AcAlert
        title={GLOBAL_ERRORS.FORM_STATE_INVALID}
        visible={hasErrors}
      />
      <AcFormProvider
        form={form}
        key={id}
        onSubmit={onFormSubmit}
        readOnly={false}>
        <UserProfileForm onError={onError} isNewProfile />
      </AcFormProvider>
    </>
  )
})
