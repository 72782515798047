import { createContext, useContext, useEffect } from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { AcFormContext } from '../ac-form-provider/ac-form-provider'
import styles from './ac-form.module.scss'

export type IAcForm = {
  children: React.ReactNode
  layout?: 'masonry' | 'column'
  onSubmit?: SubmitHandler<Record<string, unknown>>
  onError?: () => undefined | void
}

export const AcFormErrorContext = createContext<Pick<IAcForm, 'onError'>>({
  onError: () => void 0,
})

export const AcForm = ({
  children,
  layout = 'masonry',
  onSubmit: customOnSubmit,
  onError = () => void 0,
}: IAcForm) => {
  const { handleSubmit } = useFormContext()
  const { onFormSubmit, hasErrors } = useContext(AcFormContext)

  const onSubmit: IAcForm['onSubmit'] = (data, event) => {
    onFormSubmit(data, event)
    customOnSubmit?.(data, event)
  }

  useEffect(() => {
    if (hasErrors) onError()
  }, [hasErrors])

  return (
    <div className={styles['ac-form-container']}>
      <AcFormErrorContext.Provider value={{ onError }}>
        <form
          className={styles[`ac-form--layout-${layout}`]}
          onSubmit={handleSubmit(onSubmit, onError)}>
          {children}
        </form>
      </AcFormErrorContext.Provider>
    </div>
  )
}
