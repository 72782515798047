export const PRICE = {
  PRODUCT_MARGE: 46,
  PRODUCT_BTW_OPTIONS: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 6,
      label: '6%',
    },
    {
      value: 9,
      label: '9%',
    },
    {
      value: 21,
      label: '21%',
    },
  ],
}
