import { AcForm, AcFormCard, AcFormInput } from '@components'
import { KEYS, LABELS } from '@constants'
import { IProduct } from '@typings'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'
import {
    AcIntlInputFloat,
} from '@helpers/ac-intl-number.helper'

export type IProductExtrasForm = {
    onError: () => undefined | void
}

export const ProductExtrasForm = observer(
    ({ onError }: IProductExtrasForm) => {
        const FormInput = AcFormInput<IProduct>
        const { watch } = useFormContext<IProduct>()

        const isActive = watch(KEYS.WEBSHOP_ACTIVE)

        return (
            <AcForm onError={onError}>
                <AcFormCard title={LABELS.INFORMATION}>
                    <FormInput
                        name={KEYS.WEBSHOP_ACTIVE}
                        label={LABELS.WEBSHOP_ACTIVE}
                        type="switch"
                    />
                    <FormInput
                        name={KEYS.WEBSHOP_NAME}
                        label={LABELS.WEBSHOP_NAME}
                        required
                        validate={isActive ? 'required' : ''}
                    />
                    <FormInput
                        name={KEYS.WEBSHOP_DESCRIPTION}
                        label={LABELS.DESCRIPTION}
                    />
                    <FormInput
                        name={KEYS.WEBSHOP_SALE}
                        label={LABELS.SALE_PRICE}
                        type="number"
                        validate={isActive ? 'required' : ''}
                        startAdornment={<span style={{ width: '1.5rem' }}>€</span>}
                        transform={{ input: AcIntlInputFloat }}
                    />
                    <FormInput
                        name={KEYS.IMAGE}
                        label={LABELS.IMAGE}
                        type="image-crop"
                    />
                </AcFormCard>
            </AcForm>
        )
    }
)
