import { AcForm, AcFormCard, AcFormInput } from '@components'
import { KEYS, LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import { IProduct } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'

export type IProductAvailabilityForm = {
  onError: () => undefined | void
}

export const ProductAvailabilityForm = observer(
  ({ onError }: IProductAvailabilityForm) => {
    const { products } = useStore()
    const FormInput = AcFormInput<IProduct>

    const { watch } = useFormContext()

    const hasCustomAvailability = watch('has_custom_availability')

    const getWeeks = useMemo(() => {
      const weeks = []
      const current = dayjs().add(1, 'day')
      for (let i = 1; i <= 52; i++) {
        const week = current.add(i, 'weeks')
        weeks.push({
          label:
            week.year() > current.year()
              ? `Week ${week.isoWeek()} - ${week.year()}`
              : `Week ${week.isoWeek()}`,
          value: `${week.isoWeek()}-${week.isoWeekYear()}`,
        })
      }
      return weeks
    }, [])

    return (
      <AcForm onError={onError}>
        <AcFormCard title={LABELS.AVAILABILITY}>
          <FormInput
            name={KEYS.HAS_CUSTOM_AVAILABILITY}
            label={LABELS.AVAILABILITY_MODIFIED}
            type="switch"
          />

          {!!hasCustomAvailability && (
            <FormInput
              name={KEYS.WEEKS}
              defaultValue={products.current_product_weeks_objects}
              label={LABELS.WEEKS_ACTIVE}
              type="multi-select"
              labelKey="label"
              valueKey="value"
              options={getWeeks}
            />
          )}
        </AcFormCard>
      </AcForm>
    )
  }
)
