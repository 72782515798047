import { HTMLAttributes } from 'react'

import { ITemplateProduct } from '@typings'
import { AcTemplateProductTitle } from '../index.features.components'

import { AcTypography } from '@components'
import { AcIntlPrice } from '@helpers/ac-intl-number.helper'

import styles from './ac-product-selector.module.scss'
import clsx from 'clsx'

export const AcProductSelectorItem = ({
  product,
  selected,
  onClick,
}: {
  product: ITemplateProduct
  selected?: boolean
  onClick?: HTMLAttributes<HTMLDivElement>['onClick']
}) => {
  return (
    <div
      className={clsx(
        styles['ac-product-selector-item'],
        selected && styles['ac-product-selector-item--selected']
      )}
      onClick={onClick}>
      <AcTemplateProductTitle {...product} />
      <AcTypography
        size="xs"
        color="neutral-700"
        className={styles['ac-product-selector-price']}>
        {AcIntlPrice(product.sale)}
      </AcTypography>
    </div>
  )
}
