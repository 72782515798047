import clsx from 'clsx'
import { useMemo } from 'react'

import styles from './ac-container.module.scss'

interface IAcContainer {
  children: React.ReactNode
  size?: 'lg' | 'md' | 'sm' | 'xs'
  className?: string | boolean
  element?: 'div' | 'section' | 'main'
}

export const AcContainer = ({
  children,
  size = 'md',
  className,
  element: Element = 'div',
}: IAcContainer) => {
  const getTypographyClassNames = useMemo(
    () =>
      clsx(
        styles['ac-container'],
        styles[`ac-container--${size}`],
        className && className
      ),
    [Element, className, size]
  )

  return <Element className={getTypographyClassNames}>{children}</Element>
}
