import { AcLoader } from '@components'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const AcLogin = () => {
  const { state } = useLocation()
  const { host } = window.location
  const redirectUrl = new URL(
    `${process.env.REACT_APP_BUURTBASE_URL}/auth/login?nextbase_origin=http://${host}`
  )

  if (state?.intended) {
    redirectUrl.searchParams.set('nextbase_intended', state.intended)
  }

  useEffect(() => {
    window.location.href = redirectUrl.href
  }, [])

  return <AcLoader />
}
