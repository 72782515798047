import {
  AcCard,
  AcIcon,
  AcTabs,
  AcTextInput,
  AcTitle,
  AcTypography,
} from '@components'
import { GLOBAL_SEARCH_TABS, KEYS, LABELS } from '@constants'
import { withDebounce } from '@helpers/with-debounce'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef } from 'react'

import styles from './ac-global-search.module.scss'

export const AcGlobalSearch = observer(() => {
  const { global_search } = useStore()
  const inputElement = useRef<HTMLInputElement>(null)

  const renderEmpty = useMemo(() => {
    return (
      <div className={styles['ac-global-search-empty-state']}>
        <AcIcon icon="SEARCH" />
        <AcTitle level={3}>{LABELS.SEARCH_TITLE}</AcTitle>
        <AcTypography
          html={LABELS.SEARCH_SUBTITLE}
          size="sm"
        />
      </div>
    )
  }, [])

  const renderResults = useMemo(() => {
    const filteredRelevantTabs = GLOBAL_SEARCH_TABS.filter(({ id }) => {
      if (id === KEYS.ALL) return true
      return Object.prototype.hasOwnProperty.call(global_search.results, id)
    })
    return (
      <div className={styles['ac-global-search-results']}>
        <AcTabs
          className={styles['ac-global-search-tabs']}
          tabs={filteredRelevantTabs}
        />
      </div>
    )
  }, [global_search.results])

  const renderContent = useMemo(() => {
    if (global_search.is_empty) return renderEmpty
    else return renderResults
  }, [global_search.is_empty, renderResults])

  const navigateList = (event: KeyboardEvent) => {
    // For future me: navigate through list, keep selected index in store
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
      event.preventDefault()
    }
  }

  useEffect(() => {
    inputElement.current?.focus()
    inputElement.current?.select()

    document.addEventListener('keydown', navigateList)

    return () => document.removeEventListener('keydown', navigateList)
  }, [])

  return (
    <AcCard
      elevation="lg"
      padding="none">
      <AcTextInput
        ref={inputElement}
        name="search"
        type="search"
        id="search-input"
        className={styles['ac-global-search-input']}
        startAdornment={<AcIcon icon="SEARCH" />}
        placeholder={LABELS.SEARCH_PLACEHOLDER}
        defaultValue={global_search.query}
        onInput={withDebounce(
          (e: React.ChangeEvent<HTMLInputElement>) =>
            global_search.search(e.target.value),
          600
        )}
      />
      <hr className={styles['ac-global-search-divider']} />
      {renderContent}
    </AcCard>
  )
})
