import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './ac-toast-container.scss'

export const AcToastContainer = () => {
  return (
    <ToastContainer
      theme="dark"
      position="bottom-center"
      closeButton={false}
      hideProgressBar={true}
    />
  )
}
