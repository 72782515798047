import { useMemo, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styles from './ac-template-lunch-day-row.module.scss'
import { AcCheckbox, AcSelect, AcTextInput, AcTypography } from '@components'
import { DIETARY_PREFERENCES_BASIC } from '@constants'
import type {
  IWeekdaysKeys,
  IDietaryPreferencesBasicKeys,
  ICustomerProfileDayRow,
} from '@typings'
import { useFormContext } from 'react-hook-form'
export interface IAcLunchDayRow {
  day: IWeekdaysKeys
  label: string
  readOnly?: boolean
  GRID_TEMPLATE_COLS: string
  initial: ICustomerProfileDayRow | undefined
  showDietaryPreferences?: boolean,
  index: number,
  onRowSelect: (isSelected: boolean, day: IWeekdaysKeys) => void
  onRowDataChange: (values: ICustomerProfileDayRow) => void
}

const MAX = 10000

export const AcLunchDayRow = observer(
  ({
    day,
    label,
    readOnly,
    initial,
    showDietaryPreferences,
    index,
    onRowSelect,
    onRowDataChange,
    GRID_TEMPLATE_COLS,
  }: IAcLunchDayRow) => {
    const { formState: { errors } } = useFormContext()
    const [isSelected, setIsSelected] = useState(initial?.day ? true : false)
    const [preference, setPreference] =
      useState<null | IDietaryPreferencesBasicKeys>(initial?.preference || null)
    const [luncher_count, setLuncherCount] = useState<null | number>(
      initial?.luncher_count || null
    )

    useEffect(() => {
      if (initial?.day) setIsSelected(true)
      if (initial?.preference) setPreference(initial.preference)
      if (initial?.luncher_count) setLuncherCount(initial.luncher_count)
    }, [initial])

    const getDietaryPreferenceOptions = useMemo(() => {
      const keys = Object.entries(DIETARY_PREFERENCES_BASIC)
      return keys.map(([value, label]) => ({ value, label }))
    }, [])

    const handleSelectChange = (value: IDietaryPreferencesBasicKeys) => {
      setPreference(value)
    }

    const handleCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        const newVal = parseInt(e.target.value)
        if (newVal > MAX) setLuncherCount(MAX)
        setLuncherCount(Math.round(newVal))
      } else {
        setLuncherCount(null)
      }
    }

    useEffect(() => {
      onRowSelect(isSelected, day)
      if (!isSelected) {
        setPreference(null)
        setLuncherCount(null)
      }
    }, [isSelected])

    useEffect(() => {
      if (luncher_count || preference) {
        onRowDataChange({ day, preference, luncher_count })
      }
    }, [luncher_count, preference])

    // eslint-disable-next-line
    const errs = (errors?.days as any)?.[`${index}`]

    return (
      <div
        className={styles['ac-template-lunch-day-row']}
        style={{ gridTemplateColumns: GRID_TEMPLATE_COLS }}>
        <AcCheckbox
          label={label}
          checked={isSelected}
          onClick={() => setIsSelected(!isSelected)}
          disabled={readOnly}
        />
        <AcTypography color={readOnly ? 'neutral-500' : 'neutral-900'}>
          {label}
        </AcTypography>
        {isSelected && (
          <>
            {showDietaryPreferences ? (
              <AcSelect
                id={day}
                name={label}
                readOnly={readOnly}
                inputFormat="value"
                outputFormat="value"
                labelKey="label"
                valueKey="value"
                noMinWidth
                options={getDietaryPreferenceOptions}
                defaultValue={preference}
                onChange={handleSelectChange}
                error={(errs?.preference?.message as string)}
              />
            ) : <div></div>}
            <AcTextInput
              id={day}
              type="number"
              name={label}
              readOnly={readOnly}
              onChange={handleCountChange}
              value={luncher_count?.toString()}
              min={0}
              step={1}
              max={MAX}
              error={(errs?.luncher_count?.message as string)} />
          </>
        )}
      </div>
    )
  }
)
