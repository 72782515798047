import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'

import {
  AcButton,
  AcCounterInput,
  AcDropTargetContainer,
  AcTemplateCategory,
  AcTitle,
  AcTypography,
} from '@components'
import { DRAG_TYPES, ICONS, KEYS, LABELS } from '@constants'
import { AcIntlPrice } from '@helpers/ac-intl-number.helper'
import {
  AcGetProductCollectionAmount,
  AcGetProductsFromCategoryCollection,
} from '@helpers/ac-template.helper'
import { useStore } from '@hooks/use-store'
import { ITemplateDay, ITemplateDragItem, ITemplateProduct } from '@typings'

import { AcProductSelector } from '../ac-product-selector/ac-product-selector'

import styles from './ac-template-day.module.scss'

export const AcTemplateDay = observer(
  ({ name, lunchers, lunch, extra }: ITemplateDay) => {
    const { template } = useStore()
    const [extraWrapperRef, setExtraWrapperRef] = useState<HTMLElement | null>(
      null
    )

    const [{ productIsOver, productCanDrop }, productDropRef] = useDrop(() => ({
      accept: DRAG_TYPES.PRODUCT,
      drop: (item: ITemplateDragItem) => template.handleDragProduct(item, name),
      collect: monitor => ({
        productIsOver: monitor.isOver(),
        productCanDrop: monitor.canDrop(),
      }),
    }))

    const [{ extraIsOver, extraCanDrop }, extraDropRef] = useDrop(() => ({
      accept: DRAG_TYPES.EXTRA_PRODUCT,
      drop: (item: ITemplateDragItem) => template.handleDragProduct(item, name),
      collect: monitor => ({
        extraIsOver: monitor.isOver(),
        extraCanDrop: monitor.canDrop(),
      }),
    }))

    const getExtraWrapperClasses = useMemo(
      () =>
        clsx(styles['ac-template-day-extra'], styles['ac-template-day-card']),
      []
    )

    const getDayBudget = useMemo(
      () => lunchers * template.current_info.budget_to_spend,
      [
        lunchers,
        template.current_info.budget_to_spend,
        template.current_info.budget,
      ]
    )

    const getCurrentExtraProductCount = useMemo(() => {
      return extra.reduce((bulk, curr) => bulk + curr.quantity, 0)
    }, [extra])

    const getCurrentPlannedDayBudget = useMemo(() => {
      const array: ITemplateProduct[] = []
      const products = lunch.reduce((bulk, curr) => {
        for (const product of curr.products) {
          bulk.push(product)
        }
        return bulk
      }, array)

      return AcGetProductCollectionAmount(products)
    }, [
      lunch,
      template.current_info.budget_to_spend,
      template.current_info.budget,
    ])

    const getTotalExtraPrice = useMemo(() => {
      const allProducts = AcGetProductsFromCategoryCollection(extra)
      return AcGetProductCollectionAmount(allProducts)
    }, [extra])

    const handleLunchersChange = (number: number) => {
      template.handleLunchers(name, number)
    }

    return (
      <div
        className={styles['ac-template-day']}
        ref={productDropRef}>
        <div className={`${styles['ac-template-day-card']}`}>
          <AcDropTargetContainer
            canDrop={productCanDrop}
            isOver={productIsOver}
          />
          <div className={styles['ac-template-day-info']}>
            <span className={styles['ac-template-day-info-item']}>
              <AcTypography size="xs">{LABELS.LUNCHERS}</AcTypography>
              <AcCounterInput
                readOnly={!template.user_can_edit}
                id={name}
                name={'test'}
                value={lunchers}
                onBlur={event =>
                  handleLunchersChange(parseInt(event.target.value))
                }
                onMouseDown={e => e.stopPropagation()}
              />
            </span>
            <span className={styles['ac-template-day-info-item']}>
              <AcTypography size="xs">{LABELS.DAY_BUDGET_CLIENT}</AcTypography>
              <AcTypography size="xs">{AcIntlPrice(getDayBudget)}</AcTypography>
            </span>
            <span className={styles['ac-template-day-info-item']}>
              <AcTypography size="xs">{LABELS.PLANNED_TODAY}</AcTypography>
              {getCurrentPlannedDayBudget > getDayBudget && (
                <AcTypography
                  size="xs"
                  className={styles['ac-template-day-info-budget--error']}>
                  {AcIntlPrice(getCurrentPlannedDayBudget)}
                </AcTypography>
              )}
              {getCurrentPlannedDayBudget <= getDayBudget && (
                <AcTypography
                  size="xs"
                  className={styles['ac-template-day-info-budget--succes']}>
                  {AcIntlPrice(getCurrentPlannedDayBudget)}
                </AcTypography>
              )}
            </span>
          </div>
          {lunch && (
            <div className={styles['ac-template-day-lunch']}>
              {lunch.map(category => (
                <AcTemplateCategory
                  group={KEYS.LUNCH}
                  day={name}
                  hasActions
                  key={category.id}
                  {...category}
                />
              ))}
            </div>
          )}
        </div>
        <div
          className={getExtraWrapperClasses}
          ref={setExtraWrapperRef}>
          <div ref={extraDropRef}>
            <AcDropTargetContainer
              canDrop={extraCanDrop}
              isOver={extraIsOver}
            />
            <div className={styles['ac-template-day-card-header']}>
              <AcTitle level={3}>
                {LABELS.EXTRAS} {`(${getCurrentExtraProductCount})`}
              </AcTitle>
              {template.user_can_edit && (
                <div className={styles['ac-template-day-card-header-actions']}>
                  <AcProductSelector
                    loading={template.product_selector.loading}
                    productsGroupedByCategory={
                      template.product_selector.products
                    }
                    categories={template.product_selector.categories}
                    group={KEYS.EXTRA}
                    parentRef={extraWrapperRef}
                    onSearch={params =>
                      template.handleProductSearch(name, params)
                    }
                    onChange={product =>
                      template.handleAddProduct(product, name, KEYS.EXTRA)
                    }>
                    <AcButton
                      padding="xs"
                      color="secondary"
                      icon={ICONS.PLUS}
                      variant="icon"
                      label={LABELS.ADD_PRODUCT}
                    />
                  </AcProductSelector>
                </div>
              )}
            </div>
            <div
              className={`${styles['ac-template-day-info-item']} 
              ${styles['ac-template-day-padding-8']}`}>
              <AcTypography size="xs">{LABELS.TOTAL}</AcTypography>
              <AcTypography size="xs">
                {AcIntlPrice(getTotalExtraPrice)}
              </AcTypography>
            </div>
            {extra && (
              <div className={styles['ac-template-day-extra-categories']}>
                {extra.map(category => (
                  <AcTemplateCategory
                    group={KEYS.EXTRA}
                    day={name}
                    key={category.id}
                    hasActions
                    {...category}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)
