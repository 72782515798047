import {
  AcForm,
  AcFormCard,
  AcFormInput,
  AcSupplierDeliveryTimes,
  AcTabs,
  AcTypography,
} from '@components'
import { KEYS, LABELS, MARKETS } from '@constants'
import { useStore } from '@hooks/use-store'
import { ISupplier } from '@typings'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

export type ISupplierInformationForm = {
  onError: () => undefined | void
}

export const SupplierInformationForm = observer(
  ({ onError }: ISupplierInformationForm) => {
    const FormInput = AcFormInput<ISupplier>
    const { suppliers } = useStore()

    const form = useFormContext<ISupplier>()
    const delivery_days = useWatch({
      control: form.control,
      name: 'delivery_days',
    })
    const markets = useWatch({
      control: form.control,
      name: 'markets',
    })

    const renderSupplierDeliveryTimeTabs = useMemo(() => {
      return MARKETS.map(({ id, abbreviation }) => {
        const value = Array.isArray(delivery_days)
          ? delivery_days.filter(({ market_id }) => market_id === id)
          : []

        const isMarketEnabled =
          markets?.length &&
          markets.some(({ id: formMarketId }) => formMarketId === id)

        return {
          id: 'delivery-time-tab-' + id,
          label: abbreviation,
          component: () => (
            <AcSupplierDeliveryTimes
              key={id}
              value={value}
              marketId={id}
              disabled={!isMarketEnabled}
              onChange={deliveryDays =>
                suppliers.updateDeliveryDays(id, deliveryDays)
              }
            />
          ),
          disabled: false,
        }
      })
    }, [delivery_days, markets])

    useEffect(() => {
      suppliers.setForm(form)
    }, [])

    return (
      <AcForm onError={onError}>
        <AcFormCard title={LABELS.SUPPLIER_INFORMATION}>
          <FormInput
            name={KEYS.NAME}
            label={LABELS.NAME}
            validate="required"
          />
          <FormInput
            name={KEYS.ID}
            label={LABELS.ID}
            readOnly
          />
          <FormInput
            name={KEYS.STREET}
            label={LABELS.STREET}
          />
          <FormInput
            name={KEYS.NUMBER}
            label={LABELS.NUMBER_AND_APPENDIX}
          />
          <FormInput
            name={KEYS.POSTALCODE}
            label={LABELS.POSTALCODE}
          />
          <FormInput
            name={KEYS.CITY}
            label={LABELS.CITY}
            validate="required"
          />
          <FormInput
            name={KEYS.PHONE}
            label={LABELS.PHONE}
            type="tel"
            validate="required"
          />
          <FormInput
            name={KEYS.EMAIL}
            label={LABELS.EMAIL}
            type="email"
            validate="required"
          />
        </AcFormCard>
        <AcFormCard
          title={LABELS.DELIVERIES}
          overflow="auto">
          <AcFormInput
            name="markets"
            label="Levering in regios"
            type="select-chip"
            labelKey="abbreviation"
            options={MARKETS}
          />
          <div>
            <AcTypography
              size="sm"
              color="neutral-700">
              {LABELS.DELIVERY_MOMENTS_ALT}
            </AcTypography>
            <AcTabs tabs={renderSupplierDeliveryTimeTabs} />
          </div>
        </AcFormCard>
        <AcFormCard title={LABELS.DATES}>
          <FormInput
            name={KEYS.CREATED_AT}
            label={LABELS.CREATED}
            readOnly
            transform={{
              input: value =>
                value ? dayjs(value as string).format('DD-MM-YYYY HH:mm') : '',
            }}
          />
          <FormInput
            name={KEYS.UPDATED_AT}
            label={LABELS.LAST_UPDATED}
            readOnly
            transform={{
              input: value =>
                value ? dayjs(value as string).format('DD-MM-YYYY HH:mm') : '',
            }}
          />
        </AcFormCard>
      </AcForm>
    )
  }
)
