import { AcIcon, AcTitle } from '@components'
import { ICONS, LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import { ITemplateTodoTaskData } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { AcTemplateTodoTasksItem } from '../index.features.components'
import styles from './ac-template-todo-tasks.module.scss'

export const AcTemplateTodoTasks = observer(() => {
  const { template } = useStore()

  const renderTodos = useMemo(
    () =>
      template.current_todo_tasks?.data?.map(
        (singleTodo: ITemplateTodoTaskData) => (
          <AcTemplateTodoTasksItem
            disabled={!template.user_can_edit}
            {...singleTodo}
            onClick={() => template.handleTask(singleTodo.id)}
            key={`${singleTodo.id}--${singleTodo.is_done}`}
          />
        )
      ),
    [template.current_todo_tasks]
  )

  const renderEmpty = () => (
    <div className={styles['ac-template-todo-tasks-empty']}>
      <AcIcon
        className={styles['ac-template-todo-tasks-empty-icon']}
        icon={ICONS.CHECKLIST}
        size="xl"
      />
      <AcTitle
        level={3}
        className={styles['ac-template-todo-tasks-empty-title']}>
        {LABELS.NO_TASKS_AVAILABLE}
      </AcTitle>
    </div>
  )

  return (
    <div className={styles['ac-template-todo-tasks']}>
      {template.current_todo_tasks?.data?.length ? renderTodos : renderEmpty()}
    </div>
  )
})
