import { AcIcon, AcTypography } from '@components'
import { ICON_ELEMENTS } from '@constants'

import styles from './ac-template-profile-list-item.module.scss'

type IAcTemplateProfileListItem = {
  icon: keyof typeof ICON_ELEMENTS
  label: string
  children: React.ReactNode
}

export const AcTemplateProfileListItem = ({
  icon,
  label,
  children,
}: IAcTemplateProfileListItem) => {
  return (
    <div className={styles['ac-template-profile-list-item']}>
      <AcIcon
        size="lg"
        icon={icon}
      />
      <AcTypography
        weight="semibold"
        size="sm">
        {label}
      </AcTypography>
      <AcTypography
        size="xs"
        weight="light"
        className={styles['ac-template-profile-list-item__content']}>
        {children}
      </AcTypography>
    </div>
  )
}
