import { AcButton, AcDialog, AcTextInput, AcTypography } from '@components'
import { LABELS } from '@constants'
import { handleKeyDown } from '@helpers/handle-key-down.helper'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import styles from './template-edit-lunch-budget.module.scss'

export const TemplateEditLunchBudgetModal = observer(() => {
  const { ui, template } = useStore()
  const [inputValue, setInputValue] = useState(template.current_info.budget)
  const getDisabledState = useMemo(
    () => inputValue === template.current_info.budget,
    [inputValue, template.current_info.budget]
  )
  const handleCancel = () => {
    if (ui.modal && ui.modal.reject) {
      ui.modal.reject()
    }
  }

  const handleConfirm = async () => {
    await template.handleLunchBudgetChange(inputValue)
  }

  return (
    <AcDialog
      title={LABELS.UPDATE_LUNCH_BUDGET_PER_PERSON}
      className={styles['ac-template-lunchbudget']}>
      <div className={styles['ac-template-lunchbudget-content']}>
        <AcTypography
          className={styles['ac-template-lunchbudget-content-label']}
          size="xs"
          color="neutral-700"
          element="span"
          weight="light">
          {LABELS.LUNCH_BUDGET_PER_PERSON}
        </AcTypography>
        <AcTextInput
          value={inputValue}
          onKeyDown={e => handleKeyDown(e, handleConfirm)}
          startAdornment={'€'}
          onChange={e => setInputValue(parseFloat(e.target.value))}
          title="budget"
          name="lunch"
          type="number"
          id="test"
          hint={LABELS.BUDGET_ROUNDED_TO_TWO_DECIMALS}
        />
      </div>
      <div className={styles['ac-template-lunchbudget-actions']}>
        <AcButton
          color="secondary"
          label={LABELS.CANCEL}
          onClick={handleCancel}
        />
        <AcButton
          disabled={getDisabledState}
          color="primary"
          label={LABELS.CONFIRM}
          onClick={handleConfirm}
        />
      </div>
    </AcDialog>
  )
})
