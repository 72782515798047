import { observer } from 'mobx-react-lite'
import { createRef, useMemo, useState } from 'react'

import {
  AcButton,
  AcDialog,
  AcLayeredMenu,
  AcTextInput,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

import styles from './template-todo-menu-edit.module.scss'

import { usePopper } from 'react-popper'
import { useOnClickOutside } from '@hooks/use-click-outside'

export const TemplateTodoMenuEdit = observer(() => {
  const { ui, template } = useStore()
  const [inputValue, setInputValue] = useState(
    template.current_todo_menu?.data?.message
  )

  const wrapRef = createRef<HTMLElement>()
  const [parentComponent, setParentComponent] =
    useState<HTMLButtonElement | null>(null)
  const [menuComponent, setMenuComponent] = useState<HTMLDivElement | null>(
    null
  )
  const { styles: popperStyles, attributes } = usePopper(
    parentComponent,
    menuComponent,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    }
  )
  const [menuOpen, setMenuOpen] = useState(false)

  useOnClickOutside(wrapRef, () => setMenuOpen(false))

  const handleDelete = async () => {
    await template.handleTodoMenuDelete()
    ui.modal.resolve?.()
  }

  const handleCancel = () => {
    if (ui.modal && ui.modal.reject) {
      ui.modal.reject()
    }
  }

  const handleConfirm = async () => {
    await template.handleTodoMenuChange(inputValue)
    ui.modal.resolve?.()
  }

  const handleInputChange = ({ target }: { target: HTMLTextAreaElement }) => {
    setInputValue(target.value)
  }

  const renderMoreMenu = useMemo(() => {
    return (
      <div
        style={{ ...popperStyles.popper, zIndex: 99 }}
        ref={setMenuComponent}
        {...attributes.popper}>
        <AcLayeredMenu
          menuOptions={[{ label: 'Verwijderen', onClick: handleDelete }]}
          handleClose={() => setMenuOpen(false)}
        />
      </div>
    )
  }, [popperStyles.popper, attributes.popper, setMenuOpen])

  return (
    <AcDialog
      title={LABELS.SALAD_AND_SOUPS_MENU}
      className={styles['template-todo-menu-edit']}>
      {template.current_todo_menu?.data?.message && (
        <span
          ref={wrapRef}
          className={styles['template-todo-menu-edit-more']}>
          <AcButton
            ref={setParentComponent}
            variant="icon"
            label={LABELS.MORE_ACTIONS}
            icon="MORE"
            onClick={() => setMenuOpen(true)}
          />
          {menuOpen && renderMoreMenu}
        </span>
      )}
      <div className={styles['template-todo-menu-edit-content']}>
        <AcTypography
          size="xs"
          element="span">
          {LABELS.MENU}
        </AcTypography>
        <AcTextInput
          id="menu"
          name="menu"
          type="textarea"
          rows={6}
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles['template-todo-menu-edit-actions']}>
        <AcButton
          color="secondary"
          label={LABELS.CANCEL}
          onClick={handleCancel}
          withToolTip={false}
        />
        <AcButton
          color="primary"
          label={LABELS.SEND_MENU}
          onClick={handleConfirm}
          withToolTip={false}
        />
      </div>
    </AcDialog>
  )
})
