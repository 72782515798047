import {
  AcButton,
  AcDialog,
  AcIcon,
  AcTextInput,
  AcTypography,
} from '@components'
import { DYNAMIC_LABELS, ICONS, LABELS, TAG_CATEGORIES } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { AcSelect } from 'src/components/core/ac-select/ac-select'
import styles from './tags-create.module.scss'
import { ISingleTag } from '@typings'
import { toast } from 'react-toastify'

export const TagsCreateModal = observer(() => {
  const { tags, ui } = useStore()
  const [tagCategory, setTagCategory] = useState<
    ISingleTag['category'] | undefined
  >()
  const [tagName, setTagName] = useState('')

  const isDisabled = useMemo(
    () => !(!tags.loading && tagName !== '' && tagCategory !== undefined),
    [tagCategory, tagName, tags.loading]
  )

  const handleCreate = async () => {
    if (tagCategory && tagName)
      await tags
        .createTag(tagName, tagCategory)
        .then(name =>
          toast(DYNAMIC_LABELS.TAG_CREATED_SUCCESSFULLY(name), {
            icon: <AcIcon icon={ICONS.CHECK} />,
          })
        )
        .catch(() => void 0)
  }

  return (
    <AcDialog title={LABELS.TAG_CREATE}>
      <div className={styles['ac-tags-create-modal-form--intro']}>
        <AcTypography
          weight="light"
          element="span">
          {LABELS.TAG_CREATE_INTRO}
        </AcTypography>
      </div>
      <div className={styles['ac-tags-create-modal-form--inputs']}>
        <AcTextInput
          type="text"
          loading={tags.loading}
          name="tag-name"
          id="tag-name"
          placeholder={LABELS.TAG_NAME}
          value={tagName}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTagName(e.target.value)
          }
        />
        <AcSelect
          inputFormat="value"
          loading={tags.loading}
          defaultValue={tagCategory}
          onChange={({ value }) => setTagCategory(value)}
          options={TAG_CATEGORIES}
          placeholder={LABELS.SELECT_CATEGORY}
          labelKey="label"
          valueKey="value"
          name="tag-category"
          id="tag-category"
        />
      </div>
      <div className={styles['ac-tags-create-modal-form--actions']}>
        <AcButton
          color="secondary"
          onClick={() => ui.closeModalGracefully()}
          label={LABELS.CANCEL}
        />
        <AcButton
          label={LABELS.TAG_CREATE}
          onClick={handleCreate}
          disabled={isDisabled}
        />
      </div>
    </AcDialog>
  )
})
