import {
  AcButton,
  AcForm,
  AcFormCard,
  AcFormContext,
  AcFormInput,
  AcTable,
} from '@components'
import { KEYS, LABELS } from '@constants'
import { ISupplier, ISupplierContact } from '@typings'
import { useContext, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

export type ISupplierContactsForm = {
  onError: () => undefined | void
}

const generateSupplierContact = (): ISupplierContact => {
  return {
    id: 0,
    name: '',
    mail: '',
    phone: '',
    update: '',
    is_primary: false,
  }
}

export const SupplierContactsForm = ({ onError }: ISupplierContactsForm) => {
  const { control, setValue, watch, getValues } = useFormContext<ISupplier>()
  const { readOnly } = useContext(AcFormContext)

  const {
    fields: contacts,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'contacts',
    keyName: 'key',
  })

  const FormInput = AcFormInput<ISupplier>

  const primary_contact_id = watch('primary_contact_id')

  const renderContacts = useMemo(() => {
    if (!contacts?.length) return

    const setNewContactDefault = (index: number) => {
      const contacts = getValues('contacts')
      const previousPrimaryIndex = contacts.findIndex(
        ({ is_primary }) => !!is_primary
      )
      if (~previousPrimaryIndex) {
        update(previousPrimaryIndex, {
          ...contacts[previousPrimaryIndex],
          is_primary: false,
        })
      }
      update(index, { ...contacts[index], is_primary: true })
      setValue('primary_contact_id', null)
    }

    const contactElements = []
    for (const [index, contact] of contacts.entries()) {
      contactElements.push(
        <tr key={contact.key}>
          <td>
            <FormInput
              label={LABELS.NAME}
              name={`${KEYS.CONTACTS}.${index}.${KEYS.NAME}`}
              withLabel={false}
            />
          </td>
          <td>
            <FormInput
              label={LABELS.EMAIL}
              name={`${KEYS.CONTACTS}.${index}.${KEYS.MAIL}`}
              withLabel={false}
            />
          </td>
          <td>
            <FormInput
              label={LABELS.PHONE}
              name={`${KEYS.CONTACTS}.${index}.${KEYS.PHONE}`}
              withLabel={false}
            />
          </td>
          <td>
            {contact[KEYS.ID] ? (
              <FormInput
                type="radio"
                option={contact[KEYS.ID] as ID}
                label={LABELS.PRIMARY_CONTACT}
                name="primary_contact_id"
              />
            ) : (
              <FormInput
                type="radio"
                option={contact[KEYS.ID] as ID}
                label={LABELS.PRIMARY_CONTACT}
                name={`${KEYS.CONTACTS}.${index}.${KEYS.IS_PRIMARY}`}
                onClick={() => setNewContactDefault(index)}
                checked={!primary_contact_id && contact[KEYS.IS_PRIMARY]}
              />
            )}
          </td>
          <td>
            {!readOnly && (
              <AcButton
                variant="icon"
                label={LABELS.REMOVE}
                icon="DELETE"
                onClick={() => remove(index)}
              />
            )}
          </td>
        </tr>
      )
    }
    return contactElements
  }, [contacts, readOnly, primary_contact_id])

  return (
    <AcForm
      onError={onError}
      layout="column">
      <AcFormCard title={LABELS.CONTACTS}>
        <div>
          <AcTable>
            <thead>
              <tr>
                <th>{LABELS.NAME}</th>
                <th>{LABELS.EMAIL}</th>
                <th>{LABELS.PHONE}</th>
                <th>{LABELS.PRIMARY_CONTACT}</th>
                <th>{/** Remove */}</th>
              </tr>
            </thead>
            <tbody>{renderContacts}</tbody>
          </AcTable>
          {!readOnly && (
            <div className="row middle h-4 mx-n1">
              <AcButton
                label={LABELS.ADD_CONTACT}
                variant="text"
                icon="PLUS"
                onClick={() => append(generateSupplierContact())}
              />
            </div>
          )}
        </div>
      </AcFormCard>
    </AcForm>
  )
}
