import { ICON_ELEMENTS } from '@constants'
import { PERMISSIONS } from './permissions.constants'
import { ICONS } from './icons.constants'
import { LABELS } from './labels.constants'
import { ROUTE_PATHS } from './routes.constants'

interface IMenuItem {
  path: string
  label: string
  icon: keyof typeof ICON_ELEMENTS,
  allowed?: string[]
}

export const MENU: IMenuItem[] = [
  {
    path: ROUTE_PATHS.DASHBOARD,
    label: LABELS.DASHBOARD,
    icon: ICONS.DASHBOARD,
  },
  {
    path: ROUTE_PATHS.CUSTOMER_LIST,
    label: LABELS.CUSTOMERS,
    icon: ICONS.USERS,
  },
  {
    path: ROUTE_PATHS.TEMPLATE_LIST,
    label: LABELS.TEMPLATES,
    icon: ICONS.USER,
  },
  {
    path: ROUTE_PATHS.DELIVERY_LIST,
    label: LABELS.DELIVERIES,
    icon: ICONS.TRUCK,
  },
  {
    path: ROUTE_PATHS.USER_LIST,
    label: LABELS.USERS,
    icon: ICONS.USER,
    allowed: [PERMISSIONS.USER.READ]
  },
  {
    path: ROUTE_PATHS.ORDER_LIST,
    label: LABELS.ORDERS,
    icon: ICONS.USER,
  },
  {
    path: ROUTE_PATHS.PRODUCT_LIST,
    label: LABELS.PRODUCTS,
    icon: ICONS.LABELS,
  },
  {
    path: ROUTE_PATHS.SUPPLIER_LIST,
    label: LABELS.SUPPLIERS,
    icon: ICONS.BUILDINGS,
  },
  {
    path: ROUTE_PATHS.INVOICE_LIST,
    label: LABELS.INVOICES,
    icon: ICONS.INVOICE,
  },
  {
    path: ROUTE_PATHS.SEPA_TOOLS,
    label: LABELS.SEPA_TOOLS,
    icon: ICONS.INBOX,
  },
  {
    path: ROUTE_PATHS.NOTIFICATION_LIST,
    label: LABELS.BB_NOTIFICATIONS,
    icon: ICONS.NOTIFICATION,
  },
  {
    path: ROUTE_PATHS.MANUAL_CATEGORY_LIST,
    label: LABELS.MANUALS,
    icon: ICONS.HELP,
  },
  {
    path: ROUTE_PATHS.DELIVERY_MOMENT_LIST,
    label: LABELS.DELIVERY_MOMENTS,
    icon: ICONS.DATE,
  },
  {
    path: ROUTE_PATHS.COUPON_LIST,
    label: LABELS.COUPONS,
    icon: ICONS.TICKET,
  },
  {
    path: ROUTE_PATHS.WEBSHOP_ORDER_LIST,
    label: LABELS.WEBSHOP_ORDERS,
    icon: ICONS.STORE,
  },
  {
    path: ROUTE_PATHS.SYSTEM_SETTINGS,
    label: LABELS.SYSTEM,
    icon: ICONS.SETTINGS,
  },
]
