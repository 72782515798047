export const WEEK_MENUS = [
  {
    key: 'SOUP',
    label: 'Soep',
    value: 'soup',
    profile_key: 'soup_active'
  },
  {
    key: 'SALAD',
    label: 'Salade',
    value: 'salad',
    profile_key: 'salad_active'
  }
]
