import { observer } from 'mobx-react-lite'

import { AcButton, AcTemplateDayMoreMenu, AcTitle } from '@components'
import { ICONS, KEYS, LABELS, WEEKDAYS } from '@constants'
import { useStore } from '@hooks/use-store'
import { ITemplateDay } from '@typings'

import { AcProductSelector } from '../ac-product-selector/ac-product-selector'

import styles from './ac-template-day-header.module.scss'

export const AcTemplateDayHeader = observer(
  ({ name, is_deletable }: ITemplateDay) => {
    const { template } = useStore()

    const handleBibi = () => {
      const data = {
        day: name,
      }
      template.handleBibiShuffle(data)
    }

    return (
      <div className={styles['ac-template-day-header']}>
        <div className={`${styles['ac-template-day-header-card']}`}>
          <div className={styles['ac-template-day-header-card-header']}>
            <AcTitle level={3}>{WEEKDAYS[name]}</AcTitle>
            {template.user_can_edit && (
              <div
                className={
                  styles['ac-template-day-header-card-header-actions']
                }>
                <AcButton
                  color="secondary"
                  padding="xs"
                  label={LABELS.BIBI_SHUFFLE}
                  onClick={handleBibi}
                  variant="icon"
                  icon={ICONS.BIBI}
                />
                <AcTemplateDayMoreMenu
                  day={name}
                  is_deletable={is_deletable}
                />
              </div>
            )}
          </div>
          <div className="">
            {template.user_can_edit && (
              <AcProductSelector
                loading={template.product_selector.loading}
                productsGroupedByCategory={template.product_selector.products}
                categories={template.product_selector.categories}
                onChange={product =>
                  template.handleAddProduct(product, name, KEYS.LUNCH)
                }
                onSearch={params => template.handleProductSearch(name, params)}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
)
