import { useEffect, useRef, useState } from 'react'

import styles from './ac-progress-trapezoid.module.scss'

export const AcProgressTrapezoid = ({
  max = 100,
  delay = 250,
  ...props
}: React.DetailedHTMLProps<
  React.ProgressHTMLAttributes<HTMLProgressElement>,
  HTMLProgressElement
> & { delay?: number }) => {
  const firstRender = useRef(true)
  const [delayedValue, setDelayedValue] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setDelayedValue(props.value as number)
      firstRender.current = false
    }, delay)
  }, [])

  useEffect(() => {
    if (firstRender.current) return

    setDelayedValue(props.value as number)
  }, [props.value])

  return (
    <>
      <progress
        {...props}
        max={max}
        value={delayedValue}
        className={styles['ac-progress-trapezoid']}
      />
    </>
  )
}
