import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { IGlobalSearchResponse } from '@typings'
import { AxiosResponse } from 'axios'

export class GlobalSearchApi extends AxiosBaseApi {
  search = (query: string) =>
    this.api
      .get<AxiosResponse<IGlobalSearchResponse>>(
        ENDPOINTS.GLOBAL_SEARCH.SEARCH(),
        {
          params: { q: query },
        }
      )
      .then(response => response.data.data)
}
