import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import {
  AcButton,
  AcIcon,
  AcTemplateHeaderClaim,
  AcTemplateHeaderMoreMenu,
  AcTemplateMargin,
  AcTemplateWeekSelection,
  AcTitle,
} from '@components'
import { ICONS, ROUTE_PATHS } from '@constants'
import { ITemplateInfo } from '@typings'

import { useStore } from '@hooks/use-store'

import styles from './ac-template-header.module.scss'

export const AcTemplateHeader = observer((props: ITemplateInfo) => {
  const { template } = useStore()
  const navigate = useNavigate()
  const handleBackAction = () => navigate(ROUTE_PATHS.TEMPLATE_LIST)

  return (
    <div
      className={clsx(
        styles['ac-template-header'],
        template.sidebarOpen && styles['ac-template-header--sidebar-open']
      )}>
      <div className={styles['ac-template-header--left']}>
        <AcButton
          variant="icon"
          label="Terug naar overzicht"
          icon={ICONS.PREVIOUS}
          onClick={() => handleBackAction()}
        />
        <AcTitle
          level={3}
          className={styles['ac-template-header-title']}>
          {props.name}
        </AcTitle>
      </div>
      <div className={styles['ac-template-header--center']}>
        <AcTemplateMargin />
      </div>
      <div className={styles['ac-template-header--right']}>
        {template.user_can_edit && <AcTemplateHeaderMoreMenu />}
        <AcTemplateWeekSelection />
        <AcTemplateHeaderClaim />
        <AcButton
          onClick={template.toggleSidebar}
          color="secondary"
          withToolTip={false}
          padding="sm"
          label="">
          <AcIcon
            icon={ICONS.SIDEBAR_LEFT}
            size="lg"
          />
        </AcButton>
      </div>
    </div>
  )
})
