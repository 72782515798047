import { KEYS, STORE_KEYS } from '@constants'
import { autoLoad, removeFromStorage } from '@helpers/browser-storage.helpers'
import { Store } from '@stores'
import { IAuthEmployee } from '@typings'
import { action, makeAutoObservable } from 'mobx'

interface IAuthDefaultValues {
  [key: string]: unknown
  access_token: Nullable<string>
  loading: boolean
  employee: Nullable<IAuthEmployee>
}

const defaultValues: IAuthDefaultValues = {
  access_token: null,
  loading: true,
  employee: null,
}

export class AuthStore {
  [key: string]: unknown
  store: Store
  access_token: Nullable<string>
  employee: Nullable<IAuthEmployee>
  loading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.access_token = defaultValues.access_token
    this.loading = true
    this.employee = null

    autoLoad(this, KEYS.ACCESS_TOKEN)
    autoLoad(this, KEYS.EMPLOYEE)
  }

  get current_employee() {
    return this.employee
  }

  get current_permissions(): string[] | undefined {
    return this.employee?.permissions
  }

  get is_authorized() {
    return !!this.access_token
  }

  @action
  setToken = async (access_token: string) => {
    await this.store.set(STORE_KEYS.AUTH, KEYS.ACCESS_TOKEN, access_token, true)
  }

  @action
  handleTempToken = async (token: string) => {
    this.store.set(STORE_KEYS.AUTH, KEYS.LOADING, true)
    try {
      const employee =
        await this.store.api.auth.postTempToken(token)
      await this.store.set(
        STORE_KEYS.AUTH,
        KEYS.ACCESS_TOKEN,
        employee.access_token,
        true
      )

      await this.store.set(STORE_KEYS.AUTH, KEYS.EMPLOYEE, employee, true)
    } catch (e) {
      console.error(e)
    } finally {
      this.store.set(STORE_KEYS.AUTH, KEYS.LOADING, false)
    }
  }

  @action
  handleIntended = (intended: string) => {
    this.store.set(STORE_KEYS.AUTH, KEYS.INTENDED, intended, true)
  }

  @action
  clearToken = () => {
    removeFromStorage(KEYS.ACCESS_TOKEN)
  }

  @action
  logout = () => {
    this.reset(KEYS.ACCESS_TOKEN)
  }

  @action
  reset = (key?: string) => {
    if (key) {
      removeFromStorage(key)
      this.store.set(STORE_KEYS.AUTH, key, defaultValues[key])
    } else {
      for (const key in defaultValues) {
        this.store.set(STORE_KEYS.AUTH, key, defaultValues[key])
      }
    }
  }
}
