import clsx from 'clsx'
import { useEffect, useId, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

import {
  AcButton,
  AcDivider,
  AcIcon,
  AcLoader,
  AcSelectChips,
  AcTextInput,
  AcTypography,
} from '@components'
import { ICONS, KEYS, LABELS } from '@constants'
import { sameWidth } from '@helpers/ac-popper.helpers'

import styles from './ac-product-selector.module.scss'

import { withDebounce } from '@helpers/with-debounce'
import { useOnClickOutside } from '@hooks/use-click-outside'
import {
  IProductCategory,
  ITemplateCategoryOption,
  ITemplateGroupType,
  ITemplateProduct,
  ITemplateProductQuery,
} from '@typings'
import { AcProductSelectorItem } from './ac-product-selector-item'

interface IAcProductSelector<C, P> {
  label?: string
  loading?: boolean
  categories?: C[]
  productsGroupedByCategory: P
  category_id?: ID
  /** @default 'lunch' */
  group?: ITemplateGroupType

  children?: React.ReactNode
  parentRef?: Nullable<HTMLElement>

  onChange: (product: ITemplateProduct[]) => void
  onSearch: (query: Partial<ITemplateProductQuery>) => void
}

export const AcProductSelector = <
  C extends IProductCategory,
  P extends ITemplateCategoryOption[]
>({
  label = LABELS.ADD_PRODUCT,
  loading,
  category_id,
  categories,
  productsGroupedByCategory,
  children,
  parentRef = null,
  group = KEYS.LUNCH,
  onChange,
  onSearch,
}: IAcProductSelector<C, P>) => {
  const [isOpen, setOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [category, setCategory] = useState<Nullable<IProductCategory>>()
  const [products, setProducts] = useState<P | never[]>(
    productsGroupedByCategory
  )
  const [selectedProducts, setSelectedProducts] = useState<
    Set<ITemplateProduct>
  >(new Set())
  const [resultMode, setResultMode] = useState<
    (typeof KEYS)[keyof typeof KEYS]
  >(KEYS.CATEGORY)

  const [refElement, setRefElement] = useState<HTMLElement | null>(parentRef)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const id = useId()

  const getOffset = useMemo(() => {
    let offset = [0, 0]

    if (refElement) {
      offset = [0, -refElement.getBoundingClientRect().height]
    }

    return {
      name: 'offset',
      options: { offset },
    }
  }, [refElement, isOpen])

  const {
    styles: { popper: popperStyle },
    attributes,
  } = usePopper(refElement, tooltipRef, {
    placement: 'bottom',
    modifiers: [sameWidth, getOffset],
    strategy: 'fixed',
  })

  tooltipRef &&
    useOnClickOutside({ current: tooltipRef }, () => {
      setOpen(false)
      setSearchQuery('')
      setCategory(null)
    })

  const handleSearchClear = () => {
    setSearchQuery('')
    setCategory(null)
    setProducts([])
    if (category_id) {
      setResultMode(KEYS.PRODUCTS)
      onSearch({ category_id, group })
    } else {
      setResultMode(KEYS.CATEGORY)
    }
    inputRef.current?.focus()
  }

  const handleProductSelectorOpen = () => {
    if (category_id) {
      setResultMode(KEYS.PRODUCTS)
      onSearch({ category_id, group })
    }
    setProducts([])
    setOpen(true)
  }

  const handleProductItemSelect = (product: ITemplateProduct) => {
    if (selectedProducts.has(product)) {
      setSelectedProducts(selected => {
        selected.delete(product)
        return new Set(selected)
      })
    } else {
      setSelectedProducts(selected => new Set(selected.add(product)))
    }
  }

  const handleEnterPress = (event: KeyboardEvent) => {
    if (
      event.code === 'Enter' ||
      event.code === 'NumpadEnter' ||
      event.code === 'Space'
    ) {
      setOpen(false)
      onChange?.([...selectedProducts])
      setSelectedProducts(new Set())
    }
  }

  const handleProductItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    product: ITemplateProduct
  ) => {
    if (event.ctrlKey || event.metaKey) return handleProductItemSelect(product)

    setOpen(false)
    onChange?.([product])
  }

  const preventBubbling = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  const getInputId = useMemo(() => `ac-product-selector-input-${id}`, [id])

  const renderLoading = useMemo(() => {
    return (
      <div
        className={clsx(
          styles['ac-product-selector-loader'],
          loading && styles['ac-product-selector-loader--loading']
        )}>
        <AcLoader />
      </div>
    )
  }, [loading])

  const renderCategories = useMemo(() => {
    if (!categories || resultMode !== KEYS.CATEGORY) return null

    return (
      <AcSelectChips
        onChange={([category]) => setCategory(category)}
        labelKey={KEYS.NAME}
        options={categories}
      />
    )
  }, [resultMode, categories])

  const renderProducts = useMemo(() => {
    if (resultMode !== KEYS.PRODUCTS) return null

    const productCategoryElements = []

    for (const productCategory of products) {
      const productElements = []

      for (const product of productCategory.products) {
        productElements.push(
          <AcProductSelectorItem
            product={product}
            selected={selectedProducts.has(product)}
            onClick={e => handleProductItemClick(e, product)}
          />
        )
      }
      productCategoryElements.push(
        <div className={styles['ac-product-selector-products']}>
          <AcTypography
            weight="semibold"
            size="sm"
            color="neutral-900">
            {productCategory.name}
          </AcTypography>
          <AcDivider color="neutral-200" />
          {productElements}
        </div>
      )
    }

    return productCategoryElements
  }, [resultMode, products, selectedProducts])

  const renderNoProductsFound = useMemo(() => {
    if (!searchQuery || loading || products?.length) return null

    return (
      <AcTypography
        className={styles['ac-product-selector-no-results']}
        size="sm"
        color="neutral-700">
        {LABELS.NO_PRODUCTS_FOUND_FOR} &quot;{searchQuery}&quot;
      </AcTypography>
    )
  }, [searchQuery, loading, products])

  useEffect(() => {
    isOpen && inputRef.current?.focus()
  }, [isOpen, category_id, searchQuery])

  useEffect(() => {
    if (category_id || category || searchQuery) {
      setResultMode(KEYS.PRODUCTS)
    } else {
      setResultMode(KEYS.CATEGORY)
    }
  }, [searchQuery, category, group, category_id])

  useEffect(() => {
    if (category || searchQuery) {
      onSearch({
        category_id: category_id || category?.id,
        query: searchQuery || undefined,
        group,
      })
    }
  }, [searchQuery, category])

  useEffect(() => {
    setProducts(productsGroupedByCategory)
  }, [productsGroupedByCategory])

  useEffect(() => {
    if (parentRef) setRefElement(parentRef)
  }, [parentRef])

  useEffect(() => {
    if (selectedProducts.size > 0) {
      document.addEventListener('keypress', handleEnterPress)
    }

    return () => document.removeEventListener('keypress', handleEnterPress)
  }, [selectedProducts])

  return (
    <div
      ref={children ? void 0 : setRefElement}
      onClick={children ? handleProductSelectorOpen : void 0}>
      {children || (
        <AcButton
          label={label}
          padding="md"
          color="secondary"
          icon={ICONS.PLUS}
          variant="push"
          fullWidth
          className={styles['ac-product-selector-button']}
          justifyContent="flex-start"
          onClick={handleProductSelectorOpen}
        />
      )}
      {isOpen &&
        createPortal(
          <div
            style={popperStyle}
            className={styles['ac-product-selector-popup']}
            ref={setTooltipRef}
            onMouseDown={preventBubbling}
            {...attributes.popper}>
            <AcTextInput
              ref={inputRef}
              id={getInputId}
              name={getInputId}
              className={styles['ac-product-selector-input']}
              placeholder={LABELS.SEARCH_PRODUCT}
              value={searchQuery}
              onInput={withDebounce(
                (e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchQuery(e.target.value),
                600
              )}
              startAdornment={<AcIcon icon={ICONS.SEARCH} />}
              endAdornment={
                (searchQuery || category) && (
                  <AcButton
                    variant="icon"
                    label={LABELS.CLEAR}
                    icon={ICONS.CLOSE}
                    onClick={handleSearchClear}
                  />
                )
              }
            />
            <AcDivider />
            <div className={styles['ac-product-selector-results']}>
              {renderLoading}
              {renderCategories}
              {renderProducts}
              {renderNoProductsFound}
            </div>
          </div>,
          document.body
        )}
    </div>
  )
}
