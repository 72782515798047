import {
  ProductInformationTab,
  ProductLocationTab,
  ProductExtrasTab,
  ProductAvailabilityTab,
} from './product-detail'
import { SupplierInformationTab } from './supplier-detail'
import { SupplierContactsTab } from './supplier-detail/supplier-contacts'
import {
  TemplateSidebarTodoTab,
  TemplateSideBarClientProfile,
} from './template-sidebar'

import {
  GlobalSearchAllTab,
  GlobalSearchCustomersTab,
  GlobalSearchTemplatesTab,
  GlobalSearchWeekOrdersTab,
} from './global-search'

import {
  CustomerUserListTab,
  EmployeeUserListTab
} from './user-list'

export const ProductDetailTabs = {
  ProductInformationTab,
  ProductLocationTab,
  ProductExtrasTab,
  ProductAvailabilityTab,
}

export const SupplierDetailTabs = {
  SupplierInformationTab,
  SupplierContactsTab,
}

export const GlobalSearchTabs = {
  GlobalSearchAllTab,
  GlobalSearchCustomersTab,
  GlobalSearchTemplatesTab,
  GlobalSearchWeekOrdersTab,
}

export const TemplateSidebarTabs = {
  TemplateSidebarTodoTab,
  TemplateSideBarClientProfile,
}

export const UserListTabs = {
  CustomerUserListTab,
  EmployeeUserListTab
}