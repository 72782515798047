import clsx from 'clsx'
import { forwardRef } from 'react'
import { IAcTextInputProps } from '../ac-text-input/ac-text-input'
import { AcTextInput } from '../index.core.component'
import styles from './ac-counter-input.module.scss'

interface IAcCounterInput extends IAcTextInputProps {
  children?: null
}
const AcCounterInput = forwardRef<HTMLInputElement, IAcCounterInput>(
  ({ ...inputProps }: IAcCounterInput, ref) => (
    <AcTextInput
      type="number"
      className={clsx(styles['ac-counter-input'])}
      padding={8}
      {...{ ...inputProps, ref }}
    />
  )
)

AcCounterInput.displayName = 'AcCounterInput'

export { AcCounterInput }
