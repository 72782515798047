import { AcDataGrid } from '@components'
import { useStore } from '@hooks/use-store'
import { ROUTE_PATHS } from '@constants'


export const EmployeeUserListTab = () => {
  const { users } = useStore()

  return (
    <div style={{ marginTop: 24 }}>
      <AcDataGrid
        store={users.data_grid_employees}
        detailPath={ROUTE_PATHS.USER_PROFILE}
      />
    </div>
  )
}
