import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { IAuthEmployee } from '@typings'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export class EmployeesApi extends AxiosBaseApi {
  get_list = (params?: AxiosRequestConfig['params']) =>
    this.api
      .get<AxiosResponse<{ data: IAuthEmployee[] }>>(
        ENDPOINTS.EMPLOYEES.LIST(),
        {
          params,
        }
      )
      .then(response => response.data.data)
}
