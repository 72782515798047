import {
  IUiModalGeneric,
  IUiModalConfirm,
  IUiModalWizard,
  IUiModalBase,
  IUiMenu,
} from '@typings'
import { Store } from '@stores'

// Imports => MOBX
import { action, computed, makeAutoObservable } from 'mobx'

// Imports => Constants
import { KEYS, STORE_KEYS } from '@constants'

// Imports => Utilities
import { removeFromStorage } from '@helpers/browser-storage.helpers'

const DIALOG_TRANSITION_TIME = 300

const defaultValues = {
  menu: {
    dense: false,
  },
  modal: {
    type: 'modal' as const,
    visible: false,
    closeable: false,
    onClose: () => void 0,
    width: 'md' as const,
    options: {},
    rootElement: () => null,
    resolve: undefined,
    reject: undefined,
  },
  drawer: {
    visible: false,
    rootElement: null,
  },
}

export class UiStore {
  store: Store
  modal: IUiModalGeneric | IUiModalConfirm | IUiModalWizard
  menu: IUiMenu
  drawer: Partial<(typeof defaultValues)['drawer']>

  constructor(store: Store) {
    makeAutoObservable(this)

    this.store = store
    this.modal = defaultValues.modal
    this.drawer = defaultValues.drawer
    this.menu = defaultValues.menu
  }

  @computed
  get current_modal() {
    return this.modal
  }

  @computed
  get current_drawer() {
    return this.drawer
  }

  @action
  async confirm(options: IUiModalConfirm['options']) {
    this.reset(KEYS.MODAL)
    await new Promise((resolve, reject) => {
      this.set(KEYS.MODAL, {
        type: 'confirm',
        visible: true,
        closeable: true,
        width: 'md',
        resolve,
        reject: () => {
          reject()
        },
        options,
      })
    }).finally(() => this.closeModalGracefully())
  }

  @action
  async showModal(
    rootElement: IUiModalGeneric['rootElement'],
    options?: Partial<IUiModalBase>
  ) {
    this.reset(KEYS.MODAL)
    await new Promise((resolve, reject) => {
      this.set(KEYS.MODAL, {
        type: 'modal',
        visible: true,
        closeable: options?.closeable || false,
        onClose: options?.onClose ?? void 0,
        width: options?.width || 'md',
        rootElement,
        resolve,
        reject: () => {
          reject()
        },
        options: {},
      })
    }).finally(() => this.closeModalGracefully())
  }

  @action
  async closeModalGracefully() {
    this.set(KEYS.MODAL, { ...this.modal, visible: false })
    if (this.modal.onClose) this.modal.onClose()
    return new Promise(resolve => {
      setTimeout(() => {
        this.reset(KEYS.MODAL)
        resolve(true)
      }, DIALOG_TRANSITION_TIME)
    })
  }

  @action
  toggleMenuDense() {
    this.menu.dense = !this.menu.dense
  }

  @action
  async set<K extends keyof Store['ui'], A extends Store['ui'][K]>(
    key: K,
    value: A,
    save?: boolean
  ) {
    this.store.set(STORE_KEYS.UI, key, value, save)
  }

  @action
  async reset(key?: keyof typeof defaultValues) {
    if (key) {
      this.store.set(STORE_KEYS.UI, key, defaultValues[key])
      removeFromStorage(key)
    } else {
      for (const key in defaultValues) {
        this.store.set(
          STORE_KEYS.UI,
          key as keyof Store['ui'],
          (defaultValues as any)[key]
        )
      }
    }
  }
}

export default UiStore
