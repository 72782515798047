import { AcChip, AcIcon, AcTypography } from '@components'
import { ICONS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import styles from './ac-template-margin.module.scss'

export const AcTemplateMargin = observer(() => {
  const { template } = useStore()

  const currentPercentage = useMemo(() => {
    const getPercentage = (
      (template.current_products_total /
        template.current_total_budget_to_spend) *
      100
    ).toFixed(2)

    if (!getPercentage || getPercentage === 'Infinity') {
      return '0'
    }
    return getPercentage
  }, [template.current_products_total, template.current_total_budget_to_spend])

  const getChipVariant = useCallback(
    (percentage: string): 'success' | 'error' => {
      const nr = parseFloat(percentage)
      return nr > 98.99 && nr < 100.51 ? 'success' : 'error'
    },
    [currentPercentage]
  )

  //Formats the amounts to the correct string
  const getAmountString = (amount: string) => {
    //Removes the native minus before the number, since we add it before the € sign.
    const removeMinus = (number: string) => number.replace('-', '')
    return parseFloat(amount) > 0
      ? `+€${amount}`
      : parseFloat(amount) === 0
      ? `€${removeMinus(amount)}`
      : `-€${removeMinus(amount)}`
  }

  const minimumAmount = useMemo(() => {
    const total =
      template.current_total_budget_to_spend * 0.99 -
      template.current_products_total

    const roundedTotal = total.toFixed(2)
    return getAmountString(roundedTotal)
  }, [template.current_total_budget_to_spend, template.current_products_total])

  const maximumAmount = useMemo(() => {
    const total =
      template.current_total_budget_to_spend * 1.005 -
      template.current_products_total
    const roundedTotal = total.toFixed(2)

    return getAmountString(roundedTotal)
  }, [template.current_total_budget_to_spend, template.current_products_total])

  return (
    <div className={styles['ac-template-margin']}>
      <AcChip
        variant={getChipVariant(currentPercentage)}
        label={`${currentPercentage}%`}
      />
      <span className={styles['ac-template-margin-info']}>
        <AcTypography
          className={styles['ac-template-margin-info-amount']}
          size="sm"
          element="span">
          {minimumAmount}
        </AcTypography>
        <AcIcon
          icon={ICONS.RANGE}
          className={styles['ac-template-margin-info-icon']}
        />
        <AcTypography
          className={styles['ac-template-margin-info-amount']}
          size="sm"
          element="span">
          {maximumAmount}
        </AcTypography>
      </span>
    </div>
  )
})
