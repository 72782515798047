import { ClientWebSocket } from './client.websocket'
export interface IWebsocket {
  client: ClientWebSocket
}

export default () => {
  const client = new ClientWebSocket()

  return {
    client,
  }
}
