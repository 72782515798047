import { AcDataGridRow, AcIcon, AcTextInput } from '@components'
import { ICONS } from '@constants'
import { withDebounce } from '@helpers/with-debounce'
import { IDataGridFilter } from '@typings'
import { observer } from 'mobx-react-lite'
import { useId } from 'react'
import { DataGridStore } from 'src/stores/datagrid.stores'
import { AcSelect } from '../ac-select/ac-select'
import { AcSwitch } from '../ac-switch/ac-switch'
import styles from './ac-datagrid-filters.module.scss'
interface IDataGridFilterProps {
  store: DataGridStore
}

export const AcDataGridFilters = observer(({ store }: IDataGridFilterProps) => {
  if (store.filters.length === 0) return null

  const getFilterComponent = (filter: IDataGridFilter) => {
    switch (filter.type) {
      case 'text':
        return (
          <AcTextInput
            className={styles['ac-datagrid-filters--text']}
            type="search"
            name={filter.name}
            defaultValue={store.query}
            id={filter.name}
            placeholder={filter.placeholder}
            startAdornment={<AcIcon icon={ICONS.SEARCH} />}
            onInput={withDebounce(
              (e: React.ChangeEvent<HTMLInputElement>) =>
                store.setQuery(e.target.value),
              600
            )}
          />
        )

      case 'select':
        return (
          <AcSelect
            id={'ac-datagrid-filters--select' + useId()}
            className={styles['ac-datagrid-filters--select']}
            name={filter.name}
            withSearch
            options={filter.meta.options}
            defaultValue={filter.value}
            onChange={val => store.handleFilterChange(filter.name, val.value)}
            labelKey="label"
            valueKey="value"
            inputFormat="value"
            placeholder={filter.placeholder}
            withEmpty
          />
        )
      case 'boolean':
        return (
          <div className={styles['ac-datagrid-filters--boolean']}>
            <span className={styles['ac-datagrid-filters--boolean__label']}>
              {filter.label}
            </span>
            <AcSwitch
              size="md"
              checked={!!filter.value}
              loading={store.loading}
              onClick={val => store.handleFilterChange(filter.name, val)}
            />
          </div>
        )
    }
  }
  return (
    <AcDataGridRow>
      <div className={styles['ac-datagrid-filters']}>
        {store.filters.map(singleFilter => getFilterComponent(singleFilter))}
      </div>
    </AcDataGridRow>
  )
})
