import { AcCheckbox, AcIcon, AcTypography } from '@components'
import { useCallback, useMemo, useState, useContext } from 'react'

// Styles
import styles from './ac-supplier-delivery-times.module.scss'

import { DAYS, LABELS, WEEK } from '@constants'
import { ISupplierDeliveryDay } from '@typings'
import { AcFormContext } from 'src/components/core/ac-form-provider/ac-form-provider'

interface IAcSupplierDeliveryTimesProps {
  value: ISupplierDeliveryDay[]
  marketId: ID
  disabled?: boolean
  onChange: (deliveryTimes: ISupplierDeliveryDay[]) => void
}

type IAcSupplierDeliveryTimesRequiredFields = Pick<
  ISupplierDeliveryDay,
  'day' | 'for' | 'market_id'
>

export const AcSupplierDeliveryTimes = ({
  value,
  marketId,
  disabled,
  onChange,
}: IAcSupplierDeliveryTimesProps) => {
  const [deliveryTimes, setDeliveryTimes] = useState(value)
  const formContext = useContext(AcFormContext)

  const readOnly = useMemo(
    () => formContext?.readOnly || disabled,
    [disabled, formContext?.readOnly]
  )

  const daysOfTheWeek = WEEK.WORKING_WEEK.map(
    day => day.toLowerCase() as ArrayElement<ISupplierDeliveryDay['for']>
  )

  /**
   * Toggles checkbox in matrix and updates deliveryTimes
   *
   * @param {ReceivedDayObject} receivedDay
   * @param {string} clickedDeliveryDay
   */
  const toggleDeliveryDate = (
    receivedDay: IAcSupplierDeliveryTimesRequiredFields,
    clickedDeliveryDay: ArrayElement<ISupplierDeliveryDay['for']>
  ) => {
    setDeliveryTimes(deliveryTimes => {
      const newDates = [...deliveryTimes]
      const deliveryDateIndex = deliveryTimes.indexOf(
        receivedDay as ISupplierDeliveryDay
      )

      // Add customer delivery entry if `for` day does not exists
      if (~deliveryDateIndex) {
        const currentDeliveryDays = new Set(receivedDay.for)
        const addingNewDay = !currentDeliveryDays.has(clickedDeliveryDay)

        if (addingNewDay) {
          currentDeliveryDays.add(clickedDeliveryDay)
        } else {
          currentDeliveryDays.delete(clickedDeliveryDay)
        }
        newDates[deliveryDateIndex].for = [...currentDeliveryDays]
      } else {
        // Add BB delivery entry if delivery day does not exists
        newDates[deliveryTimes.length] = {
          day: receivedDay.day,
          market_id: receivedDay.market_id,
          for: [clickedDeliveryDay],
        } as ISupplierDeliveryDay
      }

      onChange(newDates)

      return newDates
    })
  }

  const renderWorkDays = useMemo(() => {
    return WEEK.WORKING_WEEK.map(day => (
      <AcTypography
        key={day}
        size="sm">
        {DAYS.NL_SHORT[day]}
      </AcTypography>
    ))
  }, [])

  const renderDaysFromSaturday = useCallback(
    (receivedDay: IAcSupplierDeliveryTimesRequiredFields) => {
      return daysOfTheWeek.map(day => {
        const checked = receivedDay.for.includes?.(day)

        if (readOnly) return <div key={day}>{checked && <AcIcon icon={'CHECK'} />}</div>

        return (
          <AcCheckbox
            key={day}
            label={day}
            checked={checked}
            onClick={() => toggleDeliveryDate(receivedDay, day)}
          />
        )
      })
    },
    [readOnly]
  )

  const renderDeliveryTimes = useMemo(() => {
    return WEEK.FROM_SATURDAY.map(weekDay => {
      const dayLabel = DAYS.NL_SHORT[weekDay]
      const receivedDay = deliveryTimes.find(
        ({ day }) => day === weekDay.toLowerCase()
      ) || {
        for: [],
        market_id: marketId,
        day: weekDay.toLowerCase() as IAcSupplierDeliveryTimesRequiredFields['day'],
      }
      return (
        <div
          key={receivedDay.day}
          className={styles['ac-supplier-delivery-times-row']}>
          <span>{dayLabel}</span>
          {renderDaysFromSaturday(receivedDay)}
        </div>
      )
    })
  }, [deliveryTimes, readOnly])

  return (
    <div className={styles['ac-supplier-delivery-times']}>
      <AcTypography
        className={styles['ac-supplier-delivery-times-label']}
        html={LABELS.DELIVERY_AT_CUSTOMER}
        size="sm"
      />
      <header className={styles['ac-supplier-delivery-times-row']}>
        <AcTypography
          className={styles['ac-supplier-delivery-times-label']}
          html={LABELS.DELIVERY_AT_BUURTBOER}
          size="sm"
        />
        {renderWorkDays}
      </header>
      {renderDeliveryTimes}
    </div>
  )
}
