import { useMemo } from 'react'
import { useStore } from './use-store'

export interface IPermissionOptions {
    match: 'any' | 'all'
}

export const usePermissions = (permissions?: string[], options?: IPermissionOptions) => {
    if (!permissions?.length) return true
    
    const { auth } = useStore()

    const isAllowed = useMemo(() => {
        if (options?.match === 'all') return permissions.every(singlePermission => auth?.employee?.permissions?.includes(singlePermission))
        return permissions.some(singlePermission => auth?.employee?.permissions?.includes(singlePermission))
    }, [auth.employee?.permissions, permissions])

    return isAllowed
}