import { ICONS, LABELS } from '@constants'
import { IDataGridPagination } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { DataGridStore } from 'src/stores/datagrid.stores'
import { AcButton, AcDataGridRow, AcTypography } from '../index.core.component'
import styles from './ac-datagrid-pagination.module.scss'

interface IAcDataGridPaginationProps {
  pagination: IDataGridPagination
  store: DataGridStore
}

export const AcDataGridPagination = observer(
  ({ pagination, store }: IAcDataGridPaginationProps) => {
    const getCurrentItemNumbers = useMemo(() => {
      const startItem =
        pagination.current_page === 1
          ? 1
          : (pagination.current_page - 1) * pagination.per_page + 1

      const endItem =
        pagination.current_page > 1 &&
        pagination.current_page < pagination.last_page
          ? startItem + pagination.per_page - 1
          : pagination.current_page === pagination.last_page
          ? pagination.total
          : pagination.per_page
      return `${startItem}-${endItem} of ${pagination.total}`
    }, [pagination.current_page, pagination.per_page, pagination.total])

    const getVisibleRange = useMemo(() => {
      const { current_page, last_page } = pagination
      const delta = 1
      const range = 4
      const left = current_page - delta
      const right = current_page + delta

      const pages = []
      const pagesWithDots = []
      let pageLength

      for (let page = 1; page <= last_page; page++) {
        // if is first page
        if (page === 1) pages.push(page)
        // if is last page
        else if (page === last_page) pages.push(page)
        // if is in range of delta (left = current - delta, right = current + delta)
        else if (page >= left && page <= right) pages.push(page)
        // if is in range (start)
        else if (current_page <= range && page <= range) pages.push(page)
        // if is in range (end (last_page - range)
        else if (current_page >= last_page - range && page >= last_page - range)
          pages.push(page)
      }

      for (const page of pages) {
        // l is undefined on first iteration, so we always show page 1
        if (pageLength) {
          // check equals
          // current indice - previous === 2 (difference between delta left and first page)
          // current indice - previous === 2 (difference between delta right and last page)
          // then we show that page as well
          if (page - pageLength === 2) {
            pagesWithDots.push(pageLength + 1)

            // otherwise we check if the difference is not exactly 2, and we show the ellipsis
          } else if (page - pageLength !== 1) {
            pagesWithDots.push('...')
          }
        }
        pagesWithDots.push(page)
        pageLength = page
      }

      return pagesWithDots
    }, [pagination.current_page, pagination.last_page])

    const getNumberRange = useMemo(() => {
      return (
        <span>
          {getVisibleRange.map(pageNumber =>
            typeof pageNumber === 'string' ? (
              <span
                key={`${pageNumber}-${Math.random()}`}
                className={styles['ac-datagrid-pagination-dots']}>
                {pageNumber}
              </span>
            ) : (
              <span
                className={`${styles['ac-datagrid-pagination-number']} ${
                  pageNumber === pagination.current_page
                    ? styles['ac-datagrid-pagination-number--active']
                    : ''
                }`}
                key={pageNumber}
                onClick={() => store.handlePagination(pageNumber)}>
                <AcTypography
                  className={`${styles['ac-datagrid-pagination-number-element']}`}
                  element="span"
                  size="xs">
                  {pageNumber.toString()}
                </AcTypography>
              </span>
            )
          )}
        </span>
      )
    }, [
      pagination.current_page,
      pagination.total,
      pagination.last_page,
      getVisibleRange,
    ])

    return (
      <AcDataGridRow className={styles['ac-datagrid-pagination']}>
        <div className={styles['ac-datagrid-pagination--left']}>
          <AcTypography size="xs">{getCurrentItemNumbers}</AcTypography>
        </div>
        <div className={styles['ac-datagrid-pagination--right']}>
          <span>
            {store.pagination && store.pagination?.current_page > 1 && (
              <AcButton
                icon={ICONS.ANGLE_LEFT}
                variant="icon"
                color="secondary"
                label={LABELS.PREVIOUS}
                onClick={() => store.handlePagination('prev')}
              />
            )}
          </span>
          <span className={styles['ac-datagrid-pagination--right-numbers']}>
            {getNumberRange}
          </span>
          <span className={styles['ac-datagrid-pagination--right-next']}>
            {store.pagination &&
              store.pagination.current_page < store.pagination.last_page && (
                <AcButton
                  label="next"
                  icon={ICONS.ANGLE_RIGHT}
                  variant="icon"
                  color="secondary"
                  onClick={() => store.handlePagination('next')}
                />
              )}
          </span>
        </div>
      </AcDataGridRow>
    )
  }
)
