import { AcCard, AcIcon, AcTypography } from '@components'
import { ICONS, ICON_ELEMENTS } from '@constants'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import styling from './ac-layered-menu-item.module.scss'

export interface IAcLayeredMenuItem {
  label: string
  icon?: keyof typeof ICON_ELEMENTS
  onClick: () => void
  children?: IAcLayeredMenuItem[]
}

interface IAcLayerMenuItemProps extends IAcLayeredMenuItem {
  handleClose?: () => void
}

export const AcLayeredMenuItem = ({
  label,
  icon,
  onClick,
  children,
  handleClose,
}: IAcLayerMenuItemProps) => {
  const [parent, setParent] = useState<HTMLDivElement | null>(null)
  const [menu, setMenu] = useState<HTMLDivElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const { styles, attributes } = usePopper(parent, menu, {
    placement: 'right-start',
  })

  const getItemClassNames = useMemo(
    () =>
      clsx(
        styling['ac-layered-menu-item'],
        icon && styling['ac-layered-menu-item--has-icon']
      ),
    [icon, onClick]
  )

  const handleParentClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    handleClose && handleClose()
    onClick()
  }

  return (
    <>
      <div
        key={label}
        ref={setParent}
        onMouseEnter={() => setMenuOpen(true)}
        onMouseLeave={() => setMenuOpen(false)}
        className={getItemClassNames}
        onClick={e => handleParentClick(e)}>
        {icon && (
          <AcIcon
            icon={icon}
            className={styling['ac-layered-menu-item-icon']}
          />
        )}
        <AcTypography
          size="sm"
          element="span"
          color="neutral-700"
          className={styling['ac-layered-menu-item-label']}>
          {label}
        </AcTypography>
        {children && (
          <AcIcon
            icon={ICONS.ANGLE_RIGHT}
            className={styling['ac-layered-menu-item-icon']}
          />
        )}
        {children && (
          <>
            {menuOpen && (
              <div
                ref={setMenu}
                style={styles.popper}
                {...attributes}>
                <AcCard
                  padding="none"
                  elevation="sm">
                  {children?.map(singleChild => (
                    <AcLayeredMenuItem
                      handleClose={handleClose}
                      {...singleChild}
                      key={singleChild.label}
                    />
                  ))}
                </AcCard>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
