import { AcAppHeader, AcButton, AcDataGrid, AcMoreButton } from '@components'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

export const AcProductList = observer(() => {
  const { products } = useStore()
  const navigate = useNavigate()

  const menuOptions = [
    {
      label: LABELS.TAGS,
      onClick: () => navigate(ROUTE_PATHS.PRODUCT_TAGS),
    },
  ]

  return (
    <>
      <AcAppHeader
        title={LABELS.PRODUCTS}
        action={
          <>
            <AcMoreButton
              menuOptions={menuOptions}
              buttonType="header"
            />
            <AcButton
              onClick={() => navigate(ROUTE_PATHS.PRODUCT_CREATE)}
              label={LABELS.NEW}
              icon={ICONS.PLUS}
            />
          </>
        }
      />
      <AcDataGrid
        store={products.data_grid}
        detailPath={ROUTE_PATHS.PRODUCT_DETAIL}
      />
    </>
  )
})
