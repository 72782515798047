import { useMemo } from 'react'
import { AcTypography } from '../index.core.component'
import styles from './ac-drop-target-container.module.scss'
import clsx from 'clsx'

export interface IAcDropTargetContainer {
  canDrop: boolean
  isOver: boolean
  label?: string
}

/* This component is used as an overlay for your targeted drop container.
The way this works is that this is essentially a transparent overlay which is absolutely positioned
within your actual drop container. To make this work, make sure your actual drop container 
(eg, the parent of this component) is positioned relative(!). */

export const AcDropTargetContainer = ({
  canDrop,
  isOver,
  label,
}: IAcDropTargetContainer) => {
  const getContainerClasses = useMemo(
    () =>
      clsx(
        styles['ac-drop-target-container'],
        isOver && styles['ac-drop-target-container--is-over'],
        canDrop && styles['ac-drop-target-container--can-drop']
      ),
    [canDrop, isOver]
  )
  return (
    <div
      className={getContainerClasses}
      style={
        canDrop ? (isOver ? { opacity: 1 } : { opacity: 0.8 }) : { opacity: 0 }
      }>
      {label && (
        <AcTypography
          color="neutral-700"
          size="xs">
          {label}
        </AcTypography>
      )}
    </div>
  )
}
