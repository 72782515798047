import clsx from 'clsx'

import styles from './ac-avatar.module.scss'

export const AcAvatar = (
  props: React.ImgHTMLAttributes<HTMLImageElement> & { text?: string }
) => {
  return (
    <img
      {...props}
      className={clsx(props.className, styles['ac-avatar'])}
      data-text={props.text}
      onError={e =>
        (e.target as HTMLImageElement).classList.add(styles['ac-avatar--error'])
      }
    />
  )
}
