import { AcCard, AcTitle } from '@components'
import styles from './ac-form-card.module.scss'
import clsx from 'clsx'

export type IAcFormCard = {
  title: string
  children: React.ReactNode
  overflow?: 'auto'
  fitContent?: boolean,
  className?: string
}

export const AcFormCard = ({
  title,
  children,
  fitContent,
  overflow,
  className,
}: IAcFormCard) => {
  return (
    <AcCard
      className={clsx(
        styles['ac-form-card'],
        styles[`ac-form-card--overflow-${overflow}`],
        fitContent && styles['ac-form-card--fit-content'],
        className && className
      )}>
      <AcTitle level={2}>{title}</AcTitle>
      {children}
    </AcCard>
  )
}
