import { useState } from 'react'
import PropTypes from 'prop-types'

// Components
import { AcButton, AcTitle } from '@components'
import AcImageUpload from './ac-image-upload'
import AcImageCrop from './ac-image-crop'

// Styles
import styles from './ac-image-input.module.scss'
import { FORM_CONSTANTS, LABELS } from '@constants'

interface Props {
  onClose: () => void
  onChange?: (file: File) => void
  setPreview: (croppedImage: string) => void
}

const AcImageEditModal: React.FC<Props> = ({
  onClose,
  onChange: onFileChange,
  setPreview,
}) => {
  const [image, setImage] = useState<null | File>(null)
  const [imageSrc, setImageSrc] = useState<string>('')

  const onChange = (file: File) => {
    const reader = new FileReader()
    reader.addEventListener('load', () =>
      setImageSrc(reader?.result?.toString() || '')
    )
    reader.readAsDataURL(file)
    setImage(file)
  }

  const selectCroppedImage = async (canvas: HTMLCanvasElement) => {
    const croppedImage = canvas.toDataURL('image/png', 1)
    setPreview(croppedImage)
    if (onFileChange && image) onFileChange(image)
    onClose()
  }

  return (
    <div className={styles['ac-image-edit-modal']}>
      <div className={styles['ac-image-edit-modal-header']}>
        <AcTitle
          className={styles['ac-image-upload-title']}
          level={2}>
          {FORM_CONSTANTS.UPLOAD_IMAGE}
        </AcTitle>
        <span>
          <AcButton
            icon="CLOSE"
            variant="icon"
            label={LABELS.CLOSE}
            onClick={onClose}
          />
        </span>
      </div>
      {!image && <AcImageUpload onChange={onChange} />}
      {image && (
        <div>
          <AcImageCrop
            src={imageSrc}
            selectCroppedImage={selectCroppedImage}
            closeModal={onClose}
          />
        </div>
      )}
    </div>
  )
}

AcImageEditModal.propTypes = {
  onChange: PropTypes.any,
  onClose: PropTypes.any,
  setPreview: PropTypes.any,
}

export default AcImageEditModal
