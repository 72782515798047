import { useEffect, useState } from 'react'
import { useStore } from './use-store'
import { ISingleUser } from '@typings'

export const useProfileRolesManagement = (profile: ISingleUser | null) => {
    const [isAllowedProfileManagement, setIsAllowedProfileManagement] = useState(false)
    const { auth } = useStore()

    const parseProfileRoles = (profile: ISingleUser | null) => {
        const contactRoles = profile?.roles_contact || []
        const employeeRoles = profile?.roles_employee || []
        return [...contactRoles, ...employeeRoles]
    }

    useEffect(() => {
        const currentProfileRoles = parseProfileRoles(profile)
        if (!currentProfileRoles?.length) {
            setIsAllowedProfileManagement(true)
            return
        }
        let currentUserPermissions = auth.employee?.permissions || []
        if (auth?.employee?.id === profile?.id) {
            const manageSelfPermissions = auth?.employee?.roles?.map(role => `user.roles.manage.${role.name}`)
            if (manageSelfPermissions) {
                const allPermissions = currentUserPermissions.concat(manageSelfPermissions)
                const duplicatesRemoved = [...new Set(allPermissions)]
                currentUserPermissions = duplicatesRemoved
            }
        }
        const allowed = currentProfileRoles.every(role => currentUserPermissions?.includes(`user.roles.manage.${role.name}`))
        setIsAllowedProfileManagement(allowed)
    }, [profile?.id])

    return { isAllowedProfileManagement }
}
