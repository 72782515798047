import {
  AcButton,
  AcForm,
  AcFormCard,
  AcFormContext,
  AcFormInput,
} from '@components'
import { KEYS, LABELS, PRICE, TAG_CATEGORIES } from '@constants'
import {
  AcIntlInputFloat,
  AcIntlInputPercentage,
} from '@helpers/ac-intl-number.helper'
import { useStore } from '@hooks/use-store'
import { IProduct } from '@typings'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useCallback, useContext, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

export type IProductInformationForm = {
  onError: () => undefined | void
}

export const ProductInformationForm = observer(
  ({ onError }: IProductInformationForm) => {
    const { products, tags } = useStore()
    const FormInput = AcFormInput<IProduct>

    const { control, setValue } = useFormContext<IProduct>()
    const { readOnly } = useContext(AcFormContext)

    const sale = useWatch({ control, name: 'sale' })
    const buy = useWatch({ control, name: 'buy' })

    const current_selected_category_id = useWatch({ name: KEYS.CATEGORY_ID })
    const current_selected_productGroup_id = useWatch({
      name: KEYS.PRODUCT_GROUP_ID,
    })

    const handleCategoryOptions = useCallback(async () =>
      // current_selected_productGroup_id: number,
      // category_id: number
      {
        //Get the option object from store
        const productGroupObj = await products.current_product_groups.find(
          singleGroup => singleGroup.id === current_selected_productGroup_id
        )

        if (!productGroupObj) return

        //If it does, update category option list filtered by BE using the productGroup ID.
        await products
          .updateCategoryList(current_selected_productGroup_id)
          .then(() => {
            const isInCurrentCategories =
              products.current_product_categories?.some(
                ({ id }) => id === current_selected_category_id
              )
            //Check if the options length === 1
            if (products.joins.product_categories.length === 1) {
              //If so, set the value of the only option for category.
              setValue(
                KEYS.CATEGORY_ID,
                products.joins.product_categories[0].id
              )
              return
            }
            if (
              //If no, check if current selected category id is in the options array
              !isInCurrentCategories
            ) {
              //if not, remove category
              setValue(KEYS.CATEGORY_ID, null)
            } else {
              //else, we do nothing with the category field.
              return
            }
          })
      }, [current_selected_productGroup_id])

    useEffect(() => {
      products.recalculateSalePrice()
    }, [buy])

    useEffect(() => {
      products.recalculateMarge()
    }, [sale, buy])

    useEffect(() => {
      if (current_selected_productGroup_id) {
        handleCategoryOptions()
      }
    }, [current_selected_productGroup_id])

    return (
      <AcForm onError={onError}>
        <AcFormCard title={LABELS.PRODUCT_INFORMATION}>
          <FormInput
            name={KEYS.NAME}
            label={LABELS.NAME}
            validate="required"
          />
          <FormInput
            name={KEYS.ARTICLE_CODE}
            label={LABELS.ARTICLECODE}
          />
          <FormInput
            name={KEYS.ID}
            label={LABELS.ID}
            readOnly
          />
          <FormInput
            name={KEYS.PRODUCT_GROUP_ID}
            outputFormat="value"
            inputFormat="value"
            label={LABELS.PRODUCTGROUP}
            type="select"
            labelKey="name"
            options={products.current_product_groups}
            valueKey="id"
          />
          <FormInput
            name={KEYS.CATEGORY_ID}
            inputFormat="value"
            outputFormat="value"
            label={LABELS.CATEGORY}
            type="select"
            labelKey="name"
            options={products.current_product_categories}
            valueKey="id"
            validate="required"
          />
          <FormInput
            name={KEYS.SIZE}
            label={LABELS.WEIGHT}
            validate="required|numeric"
            type="number"
          />
          <FormInput
            name={KEYS.SUPPLIER}
            label={LABELS.SUPPLIER}
            outputFormat="object-snake"
            type="select"
            labelKey="name"
            options={products.joins.suppliers}
            withSearch
            valueKey="id"
            validate="required"
          />
          <FormInput
            name={KEYS.INVOICE_ARTICLE}
            label={LABELS.EXACT_ARTICLE}
            outputFormat="object-snake"
            type="select"
            labelKey="name"
            options={products.joins.invoice_articles}
            valueKey="id"
            hint={LABELS.ADMINISTRATION_ONLY}
          />
          <FormInput
            name={KEYS.IMAGE}
            label={LABELS.IMAGE}
            type="image-crop"
          />
        </AcFormCard>
        <AcFormCard title={LABELS.PRICE}>
          <FormInput
            name={KEYS.MARGE}
            label={LABELS.MARGE}
            readOnly
            endAdornment={<span>(Basis marge {PRICE.PRODUCT_MARGE}%)</span>}
            transform={{ input: AcIntlInputPercentage }}
          />
          <FormInput
            name={KEYS.BUY}
            label={LABELS.BUY_PRICE}
            type="number"
            validate="required"
            startAdornment={<span style={{ width: '1.5rem' }}>€</span>}
            transform={{ input: AcIntlInputFloat }}
          />
          <FormInput
            name={KEYS.SALE}
            label={LABELS.SALE_PRICE}
            type="number"
            validate="required"
            startAdornment={<span style={{ width: '1.5rem' }}>€</span>}
            endAdornment={
              !readOnly && (
                <AcButton
                  onClick={() => products.recalculateSalePrice(true)}
                  label={LABELS.RECALCULATE}
                  padding="none"
                  variant="text"
                />
              )
            }
            transform={{ input: AcIntlInputFloat }}
          />
          <FormInput
            name={KEYS.BTW}
            outputFormat="value"
            inputFormat="value"
            label={LABELS.BTW_TARIF}
            type="select"
            labelKey="label"
            validate="required"
            options={PRICE.PRODUCT_BTW_OPTIONS}
            valueKey="value"
          />
        </AcFormCard>
        <AcFormCard title={LABELS.TAGS}>
          <FormInput
            name={'tags_a'}
            defaultValue={products.current_product_tags_diet}
            label={TAG_CATEGORIES[0].label}
            type="multi-select"
            labelKey="name"
            options={tags.current_diet_tags || []}
            valueKey="id"
          />
          <FormInput
            name={'tags_b'}
            defaultValue={products.current_product_tags_allergies}
            label={TAG_CATEGORIES[1].label}
            type="multi-select"
            labelKey="name"
            options={tags.current_allergy_tags || []}
            valueKey="id"
          />
          <FormInput
            name={'tags_c'}
            defaultValue={products.current_product_tags_facility}
            label={TAG_CATEGORIES[2].label}
            type="multi-select"
            labelKey="name"
            options={tags.current_facility_tags || []}
            valueKey="id"
          />
        </AcFormCard>
        <AcFormCard title={LABELS.DATES}>
          <FormInput
            name={KEYS.CREATED_AT}
            label={LABELS.CREATED}
            readOnly
            transform={{
              input: value =>
                value ? dayjs(value as string).format('DD-MM-YYYY HH:mm') : '',
            }}
          />
          <FormInput
            name={KEYS.UPDATED_AT}
            label={LABELS.LAST_UPDATED}
            readOnly
            transform={{
              input: value =>
                value ? dayjs(value as string).format('DD-MM-YYYY HH:mm') : '',
            }}
          />
        </AcFormCard>
      </AcForm>
    )
  }
)
