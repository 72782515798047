import { render } from 'preact'
import 'preact/debug'
import React from 'react'

import '@styles/index.styles.scss'

import reportWebVitals from '@helpers/report-web-vitals.helpers'

import { AcModal, AcRouter, AcToastContainer } from '@components'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const root = document.getElementById('root') as HTMLElement

render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <AcRouter />
      <AcModal />
      <AcToastContainer />
    </DndProvider>
  </React.StrictMode>,
  root
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
