import { DATAGRID_ENDPOINTS, KEYS, LABELS } from '@constants'
import AcDownloadFile from '@helpers/ac-download-file'
import { removeItemFromArray } from '@helpers/ac-remove-from-array'
import { saveToStorage } from '@helpers/browser-storage.helpers'
import { isEmptyObject } from '@helpers/is-empty-object'
import { Store } from '@stores'
import {
  IDataGridAction,
  IDataGridDataItem,
  IDataGridField,
  IDataGridFilter,
  IDataGridPagination,
} from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'

const initialValues = {
  data: [],
  fields: [],
  filters: [],
  actions: [],
  loading: true,
  isBusy: [],
  query: '',
  activeFilters: {},
  activeSort: {},
  allSelected: false,
  pagination: null,
  selectedValues: [],
}
export class DataGridStore {
  [key: string]: any
  _store: Store
  fields: IDataGridField[]
  data: IDataGridDataItem[]
  filters: IDataGridFilter[]
  actions: IDataGridAction[]
  loading: boolean
  query: string
  isBusy: IDataGridDataItem['resource_key'][]
  activeFilters: {
    [key: IDataGridFilter['name']]: IDataGridFilter['value'] | number
  }
  activeSort: {
    [key: string]: IDataGridField['field']
  }
  pagination: Nullable<IDataGridPagination>
  allSelected: boolean
  storeKey: string
  selectedValues: IDataGridDataItem['resource_key'][]

  constructor(globalStore: Store, storeKey: string) {
    makeAutoObservable(this)
    this._store = globalStore
    this.storeKey = storeKey
    this.data = initialValues.data
    this.fields = initialValues.fields
    this.filters = initialValues.filters
    this.actions = initialValues.actions
    this.loading = initialValues.loading
    this.isBusy = initialValues.isBusy
    this.query = initialValues.query
    this.activeFilters = initialValues.activeFilters
    this.activeSort = initialValues.activeSort
    this.pagination = initialValues.pagination
    this.allSelected = initialValues.allSelected
    this.selectedValues = initialValues.selectedValues
  }

  @computed
  get current_fields(): IDataGridField[] {
    return this.fields ?? []
  }

  @computed
  get current_isBusy(): IDataGridDataItem['resource_key'][] {
    return this.isBusy
  }

  @computed
  get total_items(): number {
    return this.pagination?.total || 0
  }

  @computed
  get current_selection_length(): number {
    return this.allSelected ? this.total_items : this.selectedValues.length
  }

  @computed
  get has_selection(): boolean {
    return !!this.selectedValues.length
  }

  @computed
  get has_actions(): boolean {
    return !!this.actions.length
  }

  @computed
  get has_filters(): boolean {
    return !isEmptyObject(this.activeFilters)
  }

  @computed
  get has_sorting(): boolean {
    return !isEmptyObject(this.activeSort)
  }

  @computed
  get current_params() {
    let params = {}
    if (this.has_filters) {
      const filters = Object.keys(this.activeFilters).reduce(
        (bulk, current) => ({
          ...bulk,
          [current]: this.activeFilters[current],
        }),
        {}
      )
      params = { ...params, ...filters }
    }

    if (this.has_sorting) {
      params = { ...params, ...this.activeSort }
    }

    if (this.selectedValues) {
      params = this.allSelected
        ? { ...params, selection: KEYS.ALL }
        : { ...params, selection: this.selectedValues }
    }

    return params
  }

  @action
  getData = async () => {
    this.set(KEYS.LOADING, true)
    try {
      const params = this.current_params

      console.log(this.storeKey)
      
      const { fields, data, filters, actions, ...rest } =
        await this._store.api.datagrid.getAll(
          DATAGRID_ENDPOINTS[this.storeKey],
          params
        )

      const _fields = fields.filter(
        ({ field }) => field !== 'id-check' && field !== 'actions'
      )
      this.set(KEYS.FIELDS, _fields)
      this.set(KEYS.DATA, data)
      this.set(KEYS.FILTERS, filters)
      this.set(KEYS.ACTIONS, actions)
      this.set(KEYS.PAGINATION, { ...rest })
    } catch (e) {
      console.error(e)
    } finally {
      this.set(KEYS.LOADING, false)
    }
  }

  @action
  handleSelectionAction = async (action: IDataGridAction) => {
    this.handleIsBusy(this.selectedValues, KEYS.ADD)
    try {
      if (action.confirmation !== null) {
        await this._store.ui.confirm({
          title: action.confirmation.title,
          content: action.confirmation.body,
          confirmLabel: action.confirmation.confirm,
        })
      }
      const res = await this._store.api.datagrid.handleAction(
        DATAGRID_ENDPOINTS[this.storeKey],
        action.key,
        action.type,
        this.current_params
      )

      if (action.type === 'response') {
        if (res.status === 200) {
          await this._store.ui.confirm({
            title: res.data.data.title,
            content: res.data.data.body,
            confirmLabel: res.data.data.confirm,
            withCancel: false,
          })
        }
        this.handleSelection('none')
        this.getData()
      } else {
        const filename =
          res.headers['content-disposition'].split('filename=')[1]
        const mimeType = res.headers['content-type']
        AcDownloadFile(res.data, filename, mimeType)
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.handleIsBusy(this.selectedValues, KEYS.REMOVE)
    }
  }

  @action
  handleCallBack = async (
    resourceKey: IDataGridDataItem['resource_key'],
    callBack: IDataGridField['callback']
  ) => {
    this.handleIsBusy(resourceKey, KEYS.ADD)
    const { url, method } = callBack
    const requestUrl = url.replace(':resource_key:', `${resourceKey}`)
    try {
      await this._store.api.datagrid.handleCallBack(requestUrl, method)
      await this.getData()
    } catch (e: any) {
      this._store.ui.confirm({
        title: LABELS.ATTENTION,
        content: e.response.data.message,
        confirmLabel: LABELS.CLOSE,
        withCancel: false,
      })
      console.error(e)
    } finally {
      this.handleIsBusy(resourceKey, KEYS.REMOVE)
    }
  }

  @action
  handlePagination = (direction: 'prev' | 'next' | 'reset' | number) => {
    if (this.pagination) {
      //Reset current selection when navigating
      this.handleSelection(KEYS.NONE)

      if (direction === KEYS.PREVIOUS && this.pagination.current_page > 1)
        return this.handleFilterChange(
          KEYS.PAGE,
          this.pagination.current_page - 1
        )
      if (
        direction === KEYS.NEXT &&
        this.pagination?.last_page > this.pagination?.current_page
      ) {
        return this.handleFilterChange(
          KEYS.PAGE,
          this.pagination.current_page + 1
        )
      }
      if (direction === KEYS.RESET) return this.handleFilterChange(KEYS.PAGE, 1)

      return this.handleFilterChange(KEYS.PAGE, direction)
    }
  }

  @action
  handleFilterChange = (key: IDataGridField['field'], value: any) => {
    if (
      key !== KEYS.PAGE &&
      !!this.activeFilters.page &&
      this.activeFilters[key] !== value
    ) {
      this.activeFilters = {
        ...this.activeFilters,
        page: 1,
      }
    }
    this.handleSelection(KEYS.NONE)
    const newFilter = { ...this.activeFilters, [key]: value }
    this.set(KEYS.ACTIVE_FILTERS, newFilter)
  }

  @action
  setQuery = (newValue: string) => {
    this.handleSelection(KEYS.NONE)
    this.handleFilterChange(KEYS.SEARCH, newValue)
    this.set('query', newValue)
  }

  @action
  handleSortChange = ({ sort_key: sort_by }: IDataGridField) => {
    const getSortDirection = (sortKey: IDataGridField['sort_key']) => {
      let direction = this.activeSort.direction ?? KEYS.ASCENDING

      if (
        this.activeSort.sort_by === sortKey &&
        this.activeSort.sort_direction === direction
      ) {
        direction = KEYS.DESCENDING
      }
      return direction
    }
    this.handleSelection(KEYS.NONE)
    const sort_direction = getSortDirection(sort_by)
    this.set(KEYS.ACTIVE_SORT, { sort_by, sort_direction })
  }

  @action
  handleSelection = (
    value: IDataGridDataItem['resource_key'] | 'all' | 'allPage' | 'none'
  ) => {
    if (value === KEYS.ALL_PAGE) {
      const keys = this.data.reduce(
        (bulk: number[], curr) => [...bulk, curr.resource_key],
        []
      )
      this.set(KEYS.SELECTED_VALUES, keys)
    } else if (value === KEYS.NONE) {
      this.set(KEYS.ALL_SELECTED, false)
      this.set(KEYS.SELECTED_VALUES, [])
    } else if (value === KEYS.ALL) {
      this.set(KEYS.ALL_SELECTED, true)
      this.handleSelection(KEYS.ALL_PAGE)
    } else {
      const newSelection = this.selectedValues.includes(value)
        ? this.selectedValues.filter(item => item !== value)
        : [...this.selectedValues, value]
      this.set(KEYS.ALL_SELECTED, false)
      this.set(KEYS.SELECTED_VALUES, newSelection)
    }
  }

  @action
  handleIsBusy = (
    item:
      | IDataGridDataItem['resource_key']
      | IDataGridDataItem['resource_key'][],
    method: 'add' | 'remove'
  ) => {
    const itemIsNumber = typeof item === 'number'
    const methodIsAdd = method === KEYS.ADD
    let newArray = [...this.isBusy]

    if (itemIsNumber) {
      if (methodIsAdd) {
        newArray = [...newArray, item]
      } else {
        newArray = removeItemFromArray(newArray, item)
      }
    } else {
      if (methodIsAdd) {
        newArray = newArray.concat(item)
      } else {
        newArray = newArray.filter(singleItem => !item.includes(singleItem))
      }
    }

    this.set(KEYS.IS_BUSY, newArray)
  }

  @action
  reset = () => {
    for (const key in initialValues) {
      this.set(key, initialValues[key as keyof typeof initialValues])
    }
  }

  @action
  set = (key: string, value: unknown, save?: boolean): void => {
    if (!key) return
    this[key] = value
    if (save) saveToStorage(key as string, value)
  }
}
