import { AcGlobalSearchCategory } from '@components'
import { LABELS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'

export const GlobalSearchAllTab = observer(() => {
  const { global_search } = useStore()
  return (
    <>
      <AcGlobalSearchCategory
        dense
        categoryName={LABELS.CUSTOMERS}
        items={global_search.results?.customer}
        path={ROUTE_PATHS.CUSTOMER_DETAIL}
        icon="USER_ALT"
      />
      <AcGlobalSearchCategory
        dense
        categoryName={LABELS.TEMPLATES}
        items={global_search.results?.template}
        path={ROUTE_PATHS.TEMPLATE_DETAIL}
        icon="CLIPBOARD_LIST"
      />
      {/* <AcGlobalSearchCategory
          categoryName={LABELS.DELIVERIES}
          icon="TRUCK"
        /> */}
    </>
  )
})
