import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'

import {
  AcAvatar,
  AcButton,
  AcIcon,
  AcTemplateTodoFinished,
  AcTextInput,
  AcTitle,
  AcTypography,
} from '@components'
import {
  DYNAMIC_LABELS,
  ICONS,
  LABELS,
  TEMPLATE_TODO_STATES,
  TEMPLATE_TODO_TYPES,
} from '@constants'
import { AcIntlWeekday } from '@helpers/ac-intl-datetime.helper'
import { useStore } from '@hooks/use-store'

import styles from './ac-template-todo-comment.module.scss'

export const AcTemplateTodoComment = observer(() => {
  const { template } = useStore()
  const [replyValue, setReplyValue] = useState('')
  const [replyInputVisible, setReplyInputVisible] = useState(false)

  const handleReplyChange = ({ target }: { target: HTMLTextAreaElement }) => {
    setReplyValue(target.value)
  }

  const handleFinishButtonClick = () =>
    template.completeTodo(TEMPLATE_TODO_TYPES.COMMENT)

  const handleReplyButtonClick = async () => {
    setReplyInputVisible(false)
    await template.handleTodoCommentReply(replyValue).catch(() => {
      setReplyInputVisible(true)
    })
  }

  const commentData = useMemo(
    () => template.current_todo_comment?.data,
    [template.current_todo_comment?.data]
  )

  const commentDone = useMemo(
    () => template.current_todo_comment?.state === TEMPLATE_TODO_STATES.DONE,
    [template.current_todo_comment?.state]
  )

  const commentReplyable = useMemo(
    () => !commentData?.reply && !commentDone,
    [commentData?.reply, commentDone]
  )

  const renderCommentDateTime = useMemo(
    () =>
      commentData?.updated_at
        ? DYNAMIC_LABELS.TEMPLATE_TODO_COMMENT_DATETIME(
            template.current_week,
            dayjs(commentData?.updated_at).format('HH:MM'),
            AcIntlWeekday(new Date(commentData?.updated_at)),
            dayjs(commentData?.updated_at).format('DD-MM-YYYY'),
            commentData?.contact?.name_full
          )
        : null,
    [
      template.info.week.week,
      commentData?.updated_at,
      commentData?.contact?.name_full,
    ]
  )

  const renderReplyDateTime = useMemo(
    () => dayjs(commentData?.reply?.updated_at).format('DD-MM-YY - HH:MM:ss'),
    [commentData?.reply?.updated_at]
  )

  const renderEmpty = useMemo(
    () => (
      <div className={styles['ac-template-todo-comment-empty']}>
        <AcIcon
          className={styles['ac-template-todo-comment-empty-icon']}
          icon={ICONS.NO_CHAT}
          size="xl"
        />
        <AcTitle
          level={3}
          className={styles['ac-template-todo-comment-empty-title']}>
          {LABELS.NO_COMMENT_AVAILABLE}
        </AcTitle>
      </div>
    ),
    []
  )

  const renderReplyContent = useMemo(() => {
    if (!commentData?.reply) return null

    return (
      <div className={styles['ac-template-todo-comment-reply']}>
        <div className={styles['ac-template-todo-comment-reply-heading']}>
          <div
            className={styles['ac-template-todo-comment-reply-heading-meta']}>
            <AcTypography
              element="span"
              size="sm"
              weight="semibold">
              {commentData.reply.author?.full_name}
            </AcTypography>
            <AcTypography
              element="span"
              size="xs"
              color="neutral-700">
              {renderReplyDateTime}
            </AcTypography>
          </div>
        </div>
        <AcTypography
          className={styles['ac-template-todo-comment-reply-content']}
          size="sm"
          color="neutral-700">
          {commentData.reply?.content}
        </AcTypography>
      </div>
    )
  }, [commentData?.reply])

  const renderReplyInput = useMemo(() => {
    if (commentData?.reply || !replyInputVisible) return null

    return (
      <AcTextInput
        className={styles['ac-template-todo-comment-reply-input']}
        placeholder={LABELS.LEAVE_REACTION}
        type="textarea"
        id="reply"
        name="reply"
        value={replyValue}
        onChange={handleReplyChange}
      />
    )
  }, [commentData?.reply, replyInputVisible])

  if (!commentData?.comment) return renderEmpty

  return (
    <div>
      <AcTypography
        size="sm"
        color="neutral-700"
        element="span">
        {renderCommentDateTime}
      </AcTypography>
      <AcTypography
        className={styles['ac-template-todo-comment-content']}
        size="sm"
        color="neutral-700">
        {commentData?.comment}
      </AcTypography>
      {renderReplyContent}
      {renderReplyInput}
      {template.user_can_edit && (
        <div className={styles['ac-template-todo-comment-button-wrapper']}>
          {!replyInputVisible && commentReplyable && (
            <AcButton
              label={LABELS.REPLY}
              variant="text"
              color="neutral-700"
              onClick={() => setReplyInputVisible(true)}
              withToolTip={false}
              padding="none"
            />
          )}
          {replyInputVisible && (
            <>
              <AcButton
                label={LABELS.CANCEL}
                variant="text"
                color="neutral-700"
                onClick={() => setReplyInputVisible(false)}
                withToolTip={false}
                padding="none"
              />
              <AcButton
                label={LABELS.SEND}
                variant="text"
                color="primary"
                onClick={handleReplyButtonClick}
                withToolTip={false}
                padding="none"
              />
            </>
          )}
          {!replyInputVisible && commentReplyable && (
            <AcButton
              label={LABELS.FINISH_TASK}
              variant="text"
              color="primary"
              onClick={handleFinishButtonClick}
              withToolTip={false}
              padding="none"
            />
          )}
          {commentDone && <AcTemplateTodoFinished />}
        </div>
      )}
    </div>
  )
})
