import clsx from 'clsx'
import { useMemo } from 'react'
import styles from './ac-loader.module.scss'

interface IAcLoader {
  variant?: 'primary' | 'secondary'
}

export const AcLoader = ({ variant = 'primary' }: IAcLoader) => {
  const getLoaderClassNames = useMemo(
    () => clsx(styles['ac-loader'], styles[`ac-loader--${variant}`]),
    [variant]
  )
  return (
    <div className={styles['ac-loader-wrapper']}>
      <div className={getLoaderClassNames}>
        <span className={styles['ac-loader-dot']} />
        <span className={styles['ac-loader-dot']} />
        <span className={styles['ac-loader-dot']} />
      </div>
    </div>
  )
}
