import { LABELS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { AcGlobalSearchCategory } from '@components'
import { observer } from 'mobx-react-lite'

export const GlobalSearchTemplatesTab = observer(() => {
  const { global_search } = useStore()
  return (
    <AcGlobalSearchCategory
      categoryName={LABELS.TEMPLATES}
      path={ROUTE_PATHS.TEMPLATE_DETAIL}
      items={global_search.results?.template}
      icon="CLIPBOARD_LIST"
    />
  )
})
