import { AcIcon, AcTypography } from '@components'
import { ICON_ELEMENTS, ROUTE_PATHS } from '@constants'
import { useMemo } from 'react'
import styles from './ac-global-search.module.scss'

type IAcGlobalSearchCategory = {
  categoryName: string
  items?: {
    id: ID
    title: string
    subtitle: string
    type: string
  }[]
  icon: keyof typeof ICON_ELEMENTS
  dense?: boolean
  path: (typeof ROUTE_PATHS)[keyof typeof ROUTE_PATHS]
}

export const AcGlobalSearchCategory = ({
  categoryName,
  items,
  icon,
  dense,
  path,
}: IAcGlobalSearchCategory) => {
  const renderItems = useMemo(() => {
    if (!items?.length) return null

    const itemElements = []
    for (const item of items) {
      if (dense && itemElements.length >= 3) break

      itemElements.push(
        <li key={item.id}>
          <a href={path.replace(':id', item.id.toString())}>
            <AcIcon icon={icon} />
            <div className={styles['ac-global-search-results-title']}>
              <AcTypography
                html={item.title}
                size="sm"
                weight="semibold"
                element="span"
              />
              <AcTypography
                html={item.subtitle}
                size="sm"
                weight="light"
                element="span"
              />
            </div>
          </a>
        </li>
      )
    }
    return itemElements
  }, [items, dense])

  if (!items?.length) return null

  return (
    <fieldset className={styles['ac-global-search-results-category']}>
      <AcTypography
        html={categoryName}
        element="legend"
        size="xs"
        weight="semibold"
      />
      <ul>{renderItems}</ul>
    </fieldset>
  )
}
