import { useMemo, useState } from 'react'

import { AcButton, AcSidebar, AcTabs, AcTemplateBibiInfo } from '@components'
import { DYNAMIC_LABELS, ICONS, KEYS, LABELS } from '@constants'

import { useStore } from '@hooks/use-store'
import { TemplateSidebarTabs } from '@partials/tabs/index.tabs'
import { observer } from 'mobx-react-lite'
import styles from './ac-template-sidebar.module.scss'

interface IAcTemplateSidebar {
  open: boolean
  onClose: () => void
}

export const AcTemplateSidebar = observer(
  ({ open, onClose }: IAcTemplateSidebar) => {
    const { template } = useStore()
    const [showSubmit, setShowSubmit] = useState<boolean>()

    const renderTabs = useMemo(() => {
      return [
        {
          id: KEYS.TODO,
          label: LABELS.TODO_LIST,
          component: TemplateSidebarTabs.TemplateSidebarTodoTab,
          disabled: false,
        },
        {
          id: KEYS.CUSTOMER_PROFILE,
          label: LABELS.CUSTOMER_PROFILE,
          component: TemplateSidebarTabs.TemplateSideBarClientProfile,
          disabled: false,
          badge: template.show_profile_updated,
        },
      ]
    }, [template.show_profile_updated])

    return (
      <AcSidebar open={open}>
        <div className={styles['ac-template-sidebar-wrapper']}>
          <AcTabs
            onTabChange={newTab => setShowSubmit(newTab === KEYS.TODO)}
            className={styles['ac-template-sidebar-tabs']}
            tabs={renderTabs}
            borderless
          />
          <div className={styles['ac-template-sidebar-close-button']}>
            <AcButton
              label={LABELS.CLOSE}
              icon={ICONS.CLOSE}
              withToolTip={false}
              variant="icon"
              padding="md"
              onClick={onClose}
            />
          </div>
          {template.user_can_edit && showSubmit && (
            <div className={styles['ac-template-sidebar-bottom-container']}>
              <AcTemplateBibiInfo />
              <AcButton
                label={DYNAMIC_LABELS.TEMPLATE_FINISH_WEEK_N(
                  template.current_week
                )}
                withToolTip={false}
                fullWidth
                onClick={template.getTemplateValidate}
              />
            </div>
          )}
        </div>
      </AcSidebar>
    )
  }
)
