import { useStore } from '@hooks/use-store'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { AcModalConfirm } from './ac-modal-confirm'

import styles from './ac-modal.module.scss'

const DIALOG_TRANSITION_TIME = 300

export const AcModal = observer(() => {
  const dialogElement = useRef<HTMLDialogElement>(null)
  const { ui } = useStore()
  const Element = useMemo(
    () => ui.current_modal.rootElement,
    [ui.current_modal.rootElement]
  )
  const dialogMouseMeta = useRef({ delta: 10, startX: 0, startY: 0 })

  const handleClose = useCallback(() => {
    if (ui.current_modal.onClose) {
      ui.current_modal.onClose()
    }
  }, [ui.current_modal])

  const closeModalGracefully = () => {
    dialogElement.current?.classList.add(styles['ac-modal--animate-out'])

    setTimeout(() => {
      handleClose()
      dialogElement.current?.close()
      dialogElement.current?.classList.remove(styles['ac-modal--animate-out'])
    }, DIALOG_TRANSITION_TIME)
  }

  useEffect(() => {
    if (ui.current_modal.visible) dialogElement.current?.showModal()
    else closeModalGracefully()
  }, [ui.current_modal.visible])

  useEffect(() => {
    const closeByEscape = (event: Event) => {
      event.preventDefault()
      if (ui.current_modal.closeable) {
        handleClose()
        ui.current_modal.reject?.()
      }
    }
    const closeByBackdrop = (event: MouseEvent) => {
      if ((event.target as HTMLElement)?.tagName === 'DIALOG') {
        handleClose()
        ui.current_modal.closeable && ui.current_modal.reject?.()
      }
    }

    // Check if mouse does not drag(click) to prevent dialog from accidentally closing
    const backdropMouseDown = (event: MouseEvent) => {
      dialogMouseMeta.current.startX = event.pageX
      dialogMouseMeta.current.startY = event.pageY
    }

    const backdropMouseUp = (event: MouseEvent) => {
      const diffX = Math.abs(event.pageX - dialogMouseMeta.current.startX)
      const diffY = Math.abs(event.pageY - dialogMouseMeta.current.startY)

      if (
        diffX < dialogMouseMeta.current.delta &&
        diffY < dialogMouseMeta.current.delta
      ) {
        closeByBackdrop(event)
      }
    }

    dialogElement.current?.addEventListener('mousedown', backdropMouseDown)
    dialogElement.current?.addEventListener('mouseup', backdropMouseUp)
    dialogElement.current?.addEventListener('cancel', closeByEscape)

    return () => {
      dialogElement.current?.removeEventListener('mousedown', backdropMouseDown)
      dialogElement.current?.removeEventListener('mouseup', backdropMouseUp)
      dialogElement.current?.removeEventListener('cancel', closeByEscape)
    }
  }, [ui.current_modal.reject, ui.current_modal.closeable, dialogElement])

  const GenericModal = useCallback(
    (props: typeof ui.current_modal.options) =>
      Element ? <Element {...props} /> : null,
    [Element]
  )

  return (
    <dialog
      ref={dialogElement}
      className={clsx(
        styles['ac-modal'],
        styles[`ac-modal--width-${ui.current_modal.width}`]
      )}>
      <div className={clsx(styles['ac-modal-wrapper'])}>
        {ui.current_modal.type === 'modal' && (
          <GenericModal {...ui.current_modal.options} />
        )}
        {ui.current_modal.type === 'confirm' && (
          <AcModalConfirm {...ui.current_modal.options} />
        )}
      </div>
    </dialog>
  )
})
