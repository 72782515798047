import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { ISingleTag } from '@typings'
import { AxiosResponse } from 'axios'

export class TagsApi extends AxiosBaseApi {
  get_list = () =>
    this.api
      .get<AxiosResponse<{ data: ISingleTag[] }>>(ENDPOINTS.TAGS.LIST(), {
        params: { per_page: 9999999 },
      })
      .then(response => response.data.data)

  get_single = (id: ISingleTag['id']) =>
    this.api
      .get<AxiosResponse<{ data: ISingleTag }>>(ENDPOINTS.TAGS.SINGLE(id))
      .then(res => res.data.data)

  update = (
    id: ISingleTag['id'],
    data: {
      name: ISingleTag['name']
      category: ISingleTag['category']
    }
  ) => this.api.put(ENDPOINTS.TAGS.SINGLE(id), data).then(res => res)

  create = (data: {
    name: ISingleTag['name']
    category: ISingleTag['category']
  }) => this.api.post(ENDPOINTS.TAGS.LIST(), data).then(res => res)
}
