import { ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

interface IAcRequireAuthProps {
  children: JSX.Element
  allowed: string[]
}

export const AcRequireAuth = observer(({ children, allowed }: IAcRequireAuthProps) => {
  const { auth } = useStore()
  const location = useLocation()
  const navigate = useNavigate()

  if (!auth.is_authorized) {
    auth.reset()
    return (
      <Navigate
        to={ROUTE_PATHS.LOGIN}
        state={{ intended: location.pathname }}
      />
    )
  }

  const userIsAllowed = allowed.every(singlePermission => auth?.employee?.permissions?.includes(singlePermission))

  if (!userIsAllowed) {
    navigate(-1)
    return null
  }

  return children
})
