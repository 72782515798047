import { ReactNode } from 'react'
import { AcCard, AcTitle } from '@components'

interface IAcDialogProps {
  children: ReactNode
  title?: string
  className?: string
}

export const AcDialog = ({ children, title, ...rest }: IAcDialogProps) => {
  return (
    <AcCard
      {...rest}
      padding={32}
      elevation="lg">
      {title && <AcTitle level={2}>{title}</AcTitle>}
      {children}
    </AcCard>
  )
}
