import { IUiModalConfirm } from '@typings'
import {
  AcButton,
  AcCard,
  AcTitle,
  AcTypography,
} from '../index.core.component'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import styles from './ac-modal.module.scss'

export const AcModalConfirm = ({
  title,
  content,
  confirmLabel = LABELS.CONTINUE,
  cancelLabel = LABELS.CANCEL,
  withCancel = true,
}: IUiModalConfirm['options']) => {
  const store = useStore()
  const onConfirm = store.ui.modal.resolve
  const onCancel = store.ui.modal.reject
  return (
    <AcCard
      padding={32}
      elevation="lg">
      <AcTitle level={2}>{title}</AcTitle>
      <AcTypography
        size="sm"
        html={content}
      />
      <div className={styles['ac-modal-confirm-actions']}>
        {withCancel && (
          <AcButton
            label={cancelLabel}
            color="secondary"
            onClick={onCancel}
          />
        )}
        <AcButton
          label={confirmLabel}
          onClick={onConfirm}
        />
      </div>
    </AcCard>
  )
}
