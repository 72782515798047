import {
  IProduct,
  IProductCategory,
  IProductGroup,
  IProductInvoiceArticle,
  IProductSitesResponse,
  ISingleTag,
} from '@typings'
import axios from 'axios'
import { action, computed, makeAutoObservable } from 'mobx'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { PRICE } from './../constants/price.constants'
import { ISupplierCompact } from './../typings/supplier.typings.d'

import { KEYS, LABELS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { DataGridStore } from './datagrid.stores'

import { AcFormDataManipulator } from '@helpers/ac-form-data-manipulator.helper'
import dayjs from 'dayjs'

const handleMultiSelectData = (values: IProduct) => {
  const { tags_a, tags_b, tags_c, tags, weeks, ...rest } = values
  const combinedTags: ISingleTag[] = []
  if (tags_a) combinedTags.push(...tags_a)
  if (tags_b) combinedTags.push(...tags_b)
  if (tags_c) combinedTags.push(...tags_c)

  const _values = rest
  Object.assign(_values, { tags: combinedTags.map(({ id }) => id) })
  Object.assign(_values, {
    weeks: weeks.map(({ value }: never) => value),
  })

  return _values as IProduct
}

const manipulateProduct = (values: IProduct) => {
  const formatted = handleMultiSelectData(values)
  const { data } = new AcFormDataManipulator(formatted)
    .deleteInvalidEntry('image', File)
    .deleteInvalidEntry(['invoice_article_id', 'product_group_id'], Number)
    .filterIncompleteObjects('locations', ['site_id'])
  return data
}

export class ProductStore {
  _store: Store

  loading: boolean
  product: IProduct
  products: IProduct[]
  data_grid: DataGridStore
  form?: UseFormReturn<IProduct>
  product_tags: ISingleTag[]
  joins: {
    product_groups: IProductGroup[]
    product_categories: IProductCategory[]
    invoice_articles: IProductInvoiceArticle[]
    suppliers: ISupplierCompact[]
    colors: IProductSitesResponse['colors']
    sites: IProductSitesResponse['data']
  }

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.loading = false
    this.data_grid = new DataGridStore(this._store, STORE_KEYS.PRODUCTS)
    this.product = {} as IProduct
    this.products = []
    this.product_tags = [] as ISingleTag[]
    this.form = undefined
    this.joins = {
      product_groups: [],
      product_categories: [],
      invoice_articles: [],
      suppliers: [],
      colors: [],
      sites: [],
    }
  }

  @computed
  get current_product_category_in_options(): boolean {
    return (
      !!this.product &&
      !!this.product.category_id &&
      this.current_product_categories.some(
        ({ id }) => this.product.category_id === id
      )
    )
  }

  @computed
  get current_product_weeks(): object[] {
    return this.product.weeks
  }

  @computed
  get current_product_weeks_objects(): object[] {
    const current = dayjs()
    return this.product.weeks?.map((week: string) => {
      const data = week.split('-')
      return {
        label: `Week ${data[0]}${
          parseInt(data[1]) > current.isoWeekYear() ? ' - ' + data[1] : ''
        }`,
        value: week,
      }
    })
  }

  @computed
  get current_product_groups(): IProductGroup[] {
    return this.joins.product_groups
  }

  @computed
  get current_product_categories(): IProductCategory[] {
    return this.joins.product_categories
  }

  @computed
  get current_archived(): boolean {
    return this.product.archived
  }

  @computed
  get current_active(): boolean {
    return !!this.product.active
  }

  @computed
  get current_product_tags_diet(): ISingleTag[] {
    return this.product_tags.filter(
      ({ category }) => category === 'Dieetvoorkeuren'
    )
  }

  @computed
  get current_product_tags_allergies(): ISingleTag[] {
    return this.product_tags.filter(({ category }) => category === 'Allergieën')
  }

  @computed
  get current_product_tags_facility(): ISingleTag[] {
    return this.product_tags.filter(
      ({ category }) => category === 'Faciliteiten'
    )
  }

  getList = action(async () => {
    this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, true)

    try {
      const res = await this._store.api.products.get_list()
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.PRODUCTS, res)
    } catch (e) {
      console.error(e)
    } finally {
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, false)
    }
  })

  getById = action(async (id: number) => {
    this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, true)

    try {
      const res = await this._store.api.products.get_single(id)
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.PRODUCT, res)
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.PRODUCT_TAGS, res.tags)
      if (res.product_group_id) this.updateCategoryList(res.product_group_id)
      return res
    } catch (e) {
      console.error(e)
    } finally {
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, false)
    }
  })

  @action
  updateCategoryList = async (
    product_group_id: IProductGroup['category_id']
  ) => {
    return await this._store.api.products
      .get_selectable_category_list({
        per_page: 999,
        product_group_id,
      })
      .then(product_categories => {
        this._store.set(STORE_KEYS.PRODUCTS, KEYS.JOINS, {
          ...this.joins,
          product_categories,
        })
        return Promise.resolve(product_categories)
      })
  }

  @action
  getJoins = async () => {
    // @todo make one Promise.all

    this._store.api.products
      .get_group_list({ per_page: 999 })
      .then(product_groups =>
        this._store.set(STORE_KEYS.PRODUCTS, KEYS.JOINS, {
          ...this.joins,
          product_groups,
        })
      )
      .catch(console.error)

    this._store.api.products
      .get_selectable_category_list({ per_page: 999 })
      .then(product_categories =>
        this._store.set(STORE_KEYS.PRODUCTS, KEYS.JOINS, {
          ...this.joins,
          product_categories,
        })
      )
      .catch(console.error)

    this._store.api.invoices
      .get_article_list({ per_page: 999 })
      .then(invoice_articles => {
        this._store.set(STORE_KEYS.PRODUCTS, KEYS.JOINS, {
          ...this.joins,
          invoice_articles,
        })
      })
      .catch(console.error)

    this._store.api.suppliers
      .get_list({ per_page: 999 })
      .then(suppliers => {
        this._store.set(STORE_KEYS.PRODUCTS, KEYS.JOINS, {
          ...this.joins,
          suppliers,
        })
      })
      .catch(console.error)

    this._store.api.products
      .get_site_list({ per_page: 999 })
      .then(({ colors, data: sites }) => {
        this._store.set(STORE_KEYS.PRODUCTS, KEYS.JOINS, {
          ...this.joins,
          colors,
          sites,
        })
      })
      .catch(console.error)
  }

  @action
  setForm = (form: FieldValues) => {
    this._store.set(
      STORE_KEYS.PRODUCTS,
      KEYS.FORM,
      form as unknown as UseFormReturn<IProduct>
    )
  }

  /**
   * Recalculate `marge` when `buy` or `sale` price change
   */
  @action
  recalculateMarge = () => {
    if (!this.form) return

    const sale = this.form.getValues('sale') || 0
    const buy = this.form.getValues('buy') || 0
    const newMarge = Math.round(100 - (buy / sale) * 100)

    this.form.setValue('marge', newMarge)
  }

  /**
   * Recalculate `sales` price when:
   *  * Recalculation button is pressed (fromBaseMarge)
   *  * Sale price is empty
   *  * Marge is set to default (46%)
   *
   * @param fromBaseMarge
   */
  @action
  recalculateSalePrice(fromBaseMarge = false) {
    if (!this.form) return

    const roundTwoDecimals = (value: number) =>
      Math.round((value + Number.EPSILON) * 100) / 100

    const buy = this.form.getValues('buy') || 0

    if (fromBaseMarge || !this.product?.sale) {
      this.form.setValue(
        'sale',
        roundTwoDecimals(buy / ((100 - PRICE.PRODUCT_MARGE) / 100))
      )
    }
  }

  @action
  updateProduct = async (values: IProduct) => {
    this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, true)
    values = manipulateProduct(values)

    try {
      const res = await this._store.api.products.update_product(
        this.product.id,
        values
      )
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.PRODUCT, res.data)
      return Promise.resolve(res.data)
    } catch (e: any) {
      return Promise.reject(e.response.data.errors)
    } finally {
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, false)
    }
  }

  @action
  createProduct = async (values: IProduct) => {
    this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, true)

    values = manipulateProduct(values)

    try {
      const res = await this._store.api.products.create_product(values)
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.PRODUCT, res.data)
      return Promise.resolve(res.data)
    } catch (e: any) {
      return Promise.reject(e.response.data.errors)
    } finally {
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, false)
    }
  }

  @action
  handleArchiveProduct = async () => {
    const modal = {
      title: this.current_archived
        ? LABELS.CONFIRM_PRODUCTS_ACTIVATE_TITLE
        : LABELS.CONFIRM_PRODUCTS_ARCHIVE_TITLE,
      content: this.current_archived
        ? LABELS.CONFIRM_PRODUCTS_ACTIVATE_TEXT
        : LABELS.CONFIRM_PRODUCTS_ARCHIVE_TEXT,
      confirmLabel: this.current_archived
        ? LABELS.ACTIVE_ACTION
        : LABELS.ARCHIVE_ACTION,
    }
    try {
      await this._store.ui.confirm({ ...modal })
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, true)
      await this._store.api.products.archive_product(this.product.id)
      this.getById(this.product.id)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data?.message)
          await this._store.ui.confirm({
            title: LABELS.ATTENTION,
            content: e.response.data.message,
            confirmLabel: LABELS.CLOSE,
            withCancel: false,
          })
      } else console.error(e)
    } finally {
      this._store.set(STORE_KEYS.PRODUCTS, KEYS.LOADING, false)
    }
  }

  @action
  clearProduct = () => {
    this._store.set(STORE_KEYS.PRODUCTS, KEYS.PRODUCT, {} as IProduct)
  }
}
