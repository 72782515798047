import { SupplierInformationForm } from '@partials/forms/supplier-detail/supplier-information'

export type ISupplierInformationTab = Parameters<
  typeof SupplierInformationForm
>[0]

export const SupplierInformationTab = ({
  onError,
}: ISupplierInformationTab) => {
  return <SupplierInformationForm onError={onError} />
}
