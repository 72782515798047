import { IProductInvoiceArticle } from './../typings/invoice.typings.d'
import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export class InvoicesApi extends AxiosBaseApi {
  /* DEFAULT CRUD */
  /* ACTIONS */
  /* JOINS */
  get_article_list = (params?: AxiosRequestConfig['params']) =>
    this.api
      .get<AxiosResponse<{ data: IProductInvoiceArticle[] }>>(
        ENDPOINTS.INVOICES.ARTICLE_LIST(),
        { params }
      )
      .then(response => response.data.data)
}
