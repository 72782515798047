import clsx from 'clsx'
import styles from './ac-divider.module.scss'

interface IAcDivider {
  /* @default 'neutral-300' */
  color?: 'neutral-200' | 'neutral-300'
}

export const AcDivider = ({ color = 'neutral-300' }: IAcDivider) => (
  <hr
    className={clsx(styles['ac-divider'], styles[`ac-divider--color-${color}`])}
  />
)
