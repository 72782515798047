export const handleKeyDown = (
  e: React.KeyboardEvent,
  callBack: () => void,
  code?: string
): void => {
  // code is an optional param that can be used to pass any valid key. Default is Enter.
  const _code = code ? [code] : ['Enter', 'NumpadEnter']
  const key = (key: string) => key === e.code

  if (_code.some(key)) {
    callBack()
  }
}

/* How to use
  Without code:
  <div onKeyDown={(e) => handleKeyDown(e, () => handleApChange(item))} />
  
  With code:
  <div onKeyDown={(e) => handleKeyDown(e, () => handleApChange(item), 'Space')}
  */
