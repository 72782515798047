import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'
import styles from './ac-datagrid-cell.module.scss'

interface IAcDataGridCellProps extends React.ComponentPropsWithoutRef<'td'> {
  children?: ReactNode
  /** @default 'td' */
  element?: 'td' | 'th'
}

export const AcDataGridCell = ({
  children,
  element: Element = 'td',
  ...rest
}: IAcDataGridCellProps) => {
  const getElementClassName = useMemo(
    () =>
      clsx(styles['ac-datagrid-cell'], styles[`ac-datagrid-cell--${Element}`]),
    [Element]
  )

  return (
    <Element
      className={getElementClassName}
      {...rest}>
      {children}
    </Element>
  )
}
