import { Outlet } from 'react-router-dom'

export const AcGuestLayout = (): JSX.Element => {
  return (
    <div id="app">
      <main>
        <Outlet />
      </main>
    </div>
  )
}
