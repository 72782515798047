import { AcAppHeader, AcButton, AcDataGrid } from '@components'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

export const AcSupplierList = observer(() => {
  const { suppliers } = useStore()
  const navigate = useNavigate()

  return (
    <>
      <AcAppHeader
        title={LABELS.SUPPLIERS}
        action={
          <AcButton
            onClick={() => navigate(ROUTE_PATHS.SUPPLIER_CREATE)}
            label={LABELS.NEW}
            icon={ICONS.PLUS}
          />
        }
      />
      <AcDataGrid
        store={suppliers.data_grid}
        detailPath={ROUTE_PATHS.SUPPLIER_DETAIL}
      />
    </>
  )
})
