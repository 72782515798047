import { AxiosBaseApi } from '@config/axios.config'
import {
  IDataGridAction,
  IDataGridActionResponse,
  IDataGridField,
  IDataGridParam,
  IDataGridResponse,
} from '@typings'
import { AxiosResponse } from 'axios'

export class DataGridApi extends AxiosBaseApi {
  getAll = (endpoint: string, params: IDataGridParam) =>
    this.api
      .get<AxiosResponse<IDataGridResponse>>(endpoint, {
        params: { datagrid: true, ...params },
      })
      .then(response => response.data)

  /** @throws {AxiosError} */
  handleCallBack = (
    endpoint: string,
    method: IDataGridField['callback']['method']
  ) => this.api<AxiosResponse<void>>(endpoint, { method })

  handleAction = (
    endpoint: string,
    key: IDataGridAction['key'],
    type: IDataGridAction['type'],
    params: IDataGridParam
  ) => {
    const service = type === 'response' ? 'api' : 'download'
    return this[service]
      .get<AxiosResponse<IDataGridActionResponse>>(endpoint, {
        params: { datagrid: true, action: key, ...params },
      })
      .then(response => response)
  }
}
