import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ISupplier, ISingleCustomerCompact } from '@typings'

export class CustomersApi extends AxiosBaseApi {
  /* DEFAULT CRUD */

  get_list = (params?: AxiosRequestConfig['params']) =>
    this.api
      .get<AxiosResponse<{ data: ISingleCustomerCompact[] }>>(
        ENDPOINTS.CUSTOMERS.LIST(),
        {
          params,
        }
      )
      .then(response => response.data.data)
      .catch(err => err)

  get_single = (id: number) =>
    this.api
      .get<AxiosResponse<{ data: ISupplier }>>(ENDPOINTS.CUSTOMERS.SINGLE(id))
      .then(response => response.data)
      .catch(err => err)
}
