import clsx from 'clsx'

import styles from './ac-sidebar.module.scss'

interface IAcSidebar {
  open?: boolean
  children?: React.ReactNode
}

export const AcSidebar = ({ open, children }: IAcSidebar) => {
  return (
    <aside
      className={clsx(
        styles['ac-sidebar'],
        open && styles['ac-sidebar--open']
      )}>
      {children}
    </aside>
  )
}
