import { AcAppHeader, AcButton, AcTabs, AcTab } from '@components'
import { ICONS, LABELS, ROUTE_PATHS, USERS_TABS } from '@constants'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

export const AcUserList = observer(() => {
  const navigate = useNavigate()

  return (
    <>
      <AcAppHeader
        title={LABELS.USERS}
        action={
          <AcButton
            onClick={() => navigate(ROUTE_PATHS.USER_PROFILE_CREATE)}
            label={LABELS.NEW}
            icon={ICONS.PLUS}
          />
        }
      />
      <AcTabs
        tabs={USERS_TABS}
      >
        <AcTab name={LABELS.EMPLOYEES} />
        <AcTab name={LABELS.CUSTOMERS} />
      </AcTabs>
    </>
  )
})
