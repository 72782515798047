import styles from './ac-progress-circular.module.scss'

interface IAcProgressCircular {
  value?: number
  size?: number | `${number}${'px' | '%' | 'rem'}`
}

export const AcProgressCircular = ({
  value = 0,
  size = '100%',
}: IAcProgressCircular) => {
  return (
    <svg
      className={styles['ac-progress-circular']}
      style={{ '--value': value, height: size } as React.CSSProperties}
      viewBox="0 0 120 120"
      role="progressbar"
      aria-valuenow={value}>
      <circle
        cx="60"
        cy="60"
        r="54"
      />
      <circle
        className={styles['ac-progress-circular-ring']}
        cx="60"
        cy="60"
        r="54"
        pathLength="100"
      />
    </svg>
  )
}
