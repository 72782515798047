import { LABELS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { AcGlobalSearchCategory } from '@components'
import { observer } from 'mobx-react-lite'

export const GlobalSearchWeekOrdersTab = observer(() => {
  const { global_search } = useStore()
  return (
    <AcGlobalSearchCategory
      categoryName={LABELS.WEEK_ORDERS}
      path={ROUTE_PATHS.WEEK_ORDER_DETAIL}
      items={global_search.results?.week_order}
      icon="TRUCK"
    />
  )
})
