export const AcIntlWeekday = (
  date: Date,
  notation: Intl.DateTimeFormatOptions['weekday'] = 'short'
) => new Intl.DateTimeFormat('nl-NL', { weekday: notation }).format(date)

export const AcIntlDDMMYYYY = (
  date: Date,
  dateStyle: Intl.DateTimeFormatOptions['dateStyle'] = 'short'
) => new Intl.DateTimeFormat('nl-NL', { dateStyle }).format(date)

export const AcIntlDDMMYYYYHHMM = (
  date: Date,
  dateStyle: Intl.DateTimeFormatOptions['dateStyle'] = 'short',
  timeStyle: Intl.DateTimeFormatOptions['timeStyle'] = 'short'
) => new Intl.DateTimeFormat('nl-NL', { dateStyle, timeStyle }).format(date)
