import { ICONS, KEYS, LABELS } from '@constants'
import { IDataGridField } from '@typings'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import {
  AcButton,
  AcCheckbox,
  AcDataGridCell,
  AcDataGridRow,
  AcIcon,
  AcTypography,
} from '@components'
import styles from './ac-datagrid-header.module.scss'
import { DataGridStore } from 'src/stores/datagrid.stores'

interface IDataGridHeader {
  store: DataGridStore
}

export const AcDataGridHeader = observer(({ store }: IDataGridHeader) => {
  const handleClick = (field: IDataGridField) => {
    if (!field.sortable) return
    store.handleSortChange(field)
  }

  const getCellClasses = useCallback(
    (singleField: IDataGridField) =>
      clsx(
        styles['ac-datagrid-header-cell'],
        singleField.sortable && styles['ac-datagrid-header-cell--sortable']
      ),
    []
  )

  const getHeaderCellContent = useCallback(
    (singleField: IDataGridField) => {
      const sortKeys = Object.values(store.activeSort)
      const { field, flex, label } = singleField
      const isSorting = sortKeys.includes(field)

      return (
        <AcDataGridCell
          className={getCellClasses(singleField)}
          style={{ flex: flex }}
          element="th"
          key={field}
          onClick={() => handleClick(singleField)}>
          <AcTypography
            className={styles['ac-datagrid-header-cell-content']}
            element="span"
            size="xs">
            {label}
            {isSorting && (
              <AcIcon
                className={styles['ac-datagrid-header-cell-sort-icon']}
                icon={
                  store.activeSort?.sort_direction === KEYS.DESCENDING
                    ? ICONS.ANGLE_UP
                    : ICONS.ANGLE_DOWN
                }
              />
            )}
          </AcTypography>
        </AcDataGridCell>
      )
    },
    [store.fields, store.activeSort]
  )

  const getSelectionButtonLabel = useMemo(() => {
    return `Alle ${store.total_items} items
    ${store.allSelected ? 'deselecteren' : 'selecteren'}`
  }, [store.total_items, store.current_selection_length, store.allSelected])

  return (
    <AcDataGridRow className={styles['ac-datagrid-header']}>
      {!!store.actions.length && (
        <AcDataGridCell element="th">
          <AcCheckbox
            label={LABELS.SELECT_ALL_ROWS}
            onClick={() =>
              store.handleSelection(
                store.has_selection ? KEYS.NONE : KEYS.ALL_PAGE
              )
            }
            checked={store.allSelected}
            indeterminant={store.has_selection && !store.allSelected}
          />
          {!!store.has_selection && (
            <div
              className={styles['ac-datagrid-header-cell-checkbox-selection']}>
              <AcTypography
                className={
                  styles['ac-datagrid-header-cell-checkbox-selection-label']
                }
                size="sm"
                element="span">
                {`${store.current_selection_length} ${LABELS.SELECTED}`}
              </AcTypography>
              {store.data.length < store.total_items && (
                <AcButton
                  className={
                    styles['ac-datagrid-header-cell-checkbox-selection-button']
                  }
                  variant="text"
                  padding="none"
                  label={getSelectionButtonLabel}
                  onClick={() =>
                    store.handleSelection(store.allSelected ? 'none' : 'all')
                  }
                />
              )}
            </div>
          )}
        </AcDataGridCell>
      )}

      {store.has_selection ? (
        <AcDataGridCell
          element="th"
          className={styles['ac-datagrid-header-cell-actions']}>
          {store.actions.map(singleAction => (
            <AcButton
              variant="icon"
              key={singleAction.key}
              label={singleAction.label}
              icon={singleAction.icon}
              onClick={() => store.handleSelectionAction(singleAction)}
            />
          ))}
        </AcDataGridCell>
      ) : (
        store.fields.map(singleField => getHeaderCellContent(singleField))
      )}
    </AcDataGridRow>
  )
})
