import {
  AcButton,
  AcCard,
  AcTemplateProductItem,
  AcTypography,
} from '@components'
import { ICONS, KEYS, LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import {
  ITemplateCategory,
  ITemplateDaysName,
  ITemplateGroupType,
} from '@typings'
import { observer } from 'mobx-react-lite'
import styles from './ac-template-category.module.scss'
import { AcProductSelector } from '../ac-product-selector/ac-product-selector'
import { useState } from 'react'
import { AcGetDragTypeFromProductGroup } from '@helpers/ac-get-drag-type-from-product-group.helper'

interface IAcTemplateCategoryProps extends ITemplateCategory {
  hasActions?: boolean
  day: ITemplateDaysName
  group: ITemplateGroupType
}

export const AcTemplateCategory = observer(
  ({
    id,
    name,
    products,
    quantity,
    hasActions = false,
    day,
    group,
  }: IAcTemplateCategoryProps) => {
    const { template } = useStore()

    const [templateCategoryRef, setTemplateCategoryRef] =
      useState<Nullable<HTMLElement>>(null)

    const handleBibi = (
      category_id: IAcTemplateCategoryProps['id'],
      day: IAcTemplateCategoryProps['day']
    ) => {
      const data = { category_id, day }
      template.handleBibiShuffle(data)
    }

    return (
      <AcCard
        padding={8}
        className={styles['ac-template-category']}
        ref={setTemplateCategoryRef}>
        <div className={styles['ac-template-category-title']}>
          <AcTypography
            size="sm"
            weight="semibold">{`${name} (${quantity})`}</AcTypography>
          {hasActions && template.user_can_edit && (
            <div className={styles['ac-template-category-title-actions']}>
              <AcButton
                className={styles['ac-template-category-title-actions']}
                padding="xs"
                icon={ICONS.BIBI}
                onClick={() => handleBibi(id, day)}
                color="secondary"
                variant="icon"
                label={LABELS.BIBI_SHUFFLE}
              />
              <AcProductSelector
                loading={template.product_selector.loading}
                productsGroupedByCategory={template.product_selector.products}
                categories={template.product_selector.categories}
                category_id={id}
                parentRef={templateCategoryRef}
                onSearch={params => template.handleProductSearch(day, params)}
                onChange={product =>
                  template.handleAddProduct(product, day, KEYS.LUNCH)
                }>
                <AcButton
                  padding="xs"
                  color="secondary"
                  icon={ICONS.PLUS}
                  variant="icon"
                  label={LABELS.ADD_PRODUCT}
                />
              </AcProductSelector>
            </div>
          )}
        </div>
        {products &&
          products.map(singleProduct => (
            <AcTemplateProductItem
              readOnly={!template.user_can_edit}
              day={day}
              category_id={id}
              key={singleProduct.id}
              product_type={AcGetDragTypeFromProductGroup(group)}
              {...singleProduct}
            />
          ))}
      </AcCard>
    )
  }
)
