import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  AcLoader,
  AcTemplateDay,
  AcTemplateDayHeader,
  AcTemplateHeader,
  AcTemplateSidebar,
} from '@components'
import { useStore } from '@hooks/use-store'

import { ROUTE_PATHS } from '@constants'

import styles from './ac-template-detail.module.scss'

export const AcTemplateDetail = observer(() => {
  const { id } = useParams()
  const { template, ui } = useStore()

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.altKey && event.code === 'KeyL' && id) {
      const legacyUrl = `${ROUTE_PATHS.TEMPLATE_DETAIL_EXT.replace(
        ':id',
        id
      )}?legacy`
      window.open(legacyUrl, '_blank')
    }
  }

  useEffect(() => {
    if (id) template.getSingle(parseInt(id))
    template.getProductCategories()
    template.connectToSocket()

    return () => template.disconnectSocket()
  }, [])

  useEffect(() => {
    if (!template.loading) template.handleInitiateClaimModal()
  }, [template.loading])

  useEffect(() => {
    if (!ui.menu.dense) ui.toggleMenuDense()
    if (!template.sidebarOpen) template.toggleSidebar()

    document.addEventListener('keydown', handleKeyPress)

    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  if (template.loading) return <AcLoader />

  return (
    <>
      <AcTemplateHeader {...template.current_info} />
      <section
        className={clsx(
          styles['ac-template-detail-day-headers'],
          template.sidebarOpen &&
            styles['ac-template-detail-day-headers--sidebar-open']
        )}>
        {template.current_days.map(singleDay => {
          return (
            <AcTemplateDayHeader
              key={singleDay.name}
              {...singleDay}
            />
          )
        })}
      </section>
      <section
        className={clsx(
          styles['ac-template-detail-day-container'],
          template.sidebarOpen &&
            styles['ac-template-detail-day-container--sidebar-open']
        )}>
        {template.current_days.map(singleDay => {
          return (
            <AcTemplateDay
              key={singleDay.name}
              {...singleDay}
            />
          )
        })}
      </section>
      <AcTemplateSidebar
        open={template.sidebarOpen}
        onClose={template.toggleSidebar}
      />
    </>
  )
})
