import { ITemplateCategory, ITemplateProduct } from '@typings'

export const AcGetProductsFromCategoryCollection = (
  categories: ITemplateCategory[]
) => {
  const products: Set<ITemplateProduct> = new Set([])

  categories.reduce((bulk, curr) => {
    for (const product of curr.products) {
      bulk.add(product)
    }
    return bulk
  }, products)
  return [...products]
}

export const AcGetProductCollectionAmount = (
  collection: ITemplateProduct[]
) => {
  const price = collection.reduce(
    (bulk, curr) => bulk + curr.quantity * curr.sale,
    0
  )
  return price
}
