import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'

import styles from './ac-input-base.module.scss'

interface IAcInputBase
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string
  children: ReactNode
  error?: boolean | string
  /** @default 14 */
  padding?: 0 | 8 | 10 | 14 | 16
  disabled?: boolean
  loading?: boolean
  hint?: string | boolean
  withoutBorders?: boolean
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  /** @default true */
  passFocus?: boolean
}

export const AcInputBase = ({
  className,
  children,
  error,
  padding = 14,
  disabled,
  loading,
  hint,
  withoutBorders,
  startAdornment,
  endAdornment,
  passFocus = true,
  ...props
}: IAcInputBase) => {
  const getTextInputWrapperClassNames = useMemo(
    () =>
      clsx(
        className,
        styles['ac-input-base'],
        disabled && styles['ac-input-base--disabled'],
        !!error && styles['ac-input-base--error'],
        withoutBorders && styles['ac-input-base--without-borders'],
        (startAdornment || endAdornment) &&
          styles['ac-input-base--has-adornment'],
        (error || hint) && styles['ac-input-base-hint--visible'],
        loading && styles['ac-input-base--loading']
      ),
    [
      className,
      disabled,
      error,
      loading,
      hint,
      withoutBorders,
      startAdornment,
      endAdornment,
    ]
  )

  /**
   * Extend onClick with focus passthrough to input child when passFocus is true
   */
  const _onClick = props.onClick
  props.onClick = (...args) => {
    if (passFocus)
      (
        (args[0].target as HTMLElement).querySelector('input') as HTMLElement
      )?.focus?.()
    _onClick?.(...args)
  }

  return (
    <div
      className={getTextInputWrapperClassNames}
      style={{ padding }}
      {...props}>
      {startAdornment && (
        <div className={styles['ac-input-base-adornment-start']}>
          {startAdornment}
        </div>
      )}
      {children}
      {endAdornment && (
        <div className={styles['ac-input-base-adornment-end']}>
          {endAdornment}
        </div>
      )}
      {!withoutBorders && (
        <div
          id={Math.random() + '_hint'}
          className={styles['ac-input-base-hint']}>
          {typeof error === 'string' && error.length > 0 ? error : hint}
        </div>
      )}
    </div>
  )
}
