import { ICONS } from './icons.constants'

export const TAG_CATEGORIES = [
  {
    label: 'Dieetvoorkeuren',
    value: 'Dieetvoorkeuren',
    icon: ICONS.SETTINGS_MAGIC,
  },
  {
    label: 'Allergieën',
    value: 'Allergieën',
    icon: ICONS.ALLERGY,
  },
  {
    label: 'Faciliteiten',
    value: 'Faciliteiten',
    icon: ICONS.MICROWAVE,
  },
]

export const TAG_PREFERENCES = [
  {
    label: 'Uitgesloten',
    value: 0,
  },
  {
    label: 'Niet te vaak',
    value: 1,
  },
  {
    label: 'Neutraal',
    value: 3,
  },
  {
    label: 'Super lekker',
    value: 5,
  },
]
