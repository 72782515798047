import { DRAG_TYPES } from '@constants'
import { ITemplateGroupType } from '@typings'

export const AcGetDragTypeFromProductGroup = (
  productGroup: ITemplateGroupType
) => {
  switch (productGroup) {
    case 'extra':
      return DRAG_TYPES.EXTRA_PRODUCT
    default:
      return DRAG_TYPES.PRODUCT
  }
}
