export const ASSERT_ERRORS = {
  TABS_TABS_NOT_ITERABLE: 'The errors property must be iterable',
  FORM_INPUT_MISSING_FORM_PROVIDER:
    'FormProvider from react-hook-form must be wrapped around AcFormInput',
}

export const GLOBAL_ERRORS = {
  FORM_STATE_INVALID: 'Niet alle velden zijn correct ingevuld.',
}

export const IMAGE_UPLOAD_ERRORS = {
  WRONG_MIME_TYPE: 'Je kan alleen .JPG, .JPEG of .PNG bestanden uploaden.',
  MULTIPLE_FILES_ERROR: 'Je kan maar 1 bestand tegelijkertijd uploaden.'
}