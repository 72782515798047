export const PERMISSIONS = {
    USER: {
        READ: 'user.read',
        STORE: 'user.store',
        UPDATE: 'user.update',
        DESTROY: 'user.destroy',
        RESTORE: 'user.restore',
    },
    ROLE: {
        MANAGE_SUPER_ADMIN: 'user.roles.manage.super_admin',
        MANAGE_PLANNER: 'user.roles.manage.planner',
        MANAGE_OFFICE_MANAGER: 'user.roles.manage.office_manager',
        MANAGE_LUNCHER: 'user.roles.manage.luncher',
    }
}