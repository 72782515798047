import { AcButton, AcIcon, AcLayeredMenu } from '@components'
import { ICONS, LABELS } from '@constants'
import { useOnClickOutside } from '@hooks/use-click-outside'
import { createRef, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { IAcLayeredMenuItem } from '../ac-layered-menu/ac-layered-menu-item/ac-layered-menu-item'

interface IAcMoreButtonProps {
  menuOptions: IAcLayeredMenuItem[]
  buttonType?: 'header' | 'day'
}

export const AcMoreButton = ({
  menuOptions,
  buttonType = 'day',
}: IAcMoreButtonProps) => {
  const wrapRef = createRef<HTMLElement>()
  const [parentComponent, setParentComponent] =
    useState<HTMLButtonElement | null>(null)
  const [menuComponent, setMenuComponent] = useState<HTMLDivElement | null>(
    null
  )
  const { styles, attributes } = usePopper(parentComponent, menuComponent, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: buttonType === 'day' ? [0, 0] : [0, 8],
        },
      },
    ],
  })
  const [menuOpen, setMenuOpen] = useState(false)

  useOnClickOutside(wrapRef, () => setMenuOpen(false))

  const getButtonType = useMemo(
    () =>
      buttonType === 'day' ? (
        <AcButton
          ref={setParentComponent}
          onClick={() => setMenuOpen(!menuOpen)}
          icon={ICONS.MORE}
          color="secondary"
          padding="xs"
          label={LABELS.MORE_ACTIONS}
          variant="icon"
        />
      ) : (
        <AcButton
          ref={setParentComponent}
          onClick={() => setMenuOpen(!menuOpen)}
          color="secondary"
          label={''}
          withToolTip={false}>
          <AcIcon icon={ICONS.MORE} />
        </AcButton>
      ),
    [buttonType]
  )

  return (
    <span ref={wrapRef}>
      {getButtonType}
      {menuOpen && (
        <div
          style={{ ...styles.popper, zIndex: 99 }}
          ref={setMenuComponent}
          {...attributes.popper}>
          <AcLayeredMenu
            menuOptions={menuOptions}
            handleClose={() => setMenuOpen(false)}
          />
        </div>
      )}
    </span>
  )
}
