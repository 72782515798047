export const DAYS: Record<string, string | any> = {
  EN: {
    MONDAY: 'monday',
    TUESDAY: 'tuesday',
    WEDNESDAY: 'wednesday',
    THURSDAY: 'thursday',
    FRIDAY: 'friday',
    SATURDAY: 'saturday',
    SUNDAY: 'sunday',
  },
  NL_SHORT: {
    monday: 'Ma',
    tuesday: 'Di',
    wednesday: 'Wo',
    thursday: 'Do',
    friday: 'Vr',
    saturday: 'Za',
    sunday: 'Zo',
  },
} as const

export const WEEK = {
  FROM_SATURDAY: [
    DAYS.EN.SATURDAY,
    DAYS.EN.SUNDAY,
    DAYS.EN.MONDAY,
    DAYS.EN.TUESDAY,
    DAYS.EN.WEDNESDAY,
    DAYS.EN.THURSDAY,
    DAYS.EN.FRIDAY,
  ],
  WORKING_WEEK: [
    DAYS.EN.MONDAY,
    DAYS.EN.TUESDAY,
    DAYS.EN.WEDNESDAY,
    DAYS.EN.THURSDAY,
    DAYS.EN.FRIDAY,
  ],
} as const

export const DATE_FORMATS = {
  DAY_MONTH_YEAR: 'DD-MM-YYYY',
}
