import {
  ISingleEmployee,
  IBibiShuffle,
  IDayInteraction,
  IPatchProduct,
  IProductCategory,
  ITemplateCategoryOption,
  ITemplateClaimObject,
  ITemplateDay,
  ITemplateDaysName,
  ITemplateDragItem,
  ITemplateGroupType,
  ITemplateInfo,
  ITemplateParams,
  ITemplateProduct,
  ITemplateProductQuery,
  ITemplateResponse,
  ITemplateTodo,
  ITemplateTodoCommentData,
  ITemplateTodoDataType,
  ITemplateTodoMenuData,
  ITemplateTodoOccupancyData,
  ITemplateTodoTaskData,
  ITemplateTodoType,
  ITemplateValidate,
  ITemplateWeek,
  ITemplateWeekChange,
  ITemplateTodoExtrasData,
  ITemplateTodoExtrasPatchPayload
} from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'

import {
  ACTION_LABELS,
  DYNAMIC_LABELS,
  KEYS,
  LABELS,
  STORE_KEYS,
  TAG_CATEGORIES,
  WEBSOCKET_EVENTS,
  WEEKDAYS,
} from '@constants'
import { AcGetProductCollectionAmount } from '@helpers/ac-template.helper'
import {
  TemplateClaimModal,
  TemplateConfirmMatch,
} from '@partials/modals/index.modals'
import { Store } from '@stores'
import { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

type IFormatRequestData =
  | Partial<ITemplateInfo>
  | Partial<ITemplateTodoDataType>
  | Partial<ITemplateDay>
  | Partial<IBibiShuffle>
  | Partial<IDayInteraction>
  | Partial<IPatchProduct>
  | Partial<ITemplateWeekChange>

export class TemplateStore {
  _store: Store
  loading: boolean
  is_busy: boolean

  info: ITemplateResponse['info']
  days: ITemplateResponse['days']
  todo: ITemplateResponse['todo']
  validate: ITemplateValidate

  params: ITemplateParams
  additional_days: Set<ITemplateDaysName>

  product_selector: {
    loading: boolean
    categories: IProductCategory[]
    products: ITemplateCategoryOption[]
  }

  sidebarOpen: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.loading = false
    this.is_busy = false
    this.info = {} as ITemplateResponse['info']
    this.days = [] as ITemplateResponse['days']
    this.todo = [] as ITemplateResponse['todo']
    this.validate = {} as ITemplateValidate

    this.additional_days = new Set([])
    this.params = {
      [KEYS.ADDITIONAL_DAYS]: this.current_additional_days,
    }

    this.product_selector = {
      loading: false,
      categories: [],
      products: [],
    }
    this.sidebarOpen = false
  }

  @computed
  get has_claim_history(): boolean {
    return !!this.current_info?.claim?.history?.length
  }

  @computed
  get show_claim_template_modal(): boolean {
    return !this.current_claim_status && !this.current_claimer && !this.loading
  }

  @computed
  get claimed_by_user(): boolean {
    return (
      this.current_claim_status &&
      this.current_claimer.id === this._store.auth.current_employee?.id
    )
  }

  @computed
  get user_can_edit(): boolean {
    return (
      !!this.current_claimer &&
      this.current_claimer.id === this._store.auth.current_employee?.id
    )
  }

  @computed
  get current_claim_status(): ITemplateClaimObject {
    return this.info?.claim?.current ?? null
  }

  @computed
  get current_claimer(): ITemplateClaimObject['employee'] {
    return !!this.current_claim_status && this.current_claim_status.employee
  }

  @computed
  get current_info() {
    return this.info
  }

  @computed
  get current_validate() {
    return this.validate
  }

  get current_profile() {
    return this.info?.profile
  }

  @computed
  get current_week() {
    return this.info?.week?.week
  }

  @computed
  get current_week_year() {
    return `${this.info?.week?.week}-${this.info?.week?.year}`
  }

  @computed
  get current_days() {
    return this.days
  }

  get current_days_by_name(): ITemplateDaysName[] {
    const allDays = new Set<ITemplateDaysName>([])
    this.current_days.reduce((bulk, curr) => bulk.add(curr.name), allDays)

    return [...allDays]
  }

  @computed
  get current_additional_days() {
    return [...this.additional_days]
  }

  @computed
  get current_todos() {
    return this.todo
  }

  @computed
  get current_todo_occupancy(): ITemplateTodo<ITemplateTodoOccupancyData> | null {
    return this.current_todos.find(item => item.type === 'OCCUPANCY') ?? null
  }

  @computed
  get current_todo_menu(): ITemplateTodo<ITemplateTodoMenuData> | null {
    return this.current_todos.find(item => item.type === 'MENU') ?? null
  }

  get current_todo_tasks(): ITemplateTodo<ITemplateTodoTaskData[]> | null {
    return this.current_todos.find(item => item.type === 'TASKS') ?? null
  }

  @computed
  get current_todo_comment(): ITemplateTodo<ITemplateTodoCommentData> | null {
    return this.current_todos.find(item => item.type === 'COMMENT') ?? null
  }

  @computed
  get current_todo_extras(): ITemplateTodo<ITemplateTodoExtrasData> | null {
    return this.current_todos.find(item => item.type === 'EXTRAS') ?? null
  }

  @computed
  get current_total_lunchers(): number {
    return this.current_days.reduce(
      (bulk, current) => bulk + current.lunchers,
      0
    )
  }

  @computed
  get show_profile_updated(): boolean {
    // Check if profile update is after 2 weeks ago
    return dayjs(this.current_profile?.updated_at).isAfter(
      dayjs().subtract(2, 'weeks')
    )
  }

  @computed
  get current_profile_tags_by_category() {
    const tags = this.current_profile?.tags || []

    const tagsByCategory = TAG_CATEGORIES.map(tagCategory => ({
      ...tagCategory,
      tags: tags.filter(({ category }) => category === tagCategory.value),
    }))

    return tagsByCategory
  }

  //Returns an array of all products combined.
  @computed
  get current_products(): ITemplateProduct[] {
    const products: Set<ITemplateProduct> = new Set([])
    this.current_days.reduce((bulk, current) => {
      for (const category of current.lunch) {
        for (const product of category.products) bulk.add(product)
      }
      return products
    }, products)

    return [...products]
  }

  @computed
  get current_products_total(): number {
    return AcGetProductCollectionAmount(this.current_products)
  }

  @computed
  get current_total_budget_to_spend(): number {
    if (!this.current_info.budget_to_spend || !this.current_total_lunchers)
      return 0
    return this.current_info.budget_to_spend * this.current_total_lunchers
  }

  @computed
  get current_params() {
    return this.params
  }

  @action
  templateToast = (message: string) => {
    toast.dismiss()
    toast(message)
  }

  // __SOCKET ACTIONS__ //

  /* We connect to the websocket here via the socket store
  (which is an instance of a Pusher websocket ). We pass a callBack function that is triggered
  everytime an event is fired on the socket. In our case, the handleSocketEvent.
  */
  @action
  connectToSocket = () => {
    this._store.socket.client.connect(this.handleSocketEvent)
  }

  @action
  handleSocketEvent = (event: string, data: any) => {
    if (event === WEBSOCKET_EVENTS.TEMPLATE.CLAIM_CREATED)
      this.handleSocketCreateClaim(data)

    if (event === WEBSOCKET_EVENTS.TEMPLATE.CLAIM_DELETED)
      this.handleSocketDeleteClaim(data)
  }

  @action
  handleSocketCreateClaim = async ({
    claim,
  }: {
    claim: ITemplateClaimObject
  }) => {
    if (
      claim.customer_id === this.current_info.customer.id &&
      claim.employee_id !== this._store.auth.current_employee?.id
    ) {
      await this._store.ui.closeModalGracefully()
      this.templateToast(
        DYNAMIC_LABELS.TEMPLATE_CLAIMED_BY(claim.employee.full_name)
      )
      this.getSingle(this.current_info.id, true)
    }
  }

  @action
  handleSocketDeleteClaim = async ({
    claim,
  }: {
    claim: ITemplateClaimObject
  }) => {
    if (
      claim.customer_id === this.current_info.customer.id &&
      claim.employee_id !== this._store.auth.current_employee?.id
    ) {
      this.getSingle(this.current_info.id, true)
    }
  }

  @action
  disconnectSocket = () => {
    this._store.socket.client.disconnect()
  }

  //__CLAIM__

  @action
  handleInitiateClaimModal = async () => {
    if (this.show_claim_template_modal)
      this._store.ui.showModal(TemplateClaimModal).catch(() => void 0)
  }

  @action
  handleClaimTemplate = async (userId?: ISingleEmployee['id']) => {
    try {
      const res = await this._store.api.template.claim_start(
        this.current_info.id,
        userId ?? (this._store.auth.current_employee?.id as number)
      )
      this.handleResponseData(res)
    } catch (e) {
      console.error(e)
    }
  }

  @action
  handleClaimTakeOver = async () => {
    const modal = {
      title: `${LABELS.CLAIM_TAKEOVER}?`,
      content: LABELS.CLAIM_TAKEOVER_CONTENT,
      confirmLabel: LABELS.CONFIRM,
    }
    await this._store.ui.confirm(modal)
    try {
      const res = await this._store.api.template.claim_update(
        this.current_info.id,
        this._store.auth.current_employee?.id as number
      )
      this.templateToast(
        DYNAMIC_LABELS.CLAIMED_TEMPLATE_N(
          this.current_info.name,
          this.current_claimer.full_name
        )
      )
      this.handleResponseData(res)
    } catch (e) {
      console.error(e)
    }
  }

  @action
  handleClaimEnd = async () => {
    try {
      const res = await this._store.api.template.claim_delete(
        this.current_info.id
      )
      this.templateToast(LABELS.CLAIM_DELETED)
      this.handleResponseData(res)
    } catch (e) {
      console.error(e)
    }
  }

  //__WEEKS__

  @action
  handleChangeWeek = async (
    week: ITemplateWeek['week'],
    year: ITemplateWeek['year'],
    withRequest = true
  ) => {
    this.handleParams('add', KEYS.WEEK, `${week}-${year}`, withRequest)
  }

  //___DAYS___

  @action
  handleAddDay = (day: ITemplateDaysName) => {
    const newData = this.additional_days.add(day)
    this._store.set(STORE_KEYS.TEMPLATE, KEYS.ADDITIONAL_DAYS, newData)
    this.handleParams('add', KEYS.ADDITIONAL_DAYS, [...newData])
    this.getSingle(this.current_info.id, true)
  }

  @action
  handleCopyDay = async (from: ITemplateDaysName, to: ITemplateDaysName) => {
    //To needs to be an array of ItemplateDaysName
    const data = this.formatRequestData({ from, to: [to] })
    await this._store.api.template
      .copy_day(this.current_info.id, data)
      .then(res => this.handleResponseData(res))
  }

  @action
  handleSwitchDay = async (from: ITemplateDaysName, to: ITemplateDaysName) => {
    //To needs to be an array of ItemplateDaysName
    const data = this.formatRequestData({ from, to })
    await this._store.api.template
      .switch_day(this.current_info.id, data)
      .then(res => this.handleResponseData(res))
  }

  @action
  handleClearDay = async (day: ITemplateDaysName) => {
    try {
      await this._store.ui.confirm({
        title: LABELS.ATTENTION,
        confirmLabel: LABELS.CONFIRM,
        cancelLabel: LABELS.CANCEL,
        content: LABELS.CONFIRM_CLEAR_DAY,
      })
      // We save the day in additional days to keep it.
      this.additional_days.add(day)
      // If no products are added, it will be removed on refresh

      // Format data for the clear request
      const data = this.formatRequestData({ day })

      // Call the endpoint and handle the response
      await this._store.api.template
        .clear_day(this.current_info.id, data)
        .then(res => this.handleResponseData(res))
    } catch {
      void 0
    }
  }

  @action
  handleRemoveDay = async (day: ITemplateDaysName) => {
    try {
      await this._store.ui.confirm({
        title: LABELS.ATTENTION,
        confirmLabel: LABELS.CONFIRM,
        cancelLabel: LABELS.CANCEL,
        content: LABELS.CONFIRM_REMOVE_DAY,
      })

      // remove day from additional days
      this.additional_days.delete(day)
      // Update params with new additional days
      this.handleParams(KEYS.ADD, KEYS.ADDITIONAL_DAYS, {
        ...this.current_additional_days,
      })
      // Format data for clear endpoint
      const data = this.formatRequestData({ day })

      // Call clear endpoint.
      await this._store.api.template
        .clear_day(this.current_info.id, data)
        .then(res => this.handleResponseData(res))
    } catch {
      void 0
    }
  }

  @action
  handleLunchers = async (
    day: ITemplateDaysName,
    lunchers: ITemplateDay['lunchers']
  ) => {
    const data = this.formatRequestData({ day, lunchers })
    await this._store.api.template
      .handle_lunchers(this.current_info.id, data)
      .then(res => this.handleResponseData(res))
  }

  //___CATEGORY___

  //___PRODUCT___

  @action
  handleLockProduct = async (
    id: ITemplateProduct['id'],
    is_locked: ITemplateProduct['is_locked']
  ) => {
    const data = {
      is_locked,
    }
    await this.handleProductPatch(id, data).then(() => {
      const message = is_locked
        ? ACTION_LABELS.LOCK.LOCKED(LABELS.PRODUCT)
        : ACTION_LABELS.LOCK.UNLOCKED(LABELS.PRODUCT)
      this.templateToast(message)
    })
  }

  @action
  handleDeleteProduct = (id: ITemplateProduct['id']) => {
    const data = {
      quantity: 0,
    }
    this.handleProductPatch(id, data).then(() => {
      this.templateToast(`${ACTION_LABELS.DELETE.DELETED(LABELS.PRODUCT)}`)
    })
  }

  @action
  handleProductQuantityChange = (
    id: ITemplateProduct['id'],
    quantity: ITemplateProduct['quantity']
  ) => {
    const data = {
      quantity,
    }
    this.handleProductPatch(id, data)
  }

  @action
  handleAddProduct = async (
    product: ITemplateProduct | ITemplateProduct[],
    day: ITemplateDaysName,
    group: ITemplateGroupType
  ) => {
    if (Array.isArray(product)) {
      this.handleAddProducts(product, day, group)
      return
    }

    const data = this.formatRequestData({ product_id: product.id, day, group })
    return await this._store.api.template
      .add_product(this.current_info.id, data)
      .then(res => {
        this.handleResponseData(res)
        return Promise.resolve(res)
      })
      .catch(e => {
        console.warn(e)
        return Promise.reject(e)
      })
  }

  @action
  handleAddProducts = async (
    products: ITemplateProduct[],
    day: ITemplateDaysName,
    group: ITemplateGroupType
  ) => {
    for (const product of products) {
      this.handleAddProduct(product, day, group)
    }
  }

  @action
  handleReplaceProduct = async (
    template_product_id: ITemplateProduct['id'],
    product: ITemplateProduct
  ) => {
    const _data = this.formatRequestData({ product_id: product.id })
    return await this._store.api.template
      .patch_product(this.current_info.id, template_product_id, _data)
      .then(res => {
        this.handleResponseData(res)
        return Promise.resolve(res)
      })
      .catch(e => {
        console.warn(e)
        return Promise.reject(e)
      })
  }

  @action
  handleDragProduct = async (
    item: ITemplateDragItem,
    day: ITemplateDaysName
  ) => {
    if (day === item.from || !item.id) return
    await this.handleProductPatch(item.id, { day }).then(() => {
      this.templateToast(
        `"${item.name}" succesvol verplaatst van ${WEEKDAYS[item.from]} naar ${
          WEEKDAYS[day]
        }`
      )
    })
  }

  @action
  handleProductPatch = async (
    id: ITemplateProduct['id'],
    data: Partial<IPatchProduct>
  ) => {
    const _data = this.formatRequestData(data)
    return await this._store.api.template
      .patch_product(this.current_info.id, id, _data)
      .then(res => {
        this.handleResponseData(res)
        return Promise.resolve(res)
      })
      .catch(e => {
        console.warn(e)
        return Promise.reject(e)
      })
  }

  @action
  handleProductSearch = async (
    day: ITemplateDaysName,
    params: Partial<ITemplateProductQuery>
  ) => {
    this._store.set(STORE_KEYS.TEMPLATE, KEYS.PRODUCT_SELECTOR, {
      ...this.product_selector,
      products: [],
    })

    if (params.query && params.query.length < 3) return

    params.week = this.current_week_year

    try {
      this._store.set(STORE_KEYS.TEMPLATE, KEYS.PRODUCT_SELECTOR, {
        ...this.product_selector,
        loading: true,
      })
      const products = await this._store.api.template.get_category_option(
        this.current_info.id,
        {
          group: 'lunch',
          day,
          ...params,
        }
      )
      this._store.set(STORE_KEYS.TEMPLATE, KEYS.PRODUCT_SELECTOR, {
        ...this.product_selector,
        products: products,
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._store.set(STORE_KEYS.TEMPLATE, KEYS.PRODUCT_SELECTOR, {
        ...this.product_selector,
        loading: false,
      })
    }
  }

  //____TEMPLATE____

  @action
  handleBibiShuffle = async (_data: Partial<IBibiShuffle>) => {
    const data = this.formatRequestData(_data)
    return await this._store.api.template
      .bibi_shuffle(this.current_info.id, data)
      .then(res => {
        this.handleResponseData(res)
      })
      .catch(e => console.log('error: ', e))
  }

  @action
  handleLunchBudgetChange = async (newNumber: number) => {
    const rounded = parseFloat(newNumber.toFixed(2))
    const data = this.formatRequestData({
      budget: rounded,
    })
    return await this._store.api.template
      .patch_template(this.current_info.id, data)
      .then(res => {
        this.handleResponseData(res)
        this._store.ui.current_modal.reject?.()
        this.templateToast(LABELS.LUNCH_BUDGET_PER_PERSON_UPDATED)
      })
      .catch(e => console.error(e))
  }

  @action
  getSingle = async (id: number, withoutLoading = false) => {
    {
      !withoutLoading &&
        this._store.set(STORE_KEYS.TEMPLATE, KEYS.LOADING, true)
    }
    const data = this.formatRequestData()
    try {
      const res = await this._store.api.template.get_single(id, data)
      this.handleResponseData(res)
    } catch (e) {
      console.error(e)
    } finally {
      {
        !withoutLoading &&
          this._store.set(STORE_KEYS.TEMPLATE, KEYS.LOADING, false)
      }
    }
  }

  @action
  handleParams = (
    method: 'add' | 'remove',
    key: string,
    value: any,
    getNewData = true
  ) => {
    let newParams = { ...this.params }
    if (method === 'remove') {
      delete this.params[key]
      newParams = this.params
    } else {
      newParams = {
        ...this.params,
        [key]: value,
      }
    }
    this._store.set(STORE_KEYS.TEMPLATE, KEYS.PARAMS, newParams)
    if (getNewData) this.getSingle(this.current_info.id, true)
  }

  @action
  formatRequestData = (
    data?: IFormatRequestData
  ): Partial<AxiosRequestConfig> => {
    const _data = {
      ['params']: this.current_params,
      data,
    }
    return _data
  }

  @action
  handleResponseData = ({ info, days, todo }: ITemplateResponse) => {
    if (info) this._store.set(STORE_KEYS.TEMPLATE, KEYS.INFO, info)
    if (days) this._store.set(STORE_KEYS.TEMPLATE, KEYS.DAYS, days)
    if (todo) this._store.set(STORE_KEYS.TEMPLATE, KEYS.TODO, todo)

    // We set the current week via the handleChangeWeek function so it is always present in params
    if (info?.week) this.handleChangeWeek(info.week.week, info.week.year, false)
  }

  @action
  getProductCategories = async () => {
    // this._store.set(STORE_KEYS.TEMPLATE, KEYS.LOADING, true)
    try {
      const mainCategories = await this._store.api.products.get_category_list({
        per_page: 999,
        is_main: 1,
      })
      this._store.set(STORE_KEYS.TEMPLATE, KEYS.PRODUCT_SELECTOR, {
        ...this.product_selector,
        categories: mainCategories,
      })
    } catch (e) {
      console.error(e)
    } finally {
      // this._store.set(STORE_KEYS.TEMPLATE, KEYS.LOADING, false)
    }
  }

  @action
  getTemplateValidate = async () => {
    this._store.set(STORE_KEYS.TEMPLATE, KEYS.VALIDATE, {} as ITemplateValidate)
    this._store.ui
      .showModal(TemplateConfirmMatch, { closeable: true })
      .catch(() => void 0)

    try {
      const requestData = this.formatRequestData()
      const res = await this._store.api.template.handle_validate(
        this.current_info.id,
        requestData
      )
      this._store.set(STORE_KEYS.TEMPLATE, KEYS.VALIDATE, res)
      return res
    } catch (e) {
      this._store.ui.modal.reject?.()
      console.error(e)
    }
  }

  @action
  handleTemplateFinish = async () => {
    try {
      const requestData = this.formatRequestData()
      const res = await this._store.api.template.handle_template_finish(
        this.current_info.id,
        requestData
      )
      return res
    } catch (e) {
      console.error(e)
    }
  }

  @action
  toggleSidebar = () => {
    this.sidebarOpen = !this.sidebarOpen
  }

  //____TODO____

  @action
  completeTodo = async (type: ITemplateTodoType, withOccupancy?: boolean) => {
    let data: any = { type }

    if (withOccupancy) {
      data = {
        type,
        set_occupancy: withOccupancy,
      }
    }
    try {
      const requestData = this.formatRequestData(data)
      const res = await this._store.api.template.complete_todo(
        this.current_info.id,
        requestData
      )
      this.templateToast(LABELS.TASK_FINISHED)
      this.handleResponseData(res)
    } catch (e) {
      console.error('error: ', e)
    }
  }

  @action
  handleTodoMenuChange = async (message?: string) => {
    const week = this.current_week_year
    const data = { week, message }

    try {
      const requestData = this.formatRequestData(data)
      let res

      if (this.current_todo_menu?.data?.id) {
        res = await this._store.api.template.handle_menu_update(
          this.current_info.id,
          requestData
        )
      } else {
        res = await this._store.api.template.handle_menu_add(
          this.current_info.id,
          requestData
        )
      }

      this.handleResponseData(res)
    } catch (e: any) {
      console.error('error: ', e)

      if (typeof e?.response?.data?.message === 'string') {
        this.templateToast(e.response.data.message)
      }
    }
  }

  @action
  handleTodoMenuDelete = async () => {
    const week = this.current_week_year
    const data = { week }

    try {
      const requestData = this.formatRequestData(data)
      const res = await this._store.api.template.handle_menu_delete(
        this.current_info.id,
        requestData
      )

      this.handleResponseData(res)
    } catch (e: any) {
      console.error('error: ', e)

      if (typeof e?.response?.data?.message === 'string') {
        this.templateToast(e.response.data.message)
      }
    }
  }

  @action
  handleTask = async (id: ITemplateTodoTaskData['id']) => {
    try {
      const requestData = this.formatRequestData()
      const res = await this._store.api.template.handle_task(
        this.current_info.id,
        id,
        requestData
      )
      this.handleResponseData(res)
    } catch (e) {
      console.error(e)
    }
  }

  @action
  handleTodoCommentReply = async (content?: string) => {
    try {
      const comment_id = this.current_todo_comment?.data?.id

      if (!comment_id) throw new Error('Comment id not found')

      const requestData = this.formatRequestData({ content })
      const res = await this._store.api.template.handle_comment_reply(
        this.current_info.id,
        comment_id,
        requestData
      )
      this.handleResponseData(res)
    } catch (e: any) {
      console.error('error: ', e)

      if (typeof e?.response?.data?.message === 'string') {
        this.templateToast(e.response.data.message)
      }
    }
  }

  @action
  handleTodoExtrasProduct = async (template_id: ITemplateInfo['id'], {day, product_id, value, week}: ITemplateTodoExtrasPatchPayload) => {
    const payload = {
      day, 
      product_id, 
      value,
      week
    }
    try {
      const res = await this._store.api.template.handle_extras_product_todo(template_id, payload)
      if (res) {
        this.handleResponseData(res)
      }
    } catch (e: any) {
      console.error('error: ', e)

      if (typeof e?.response?.data?.message === 'string') {
        this.templateToast(e.response.data.message)
      }
    }
  }
  
}
