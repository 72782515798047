import { AcButton } from '@components'
import { LABELS } from '@constants'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ImageEditModal from './ac-image-edit-modal'

import { useStore } from '@hooks/use-store'

// Styles
import styles from './ac-image-input.module.scss'

// Default img
import BuurtboerLogo from '@assets/images/bb-logo-no-text.svg'

interface ImageFormValue {
  file_name: string
  mime_type: string
  name: string
  srcset: string
  url: string
}
interface IAcImageInputProps {
  id: string
  loading?: boolean
  className?: string
  value?: ImageFormValue
  name: string
  disabled?: boolean
  hint?: string
  readOnly?: boolean
  error?: string | boolean
  onChange?: (file: File) => void
}

export const AcImageInput = ({
  id,
  readOnly,
  value,
  loading,
  onChange,
}: IAcImageInputProps) => {
  const { ui } = useStore()

  const [preview, setPreview] = useState(BuurtboerLogo)

  useEffect(() => {
    if (!loading && !(value instanceof File)) {
      if (value?.url) setPreview(value.url)
      if (!value?.url) setPreview(BuurtboerLogo)
    }
  }, [value, loading])

  const ImgModalContent = useCallback(() => {
    return (
      <>
        <span className={styles['ac-image-input-img__btn-close']}>
          <AcButton
            icon="CLOSE"
            variant="icon"
            label={LABELS.CLOSE}
            onClick={closeModal}
          />
        </span>
        <img
          className={styles['ac-image-input-img--modal']}
          id={id}
          src={preview}
        />
      </>
    )
  }, [preview])

  const ImgEditModal = () => {
    return (
      <ImageEditModal
        onChange={onChange}
        onClose={closeModal}
        setPreview={setPreview}
      />
    )
  }

  const openImageModal = async () => {
    await ui
      .showModal(ImgModalContent, { width: 'full', closeable: true })
      .catch(() => void 0)
  }

  const openImageEditModal = async () => {
    await ui
      .showModal(ImgEditModal, { width: 'xl', closeable: true })
      .catch(() => void 0)
  }

  const closeModal = () => {
    try {
      ui.modal.reject?.()
    } catch {
      void 0
    }
  }

  const renderPreviewBox = useMemo(() => {
    return (
      <img
        onClick={openImageModal}
        className={clsx(styles['ac-image-input-img'])}
        id={id}
        src={preview}
      />
    )
  }, [preview, id])

  if (readOnly) return renderPreviewBox

  return (
    <div className={styles['ac-image-input-edit']}>
      {renderPreviewBox}
      <div className={styles['ac-image-input-edit-action']}>
        <AcButton
          onClick={openImageEditModal}
          variant="text"
          label={LABELS.EDIT}
          icon="EDIT"
        />
      </div>
    </div>
  )
}
