import { AcCheckbox, AcTypography } from '@components'
import { ITemplateTodoExtrasDataDayProduct, ITemplateDaysName } from '@typings'
import styles from './ac-template-todo-extras-day-product.module.scss'
import { useStore } from '@hooks/use-store'

interface IAcTemplateTodoExtrasDayProduct {
    day: ITemplateDaysName
    product: ITemplateTodoExtrasDataDayProduct
}

export const AcTemplateTodoExtrasDayProduct = ({ day, product }: IAcTemplateTodoExtrasDayProduct) => {
    const { template } = useStore()

    const handleClick = async () => {
        await template.handleTodoExtrasProduct(template.current_info.id, {
            day,
            product_id: product.id,
            value: product.completed_at ? false : true,
            week: template.current_info.week.identifier
        })
    }

    return (
        <div className={styles['ac-template-todo-extras-day-product']}>
            <AcCheckbox
                checked={product.completed_at ? true : false}
                onClick={handleClick}
                label={product.name}
                withLabel
            />
            <AcTypography size='xs' element='span' color='neutral-700'>{product.quantity}x</AcTypography>
        </div>
    )
}