import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { IGlobalSearch } from '@typings'

export class GlobalSearchStore {
  _store: Store

  loading: boolean
  query: string
  empty: boolean
  results: IGlobalSearch

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.loading = true
    this.empty = true

    this.query = ''
    this.results = {} as IGlobalSearch
  }

  @computed
  get is_empty() {
    return this.query?.length < 3
  }

  @action
  search = async (query: typeof this.query) => {
    this._store.set(STORE_KEYS.GLOBAL_SEARCH, KEYS.QUERY, query)

    if (this.is_empty) {
      this._store.set(STORE_KEYS.GLOBAL_SEARCH, KEYS.RESULTS, {})
      return
    }

    this._store.set(STORE_KEYS.GLOBAL_SEARCH, KEYS.LOADING, true)

    try {
      const res = await this._store.api.global_search.search(query)
      this._store.set(STORE_KEYS.GLOBAL_SEARCH, KEYS.RESULTS, res)
    } catch (e) {
      console.error(e)
    } finally {
      this._store.set(STORE_KEYS.GLOBAL_SEARCH, KEYS.LOADING, false)
    }
  }
}
