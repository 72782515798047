import { FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form'
import Validator, { Rules } from 'validatorjs'

const useAcFormValidator = <T extends FieldValues>(
  form: UseFormReturn<T>,
  fieldLabels?: Map<keyof T, string>
) => {
  form.control._options.context ??= {}
  form.control._options.resolver = async (values: T, rules?: Rules) => {
    Validator.useLang('nl')
    const validation = new Validator(values, rules || {}, {
      required: ':attribute is verplicht.',
    })

    if (fieldLabels)
      validation.setAttributeNames(Object.fromEntries(fieldLabels))
    if (validation.fails()) {
      const validationErrors = validation.errors.all() as {
        [key: string]: string[]
      }
      const formErrors: FieldErrors = {}

      for (const error in validationErrors) {
        formErrors[error] = {
          type: 'required',
          message: (validationErrors[error] as string[])[0],
        }
      }
      return { values, errors: formErrors }
    }

    return { values, errors: {} }
  }
}

export { useAcFormValidator }
