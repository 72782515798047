import { Modifier } from '@popperjs/core'

export const sameWidth: Modifier<'sameWidth', any> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }: any) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }: any) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
  },
}

export const overlapReference: Modifier<'overlapReference', any> = {
  name: 'overlapReference',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }: any) => {
    state.styles.popper.top = `${state.rects.reference.height * -1}px`
  },
  effect: ({ state }: any) => {
    state.elements.popper.style.top = `${
      state.elements.reference.offsetHeight * -1
    }px`
  },
}
